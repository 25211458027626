import React, { createContext, useState, useContext } from 'react';

const EventsListContext = createContext();

export default function EventsListProvider({ children }) {
    const [eventsList, setEventsList] = useState(null);

    return (
        <EventsListContext.Provider value={{eventsList, setEventsList}}>
            {children}
        </EventsListContext.Provider>
    );
}

export function useEventsList() {
    const context = useContext(EventsListContext);
    
    if (!context) throw new Error('useEventsList must be used within a EventsList');
    
    const { eventsList, setEventsList } = context;
    return { eventsList, setEventsList };
}