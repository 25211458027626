import React from 'react'
import PartnerCardResult from './card/PartnerCardResult'

export default function PartnersContent() {
  return (
    <>
        <PartnerCardResult />
    </>
  )
}
