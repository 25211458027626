import Text from 'components/_UI/Text'
import React, {useEffect, useState} from 'react'
import { Column } from 'styled/alignment/Column'
import { useTagsTouristSpots } from 'context/TagsTouristSpots'
import { useTouristSpots } from 'context/TouristSpots'
import Loader from 'components/_UI/Loader'
import { ButtonSelectItem, SelectContainer, SelectWrapper } from 'components/_UI/SelectsContent'
import ButtonSelect from 'components/_UI/ButtonSelect'
import { HorizontalCenter } from 'styled/alignment/Center'
import colors from 'theme/colors'
import { toast } from 'react-toastify'
import tagsSpotsApi from 'api/requests/tagsSpots'

let tags = []

export default function CategoriesInfos({ error }) {
    const {tagsTouristSpots, setTagsTouristSpots} = useTagsTouristSpots()
    const {touristSpots, setTouristSpots} = useTouristSpots()
    const [loadCategories, setLoadCategories] = useState(false)

    useEffect(() => {
        getCategories()
    }, [])

    const removeObjectWithId = (arr, id) => {
        const objWithIndex = arr.findIndex(obj => obj.id === id)

        if (objWithIndex > -1) {
            arr.splice(objWithIndex, 1)
        }

        return arr
    }

    const categoriesFilter = (active, item) => {
        const categoriesObject = {
            active: active === 'ACTIVE' || false,
            name: item.name,
            id: item.id
        }

        if (active === 'INATIVE') {
            const newArray = removeObjectWithId(tags, categoriesObject.id)
            setTouristSpots({ ...touristSpots, categories: newArray })
            return;
        }

        tags.push(categoriesObject)
        setTouristSpots({ ...touristSpots, categories: tags })
    }
    const getCategories = async() => {
        if(tagsTouristSpots) {
            return
        }
        setLoadCategories(true)
        try {
            const { data } = await tagsSpotsApi.listSpots()
            setTagsTouristSpots(data)
        } catch (error) {
            toast.error('Não foi possível carregar Categorias', {
                position: 'top-center'
            })
        } finally {
            setLoadCategories(false)
        }
    }
  return (
    <Column marginTop='22px'>
        <Text size='small' type='bold'>Categorias</Text>
            { 
                !loadCategories && 
                    tagsTouristSpots ? (
                        <SelectContainer>
                            {
                                tagsTouristSpots?.map((el) => (
                                    <SelectWrapper key={el.id}>
                                        <ButtonSelectItem>
                                            <ButtonSelect
                                                size={'small'}
                                                onToggle={(state) => categoriesFilter(state, el)}
                                            >
                                                {el.name}
                                            </ButtonSelect>
                                        </ButtonSelectItem>
                                    </SelectWrapper>
                                ))
                            }
                        </SelectContainer>
                    ) : (
                        <Loader />
                    )    
            }
            {error && (
                <HorizontalCenter>
                    {error === 'min' && (
                        <Text color={colors.danger}>Você deve selecionar pelo menos uma categoria</Text>
                    )}
                </HorizontalCenter>
            )}
        
    </Column>
  )
}
