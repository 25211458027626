import Text from 'components/_UI/Text';
import React from 'react'
import styled, {css} from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import eventTypes from 'theme/eventTypes';

export default function CardNaturalBeauty({data}) {
  return (
    <CardContainer>
      <ImageContainer>
            {data?.photo ? (
                <CardImage src={data?.photo} />
        ) : (
                <CardTextWrapper category={data?.classification?.name}>
                    <Text
                        color={colors.neutralLight}
                        type='bold'
                        name='hero'
                    >
                        {data?.name?.charAt(0).toUpperCase()}
                    </Text>
                </CardTextWrapper>
            )}

        </ImageContainer>

        <Text
          name='small'
          type='bold'
          marginTop='6px'
          ellipsisAt='95%'
          paddingBottom={'2px'} 
        >
            {data.name}
        </Text>
    </CardContainer>
  )
}
const CardContainer = styled(Column)`
    width: 188px;
    margin: 10px 10px 10px 10px;
    transition: all ease-in-out .25s;
    cursor: pointer;
    box-shadow: 2px 8px 10px #ccc;      
    padding: 6px;
    border-radius: 10px;
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 210px;
        margin: 10px 10px 10px 10px;
        border-radius: 10px;
        box-shadow: 2px 8px 10px #ccc;        
        padding: 6px;
    }

`;

const ImageContainer = styled.div`
    display: flex;
    width: 100%;
    height: 85px;

    @media screen and (min-width: ${breakpoints.lg}) {
        height: 114px;
    }
    
`;
const CardTextWrapper = styled(FullyCentered)`
    width: 100%;
    text-align: center;
    padding: 20px;
    border-radius: 4px;

    ${({ category }) => category === 'free' && css`
        background: ${eventTypes.free.color};
    `};

    ${({ category }) => category === 'private' && css`
        background: ${eventTypes.paid.color};
    `};

`;

const CardImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
`;

