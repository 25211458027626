import Content from 'components/main/Content'
import PermissionsConent from 'modules/permisssions/PermissionsContent'
import React from 'react'
import RolesProvider from 'context/Roles'
import ScopesProvider from 'context/Scopes'

export default function Permissions() {
  return (
      <RolesProvider>
        <ScopesProvider>
          <Content title='Permissões'>
              <PermissionsConent />
          </Content>
        </ScopesProvider>
      </RolesProvider>
  )
}
