import ImageUpload from 'components/_UI/ImageUpload'
import Text from 'components/_UI/Text'
import React from 'react'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'

export default function ImageInfo({ onToggleImage, touristSpots, naturalBeauty}) {

    function toggleFile(files) {
        onToggleImage(files[0]);
    }

    return (
        <Column marginTop='16px'>
            <Text size='small' type='bold'> 
                {touristSpots ? 'Imagem do ponto turístico' : naturalBeauty ? 'Imagem da beleza natural' : 'Imagem do evento'} </Text>
            <Text size='small' color={colors.night}>
                {touristSpots ? 'Selecione uma imagem para ser a capa do seu ponto turístico.' : naturalBeauty ? 'Selecione uma imagem para ser a capa da sua beleza natural.' : 'Selecione uma imagem para ser a capa do seu evento.'}</Text>

            <ImageUpload onToggleFile={toggleFile} />
        </Column>
    )
}