import fieldMessages from "texts/messages/field";
import regex from "./regex";

const fieldValidations = {
    name: (field = 'nome', minLength = 2, maxLength = 30) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },
            pattern: {
                value: regex.onlyAlphabetic,
                message: fieldMessages.onlyAlphabetic
            }
        }
    },
    
    generic: (field = 'nome', minLength = 2, maxLength = 1200) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },
        }
    },

    description: (field = 'descrição', minLength = 2, maxLength = 9999999) => {
        return {
            // required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },
        }
    },

    addressNumber: (field = 'nome', minLength = 1, maxLength = 5) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },
            pattern: {
                value: regex.onlyNumber,
                message: fieldMessages.fillCorrectly(field)
            }
        }
    },
    
    link: (field = 'link', minLength = 3, maxLength = 300) => {
        return {
            minLength: {
                value: minLength,
                message: fieldMessages.minValue(minLength)
            },
            maxLength: {
                value: maxLength,
                message: fieldMessages.maxValue(maxLength)
            },
            pattern: {
                value: regex.link,
                message: fieldMessages.invalidUrl
            }
        }
    },

    date: (field = 'data') => {
        return {
            required: fieldMessages.fillCorrectly(field),
            pattern: {
                value: regex.date,
                message: fieldMessages.invalidFemaleField(field)
            }
        };
    },

    email: (field = 'email') => {
        return {
            required: fieldMessages.required(field),
            pattern: {
                value: regex.email,
                message: fieldMessages.invalidMaleField(field)
            }
        };
    },

    phone: (field = 'telefone') => {
        return {
            required: fieldMessages.required(field),
            pattern: {
                value: regex.phone,
                message: fieldMessages.invalidMaleField(field)
            }
        };
    },

    cpf: (field = 'CPF') => {
        return {
            required: fieldMessages.required(field),
            pattern: {
                value: regex.cpf,
                message: fieldMessages.invalidMaleField(field)
            }
        };
    },

    code: (field = 'code', minLength = 6) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.invalidMaleField(field)
            }
        };
    },

    password: (field = 'senha') => {
        return {
            required: fieldMessages.required(field),
            pattern: {
                value: regex.password,
                message: fieldMessages.password
            }
        };
    },

    passwordWithoutPatterns: (field = 'senha') => {
        return {
            required: fieldMessages.required(field),
        };
    },
    
    cepPatterns: (field = 'CEP', minLength = 9) => {
        return {
            required: fieldMessages.required(field),
            minLength: {
                value: minLength,
                message: fieldMessages.invalidMaleField(field)
            },
            pattern: {
                value: regex.cep,
                message: fieldMessages.fillCorrectly(field)
            }
        };
    },
    
    time: (field = 'horario') => {
        return {
            required: fieldMessages.required(field),
            pattern: {
                value:{
                    value: regex.time,
                    message: fieldMessages.invalidTime(field)
                }
            }
        };
    }
}

export default fieldValidations;
