import ButtonAdd from 'components/_UI/ButtonAdd'
import React, {useState} from 'react'
import styled from 'styled-components'
import { Row } from 'styled/alignment/Row'
import CreateNaturalBeautyForm from './form/CreateNaturalBeautyForm'
import NaturalBeautyResult from './NaturalBeautyResult'

export default function NaturalBeautyContent() {
    
    const [modalVisible, setModalVisible] = useState(false)

   return (
        <Container>
            <ContainerButtonAdd>
                <ButtonAdd onClick={() => setModalVisible(true)}>
                    Adicionar Beleza Natural
                </ButtonAdd>
            </ContainerButtonAdd>
            
            {modalVisible && (
                <CreateNaturalBeautyForm modalVisible={modalVisible} setModalVisible={setModalVisible}/>
            )}

            <NaturalBeautyResult/>

        </Container>
   )
}

const Container = styled.div`
    flex-direction: column;
    width: 100%;
`;

const ContainerButtonAdd = styled(Row)`
    display: flex;
    justify-content: flex-end;
`