import Text from 'components/_UI/Text'
import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import eventTypes from 'theme/eventTypes'
import sizes from 'theme/sizes'

export default function Events({data}) {
  return (
    <>
        <Text
            name='subtitle'
        >
            Eventos
        </Text>
        <ContentEvents>
            <Card large>
                <Row fullyCentralized >
                    <Column fullyCentralized>
                        <Text name='hero'>
                            {data?.count_events}
                        </Text>
                        <Text name='highlight'>
                            Ativos
                        </Text>
                    </Column>
                    <Row fullyCentralized>
                        <Column fullyCentralized>    
                            <Text name='title' color={eventTypes.free.color}>{data?.count_events_free}</Text>
                            <Text type='bold' color={eventTypes.free.color}>Gratuitos</Text>

                        </Column>
                        <Column fullyCentralized>
                            <Text name='title' color={eventTypes.paid.color}>{data?.count_events_private}</Text>
                            <Text type='bold' color={eventTypes.paid.color}>Pagos</Text>
                        </Column>
                    </Row>
                </Row>
            </Card>
            <Card>
                <Text name='hero'>
                    {data?.count_events_past}
                </Text>
                <Text name='highlight'>
                    Finalizados
                </Text>
            </Card>
            <Card>
                <Row>
                    <Column fullyCentralized>
                        <Text name='hero'>
                            {data?.count_events + data?.count_events_past}
                        </Text>
                        <Text name='highlight'>
                            Total de Eventos
                        </Text>
                    </Column>
                </Row>
            </Card>
        </ContentEvents>
        <ContentEvents statusEvents>
            <Card large mobile>
                <ContentInterests >
                    <Column fullyCentralized>
                        <Text name='hero'> {data?.count_total_interest} </Text>
                        <Text > total de interesses </Text>
                        <ContentTextInterests>
                            <Text name='small' color={colors.nightLight} type='bold'> Valido apenas em eventos ativos </Text>
                        </ContentTextInterests>
                    </Column>
                    <Column fullyCentralized>
                            <Text name='highlight'> Mais interessados </Text>
                            <Text>{data?.count_top3_interest[0]?.name} - {data?.count_top3_interest[0]?.total} </Text>
                            <Text>{data?.count_top3_interest[1]?.name} - {data?.count_top3_interest[1]?.total} </Text>
                            <Text>{data?.count_top3_interest[2]?.name} - {data?.count_top3_interest[2]?.total} </Text>
                    </Column>
                </ContentInterests>
            </Card>
            <Card large mobile>
                <ContentInterests >
                    <Column fullyCentralized>
                        <Text name='hero'>{data?.count_total_confirmeds} </Text>
                        <Text > total de confirmados </Text>
                        <ContentTextInterests>
                            <Text name='small' color={colors.nightLight} type='bold'> Valido apenas em eventos ativos </Text>
                        </ContentTextInterests>
                    </Column>
                    <Column fullyCentralized>
                        <Text name='highlight'> Mais confirmados </Text>
                        <Text>{data?.count_top3_confirmeds[0]?.name} - {data?.count_top3_confirmeds[0]?.total}</Text>
                        <Text>{data?.count_top3_confirmeds[1]?.name} - {data?.count_top3_confirmeds[1]?.total}</Text>
                        <Text>{data?.count_top3_confirmeds[2]?.name} - {data?.count_top3_confirmeds[2]?.total}</Text>
                    </Column>
                </ContentInterests>
            </Card>
        </ContentEvents>
    </>
  )
}
const Card = styled.div`
    width: 19.5%;
    height: 130px;
    box-shadow: ${sizes.none} ${sizes.nano} ${sizes.nano} ${colors.nightLight};
    border-radius: ${sizes.mini};
    background: ${colors.neutral};
    margin-left: ${sizes.medium};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 820px) {
        flex-direction: column;
        width: 100%;
        margin-left: 0;
        margin-top: 15px;
        &:nth-child(1) {
            margin-top: ${sizes.none};
        }
    }
    @media screen and (max-width: 820px) {
       
        ${({mobile}) => mobile && `
            height: 200px;
        `}
        &:nth-child(1) {
            margin-top: ${sizes.none};
        }
    }
    ${({large}) => large && `
        width: 40%;
    `}
    &:nth-child(1) {
        margin-left: ${sizes.none};
    }
`;
const ContentEvents = styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;

    @media screen and (max-width: 820px) {
        display: flex;
        flex-direction: column;
    }
    ${({statusEvents}) => statusEvents && `
        display: flex;
        flex-direction: row;
        margin-top: 15px;
    `}
   
`;
const ContentInterests = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    height: auto;
    padding-top: 10px;
    align-items: center;
    justify-content: center;
`
const ContentTextInterests = styled.div`
     @media screen and (max-width: 820px) {
        text-align: center;
    }
`;