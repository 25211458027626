import React from 'react'
import styled from 'styled-components';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import fonts from 'theme/fonts';
import sizes from 'theme/sizes';

export default function Paginate({currentPage, totalPages, usersPerPage, onPaginate, totalUsers}) {
    const maxItems = 8;
    const maxLeft = Math.floor(maxItems / 2);
    const maxRight = Math.ceil(maxItems / 2);
    const firstPage = Math.max(currentPage - maxLeft, 1);
    const lastPage = Math.min(currentPage + maxRight, totalPages);

    const previousPage = () => {
        if (currentPage !== 1) {
            const numberPage = currentPage - 1
            onPaginate(numberPage)
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(totalUsers / usersPerPage)) {
            const number = currentPage + 1
            onPaginate(number);
        }
    }

    const handlePages = async(e) => {
       const numberPage = e.target.value
       await onPaginate(numberPage)
    }

    
  return (
    <ContainerPaginate>
        <ContentPaginate>
            {totalPages > 1 && (
                <ContainerButton>
                    <BtnPageAction 
                        previous
                        disabled={currentPage !== 1 ? false : true} onClick={() => previousPage()}
                    >
                        Anterior
                    </BtnPageAction>
                </ContainerButton>
            )}
            
            {
                Array.from({length: lastPage - firstPage + 1})
                .map((_, index)  => index + firstPage)
                .map((page) => (
                    <ContainerButton>
                        <BtnPageNumber
                            key={page} 
                            value={page}
                            onClick={(e) => handlePages(e)}
                            active={page === currentPage}
                        >
                            {page}
                        </BtnPageNumber>
                    </ContainerButton>   
                ))
            }
            {totalPages > 1 && (
                <ContainerButton>
                    <BtnPageAction next disabled={currentPage !== totalPages ? false : true} onClick={() => nextPage()}>Próxima</BtnPageAction>
                </ContainerButton>
            )}
        </ContentPaginate> 
    </ContainerPaginate>
  )
}

const ContainerPaginate = styled(Row)`
    margin-top: ${sizes.immense};
    height: ${sizes.immense};
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ContentPaginate = styled(Row)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ContainerButton = styled(Row)`
    width: ${sizes.immense};
    height: ${sizes.medium};
    display: flex;
    align-items: center;
    justify-content: center;
`;
const BtnPageAction = styled.button`
    height: auto;
    width: 60px;
    background: ${colors.neutralLight};
    border: ${colors.neutralLight};
    color: ${colors.primary};
    border-radius: 3px;
    font-size: ${sizes.little};
    font-weight: ${fonts.weight.bold};
    box-shadow: -2px 3px 5px ${colors.nightLight};
    padding: 5px;
    font-size: ${sizes.little};
    &:hover {
        background: ${colors.primary};
        border:  ${colors.neutral};
        font-size: ${sizes.little};
        color: ${colors.neutral};
        padding: 5px;
    }
    ${({previous}) => previous && `
        margin-right: ${sizes.medium};
    `}
    ${({next}) => next && `
        margin-left: ${sizes.medium};
    `}
`;

const BtnPageNumber = styled.button`
    height: ${sizes.big};
    width: ${sizes.large};
    background: ${colors.neutral};
    border:  ${colors.neutral};
    color: ${colors.primary};
    border-radius: 3px;
    font-size: ${fonts.sizes.small};
    font-weight: ${fonts.weight.bold};
    box-shadow: -2px 3px 5px ${colors.nightLight};
    &:hover {
        background: ${colors.primary};
        border: ${colors.neutralLight};
        color: ${colors.neutral};
    }
    ${({active}) => active && `
        background: ${colors.primary};
        border: ${colors.neutralLight};
        color: ${colors.neutral};
    `}
`;