import React, { createContext, useState, useContext } from 'react';

const PartnersListContext = createContext();

export default function PartnersListProvider({ children }) {
    const [partnersList, setPartnersList] = useState('');

    return (
        <PartnersListContext.Provider value={{ partnersList, setPartnersList }}>
            {children}
        </PartnersListContext.Provider>
    );
}

export function usePartnersList() {
    const context = useContext(PartnersListContext);

    if (!context) throw new Error('useUser must be used within a PartnersListProvider');

    const { partnersList, setPartnersList } = context;
    return { partnersList, setPartnersList };
}