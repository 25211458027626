import React from 'react'
import protectedRoutes from './protected'
import publicRoutes from './public'


import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import useAuth from "hooks/useAuth";
import paths from "routes/paths";


export default function () {
    const { isAuth } = useAuth();
    return (
        <BrowserRouter>
            <Routes>
                {
                    <Route
                        exact
                        path='/'
                        element={isAuth ? <Navigate to={paths.statistics} /> : <Navigate to={paths.login} />}
                    />
                }


                {
                    isAuth
                        ? protectedRoutes.map((route, i) => (
                            <Route key={i} {...route} />
                        ))
                        : (<Route exact path='*' element={<Navigate to={paths.login} />} />)
                }

                {
                    !isAuth
                        ? publicRoutes.map((route, i) => (
                            <Route key={i} {...route} />
                        ))
                        : (<Route exact path='*' element={<Navigate to={paths.statistics} />} />)

                }
            </Routes>
        </BrowserRouter>
    )
}