import Text from 'components/_UI/Text';
import React, {useState, useEffect} from 'react'
import { Row } from 'styled/alignment/Row';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';
import Icon from 'components/_UI/Icon';
import Menu from 'components/_UI/Menu';


export default function Content({ children, title }) {
    const [visibleMenu, setVisibleMenu] = useState(false)
    return (
        <Container>
            { visibleMenu ? (
                <Menu 
                    visibleMenu={visibleMenu}
                    setVisibleMenu={setVisibleMenu}
                />
            ) : ( 
                <ContainerSidebar>
                    <Sidebar />
                </ContainerSidebar>
            )}
            <Column>
                <ContainerMenu>
                    <ContentButtonMenu onClick={() => setVisibleMenu(!visibleMenu)}>
                       <Icon name='menu-mobile' />
                    </ContentButtonMenu>
                </ContainerMenu>
                <Text
                    name='hero'
                    marginTop={'60px'}
                    marginLeft={'60px'}
                >
                    {title}
                </Text>

                <StyledContent component={children.type.name}>
                    {children}
                </StyledContent>
            </Column>

        </Container>
    )
}

const Container = styled(Row)` 
    height: 100vh;
`
const StyledContent = styled.div`
    /* height: 100vh; */
    overflow: auto;
    padding: 10px 60px;
    @media screen and (max-width: 420px) {
        ${({component}) => component === 'FeatureFlagContent' &&`
            padding: 10px 40px;
        `}
        ${({component}) => component === 'EventsContent' &&`
            padding: 10px 40px;
        `}
    }
`;
const ContainerMenu = styled.div`
    display: flex;
    justify-content: flex-end;  
    @media screen and (min-width: 721px){
        display: none;
    }
`;

const ContainerSidebar = styled.div`
    height: 100%;
    display: flex;
     @media screen and (max-width: 720px){
        display: none;
    }
`;

const ContentButtonMenu = styled.div`
    margin-top: 15px;
    width: 40px;
    height: 25px;
    /* background: ${colors.primaryDark}; */
    border-radius: 5px;
`;
