import React from 'react';
import UserProvider from 'context/User';
import PresentationBackground from 'components/login/common/PresentationBackground';
import SignIn from 'components/login/sign-in/SignIn';

function Login() {

    return (
        <UserProvider>
            <PresentationBackground 
                ComponentToRender={() => <SignIn />} 
                is='signIn'
            />
        </UserProvider>
        
    )
};

export default Login;

