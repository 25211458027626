import React, { createContext, useState, useContext } from 'react';

const RolesContext = createContext();

export default function RolesProvider({ children }) {
    const [roles, setRoles] = useState('');
    return (
        <RolesContext.Provider value={{ roles, setRoles}}>
            {children}
        </RolesContext.Provider>
    );
}

export function useRoles() {
    const context = useContext(RolesContext);

    if (!context) throw new Error('useRoles must be used within a RolesProvider');

    const { roles, setRoles } = context;
    return { roles, setRoles };
}