import React, { createContext, useState, useContext } from 'react';

const TagsNaturalBeautyContext = createContext();

export default function TagsNaturalBeautyProvider({ children }) {
    const [tagsNaturalBeauty, setTagsNaturalBeauty] = useState('');
    return (
        <TagsNaturalBeautyContext.Provider value={{ tagsNaturalBeauty, setTagsNaturalBeauty}}>
            {children}
        </TagsNaturalBeautyContext.Provider>
    );
}

export function useTagsNaturalBeauty() {
    const context = useContext(TagsNaturalBeautyContext);

    if (!context) throw new Error('usetagsNaturalBeauty must be used within a tagsNaturalBeauty');

    const { tagsNaturalBeauty, setTagsNaturalBeauty } = context;
    return { tagsNaturalBeauty, setTagsNaturalBeauty };
}