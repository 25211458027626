import styled from 'styled-components';
import colors from 'theme/colors';
import marginProps from 'styled/shared-props/margin';


export const StyledDivider = styled.div`
    ${marginProps};
    
    width: 100%;
    height: 2px;
    background: ${colors.nightLight};
`;