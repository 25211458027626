import React, {useState} from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import TagsNaturalBeautyResult from './TagsNaturalBeautyResult';
import ButtonAdd from 'components/_UI/ButtonAdd';
import { Row } from 'styled/alignment/Row';
import CreateTagsNaturalBeautyForm from './form/CreateTagsNaturalBeautyForm';

export default function TagsNaturalBeautyContent() {
  const [openModal, setOpenModal] = useState(false)
  return (
    <Container>
      <ContainerModal>
        <ButtonAdd onClick={() => setOpenModal(true)}>
            Adicionar Categoria
        </ButtonAdd>
      </ContainerModal>
      { openModal && (
        <CreateTagsNaturalBeautyForm 
          openModal={openModal} 
          closeModal={() => setOpenModal(false)}
        />
      )}
        <TagsNaturalBeautyResult />
    </Container>
  )
}

const Container = styled(Column)`
  width: 100%;
`;

const ContainerModal = styled(Row)`
  display: flex;
  justify-content: flex-end
`;