import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import sizes from 'theme/sizes'

export default function DetailsScopes({visibleModal, setVisibleModal, data}) {
  return (
    <Modal isOpen={visibleModal} onClose={() => setVisibleModal(false)}>
        <ModalWrapper>
            <Container>        
                {Object.entries(data).map(([key, el]) => (
                    <Column key={el.id}>
                        <Text name='subtitle'>{key}</Text>
                        {el.map((el) => (
                            <Row>
                                <Text name='small' type='normal'>{el.name === 'create' ? 'Criação' : el.name === 'read' ? 'Leitura' : el.name === 'delete' ? 'Deletar' : el.name === 'update' && 'Atualizar' }</Text>
                                <Text marginLeft={sizes.tiny} color={JSON.stringify(el.allowed) === 'true' ? colors.successDark : colors.danger}>{JSON.stringify(el.allowed) === 'true' ? 'Permitido' : 'Não permitido'}</Text>
                            </Row>
                        ))}
                    </Column>
                ))}
            </Container>
        </ModalWrapper>
    </Modal>
  )
}


const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const ModalWrapper = styled.div`
    padding: ${sizes.large} ${sizes.none} ${sizes.large} ${sizes.none};
`;

const ContentStatus = styled(Row)`
  
`