import styled from "styled-components";
import { FullyCentered } from "styled/alignment/Center";
import colors from "theme/colors";
import sizes from "theme/sizes";
import indexPriority from "theme/z-index";

export const StyledModalOverlay = styled(FullyCentered)`
    z-index: ${indexPriority.enormous};
    position: fixed;
    left: ${sizes.none};
    background: rgba(26, 26, 26, .9);
    width: 100%;
    height: 100vh;
    top: ${sizes.none};        
    padding: ${sizes.gigantic};
`;

export const StyledModal = styled(FullyCentered)`
    justify-content: space-between; 
    position: relative;
    width: 550px;
    max-height: 700px;
    flex-direction: column;
    background: ${colors.neutralLight};
    padding: ${sizes.gigantic} ${sizes.large} ${sizes.big} ${sizes.large};
    border-radius: ${sizes.nano};
    z-index: ${indexPriority.tremendous};
`;

export const StyledModalContent = styled.div`
    text-align: ${ props => props.textAlign};
    width: 100%;
    overflow: auto;
    position: relative;
    padding: ${sizes.none} ${sizes.medium} ${sizes.none} ${sizes.medium};
    border-radius: ${sizes.nano};
    display: flex;
    flex-direction: column;  
    justify-content: space-between;
`;

export const StyledCloseModal = styled.div`
  align-self: flex-end; 
  width: ${sizes.intermediary};
  height: ${sizes.intermediary};
  cursor: pointer;
  & > svg {
    transition: all ease-in-out 0.3s;
  }
  &:hover {
    & > svg {
      fill: ${colors.nightDark};
    }
  }
`; 
