import api from "api";

const roles = {
    get: () => {
        return api().get('/roles');
    },
    create: (payload) => {
        return api().post('/roles', payload);
    },
    update: (payload) => {
        return api().post('/roles/users', payload);
    },
    block: (payload) => {
        return api().post('/roles/users', payload);
    },
}

export default roles;