import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import colors from 'theme/colors';
import Text from './Text';
import { FullyCentered } from 'styled/alignment/Center';
import { Row } from 'styled/alignment/Row';
import Icon from './Icon';

export default function ImageUpload({ onToggleFile }) {
    const [dropzoneVisible, setDropzoneVisible] = useState(true);
    const [files, setFiles] = useState([]);
    const prevFilesRef = useRef([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            const updatedFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
            setFiles(updatedFiles);
            setDropzoneVisible(false);
        },
    });

    const deleteFile = () => {
        setDropzoneVisible(true);
        setFiles([]);
        onToggleFile([]);
      };

    useEffect(() => {
        const prevFiles = prevFilesRef.current;
        if (JSON.stringify(files) !== JSON.stringify(prevFiles)) {
            onToggleFile(files);
            // Revoke data URLs when component unmounts
            return () => {
                files.forEach((file) => URL.revokeObjectURL(file.preview));
            };
        }
    }, [files, onToggleFile]);

    useEffect(() => {
        prevFilesRef.current = files;
    }, [files]);

    const renderThumbs = files.map((file) => (
        <Thumb key={file.name}>
            <CloseThumb>
                <CloseThumbIcon onClick={deleteFile}>
                    <Icon name="close" color={colors.night} />
                </CloseThumbIcon>
            </CloseThumb>
            <ThumbInner>
                <ThumbImage src={file.preview} />
            </ThumbInner>
        </Thumb>
    ));

    return (
        <span>
            {dropzoneVisible ? (
                <Dropzone {...getRootProps()}>
                    <DropzoneContent>
                        <input {...getInputProps()} />
                        <IconContainer>
                            <Icon name="camera" color={colors.night} />
                        </IconContainer>
                        <Text>Clique ou solte sua imagem aqui.</Text>
                    </DropzoneContent>
                </Dropzone>
            ) : (
                <ThumbsContainer>{renderThumbs}</ThumbsContainer>
            )}
        </span>
    );
}

const Dropzone = styled(FullyCentered)`
    width: 100%;
    height: 110px;
    padding: 15px;
    border: dashed 2px ${colors.nightLight};
    border-radius: 4px;
    background: ${colors.neutralLight};
    cursor: pointer;
    transition: all ease-in-out .25s;

    &:hover {
        border: dashed 2px ${colors.secondary};
    }
`;

const ThumbsContainer = styled(Row)`
    flex-wrap: wrap;
    margin-top: 16px;
`;

const Thumb = styled.picture`
    display: inline-flex;
    border-radius: 4px;
    border: 1px solid ${colors.night};
    margin-bottom: 8px;
    width: 100%;
    height: 160px;  
    padding: 4px;
    position: relative;
`;

const IconContainer = styled.picture`
    width: 46px;
    height: 46px;
`;

const ThumbInner = styled.picture`
    display: flex;
    min-width: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

const ThumbImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
`

const CloseThumb = styled(FullyCentered)`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${colors.nightDark};
    transition: all ease-in-out .25s; 
    opacity: 0.8;
`;

const CloseThumbIcon = styled.div`
    width: 30px;
    height: 30px;
    background: ${colors.neutralDark};
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
`

const DropzoneContent = styled(FullyCentered)`
    flex-direction: column;
    padding: 30px;
`;

