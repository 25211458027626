import React from 'react'
import { StyledDivider } from 'styled/UI/StyledDivider';
import sizes from 'theme/sizes';

function Divider({
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
}) {
  return (
    <StyledDivider  
        marginTop={marginTop}
        marginRight={marginRight}
        marginBottom={marginBottom}
        marginLeft={marginLeft}     
    />
  )  
};

Divider.defaultProps = {
  marginTop: sizes.normal,
  marginBottom: sizes.normal,
  marginLeft: sizes.none,
  marginRight: sizes.none
};

export default Divider;