import React, { useEffect } from 'react'
import styled, {css} from 'styled-components'
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import Icon from './Icon';
import { useActionForTables } from 'context/ActionForTables';

export default function Table({
    columns,
    rows,
    actionClicked
}) {
    
    const {actionForTables} = useActionForTables()
    useEffect(() => {
    }, [rows, columns])

    return (
        <StyledTable>
            <TableHeader>
                {
                    columns?.map((item, index) => (
                        <TableHeaderItem key={index}> {item} </TableHeaderItem>
                    ))
                }
            </TableHeader>
            <TableBody>
                {
                    rows && rows?.map((el, index) => (
                        <TableBodyRow key={el.id}>
                            {
                                Object.values(el)
                                .filter((el) => typeof el !== 'number')
                                .map(item => {
                                    return (
                                        <TableBodyItem key={item} invisible={typeof item === 'object'}>
                                            <ContainerText color={item === 'Ativa' || item === 'Desativada' ? item : null}>
                                                {item}
                                            </ContainerText>
                                        </TableBodyItem>
                                    )
                                })}
                            {
                                actionForTables && 
                                <TableBodyItem key={index}>{actionForTables && actionForTables[index] && actionForTables[index].map(icon => {
                                    if (el.is_active === 'Ativa' && icon !== 'delete'  && icon === 'disabled') {
                                        return (
                                            <IconContainer 
                                                onClick={() => actionClicked({...el, actionSelected: 'disabled'})}
                                            
                                                >
                                                <Icon name='disabled' color={colors.danger} />
                                            </IconContainer>
                                        )
                                    }
                                    if (icon === 'edit') {
                                        return (
                                            rows && (

                                                <IconContainer onClick={() => actionClicked({...el, actionSelected: 'edit'})}
                                                
                                                >
                                                    <Icon name='edit' color={colors.night} />
                                                </IconContainer>
                                            )  
                                        )
                                    }
                                    if (icon === 'delete') {
                                        return (
                                            <IconContainer 
                                                onClick={() => actionClicked({...el, actionSelected: 'delete'})}
                                            
                                                >
                                                <Icon name='delete' color={colors.night} />
                                            </IconContainer>
                                        )
                                    }
                                    if (el.is_active === 'Desativada' &&  icon !== 'delete' && icon === 'active') {
                                        return (
                                            <IconContainer 
                                                onClick={() => actionClicked({...el, actionSelected: 'active'})}
                                            
                                                >
                                                <Icon name='active' color={colors.success} />

                                            </IconContainer>
                                        )
                                    }
                                    })}
                                </TableBodyItem>
                            }
                                    
                        </TableBodyRow>
                    )
                    )
                }
            </TableBody>
        </StyledTable >
    )
}


const StyledTable = styled.div`
    border: 1px;
    border: 1px 0 0 1px solid ${colors.neutralDark};
    border-radius: 6px;
`;

const TableHeader = styled(Row)`
    justify-content: space-around;
    padding: 16px 6px;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    text-transform: capitalize;
`;

const TableBody = styled(Column)`
`;

const TableHeaderItem = styled.div`
    text-align: left;
    width: 100%;
    padding: 0 6px ;
`;

const TableBodyRow = styled(Row)`
    align-items: center;
    width: 100%;
    justify-content: space-around;
    border-bottom: 1px solid #ccc;

    &:nth-child(odd) {
        background: ${colors.neutral};
    }
`;

const TableBodyItem = styled(Row)`
    width: 100%;
    justify-content: space-between;
    padding: 12px;
    

    ${({ invisible }) => invisible && `
        display: none;
    `}

    &:last-child{
        justify-content: flex-start;

    }

   
`;

const IconContainer = styled.div`
    width: 22px;
    height: 22px;
    margin-left: 14px;
    cursor: pointer;
    transition: all ease-in-out .2s;
    &:first-child{
        margin-left: 0;
    }

    &:hover {
        transform: scale(1.1);
    }
`;
const ContainerText = styled(Row)`
     @media screen and (max-width: 820px) {
        &:nth-child(1) {
            width: 80px;
            max-width: 120px;
        }
     }
    ${({ color }) => color === 'Ativa' && css`
        width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: ${colors.success};
    `};
        ${({ color }) => color === 'Desativada' && css`
        justify-content: center;
        width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        background: ${colors.danger};
    `};
`;