import Text from 'components/_UI/Text'
import React from 'react'
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import sizes from 'theme/sizes'

export default function Events({data}) {
  return (
    <>
        <Text
            name='subtitle'
            marginTop={sizes.medium}
        >
            Usuários
        </Text>
        <Content>
            <Card large>
                <Row fullyCentralized>
                    <Column fullyCentralized>
                        <Text name='hero'>
                            {data?.count_users}
                        </Text>
                        <Text name='highlight'>
                            Total de Usuários
                        </Text>
                    </Column>
                    <Column fullyCentralized>
                        <Row fullyCentralized>
                            <Column fullyCentralized>    
                                <Text name='hero'>{data?.count_users_verifieds}</Text>
                                <Text name='highlight'>Verificados</Text>
                            </Column>
                        </Row>
                    </Column>
                </Row>
            </Card>
            <Card>
                <Text name='hero'>
                    {data?.count_leads}
                </Text>
                <Text name='highlight'>
                    Leads
                </Text>
            </Card>
        </Content>
        
    </>
  )
}
const Card = styled(FullyCentered)`
    width: 19.5%;
    height: 130px;
    box-shadow: ${sizes.none} ${sizes.nano} ${sizes.nano} ${colors.nightLight};
    border-radius: ${sizes.mini};
    background: ${colors.neutral};
    flex-direction: column;
    margin-left: ${sizes.medium};
    ${({large}) => large && `
        width: 40%;
    `}
    &:nth-child(1) {
        margin-left: ${sizes.none};
    }
    @media screen and (max-width: 820px) {
        flex-direction: column;
        width: 100%;
        margin-left: 0;
        margin-top: 15px;
        &:nth-child(1) {
            margin-top: ${sizes.none};
        }
    }
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 820px) {
        flex-direction: column;
        width: 100%;
        /* margin-left: 10px; */
        /* margin-top: 15px; */
        &:nth-child(1) {
            margin-left: ${sizes.none};
        }
    }

`
