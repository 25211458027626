import React, { createContext, useState, useContext } from 'react';

const EventsTypesContext = createContext();

export default function EventsTypesProvider({ children }) {
    const [eventsTypes, setEventsTypes] = useState(null);

    return (
        <EventsTypesContext.Provider value={{eventsTypes, setEventsTypes}}>
            {children}
        </EventsTypesContext.Provider>
    );
}

export function useEventsTypes() {
    const context = useContext(EventsTypesContext);
    
    if (!context) throw new Error('useEventsTypes must be used within a EventsTypes');
    
    const { eventsTypes, setEventsTypes } = context;
    return { eventsTypes, setEventsTypes };
}