import Button from 'components/_UI/Button';
import Text from 'components/_UI/Text'
import React, {useState} from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column';
import DetailsScopes from '../detail/DetailsScopes';
import sizes from 'theme/sizes';
import colors from 'theme/colors';


export default function CardScopes({data}) {
    const [visibleModal, setVisibleModal] = useState(false)
    const scopesSize = Object.keys(data?.scopes).map(key => data.scopes[key]);
    const arrayNew = scopesSize.map((el) => el)

  return (
    <>
        <Content>
            <CardScope>
                <>
                    {Object.entries(data.scopes).map(([key, el]) => (
                        <ContentCard>
                            <Column>
                                <Text name='subtitle'>{key}</Text>
                                <Text type='bold'>Permissões Ativas</Text>
                                {el.filter(el => el.allowed !== false).slice(0,3).map((el) => (
                                    <ContentData>
                                        <Text name='small' type='italic'>{el.name === 'create' ? 'Criação' : el.name === 'read' ? 'Leitura' : el.name === 'delete' ? 'Deletar' : el.name === 'update' && 'Atualizar' }</Text>
                                    </ContentData>
                                ))}
                            </Column>
                            
                        </ContentCard>
                    ))}
                </>
                {
                     arrayNew[0].length > 3 && (
                        <ContentButtonMore>
                            <Button size='small' marginTop={sizes.tiny} onClick={() => setVisibleModal(true)}>Ver mais</Button>
                        </ContentButtonMore>
                    )
                }
            </CardScope>
        </Content>
        { visibleModal &&     
            <DetailsScopes data={data.scopes} visibleModal={visibleModal} setVisibleModal={setVisibleModal} />   
        }
    </>
  )
}

const Content = styled.div`
    width: 200px;
    height: 190px;
    margin: 12px 2px 12px 2px;
    margin: 10px 10px 10px 10px;
    border-radius: 10px;
    box-shadow: 2px 8px 10px #ccc;        
    padding: 6px;
`;

const CardScope = styled(Column)`
    width: 100%;
`;

const ContentButtonMore = styled(Column)`
    height: 50px;
`;

const ContentCard = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
`;

const ContentData = styled.div`
    display: flex;
    flex-direction: column;
`;

const ContainerActions = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;


const ContentButtonAction = styled.button`
    width: 17px;
    height: 17px;
    border-radius: 40px;
    padding: 4px;
    cursor: pointer;
    background: ${colors.danger};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out .25s;
    ${({edit}) => edit && `
        background: ${colors.primary};
        border: ${colors.neutralLight};
        color: ${colors.neutral};
        font-size: 10px;
        padding: 4px;
        margin-left: 5px;
        &:hover {
            transform: scale(1.01);
        }
   
    `}
    &:hover {
        transform: scale(1.03);
    }
   

`;
