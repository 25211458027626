import ButtonAdd from 'components/_UI/ButtonAdd'
import React, {useState} from 'react'
import styled from 'styled-components'
import CreateTouristSpotsForm from './form/CreateTouristSpotsForm'
import TouristSpotsResult from './TouristSpotsResult'

export default function TouristSpots() {
    const [modalVisible, setModalVisible] = useState(false)
  return (
    <> 
        <ContentBtnAdd>
            <ButtonAdd onClick={() => setModalVisible(true)}>
                Adicionar Ponto Turístico
            </ButtonAdd>
        </ContentBtnAdd>
        {
            modalVisible && (
                <CreateTouristSpotsForm  
                    setModalVisible={setModalVisible} 
                    modalVisible={modalVisible}
                />
            )
        }
        <TouristSpotsResult />
    </>
  )
}
const ContentBtnAdd = styled.div`
    display: flex;
    justify-content: flex-end;
`;
