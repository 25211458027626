import api from "api";

const events = {
    get: () => {
        return api().get('/events');
    },
    list: (number) => {
        return api().get(`/events/list?page=${number}`);
    },
    create: (payload) => {
        return api().post('/events', payload);
    },
    update: (slug, payload) => {
        return api().put(`/events/${slug}`, payload);
    },
    delete: (payload) => {
        return api().delete(`/events/${payload}`);
    },
    filter: (payload) => {
        return api().get('/events', {params: payload});
    },
};

export default events;  