import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from 'components/_UI/Button'
import sizes from 'theme/sizes'
import { Column } from 'styled/alignment/Column'
import Text from 'components/_UI/Text'
import { FullyCentered, HorizontalCenter } from 'styled/alignment/Center'
import Divider from 'components/_UI/Divider'
import { Row } from 'styled/alignment/Row'
import date from 'helpers/date'
import userApi from 'api/requests/user';
import roleApi from 'api/requests/roles';
import { toast } from 'react-toastify';
import { StyledForm } from 'styled/UI/StyledForm'
import Fieldset from 'components/_UI/Fieldset'
import masks from 'helpers/masks';
import {useForm } from 'react-hook-form'
import phoneFormatter from 'helpers/phone'
import fieldValidations from 'helpers/fieldValidations'
import unformat from 'helpers/unformat'
import Loader from 'components/_UI/Loader'
import colors from 'theme/colors'
import { useUsersFilter } from 'context/UsersFilter';
import TextArea from 'components/_UI/TextArea'
import fonts from 'theme/fonts'
import { useUser } from 'context/User'
import rolesApi from 'api/requests/roles'



export default function UserCardDetails({ data, closeModal }) {
  const { usersFilter, setUsersFilter } = useUsersFilter();
  const [toogleAction, setToogleAction] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [alterPayload, setAlterPayload] = useState(true)
  const [selectValue, setSelectValue] = useState(null)
  const [visibleRole, setVisibleRole] = useState(false)
  const [role, setRole] = useState('')
  const [isDisabled, setIsDisabled]= useState(true)
  const formatDate = (dateContent) => date.format(dateContent);
  const {user, setUser} = useUser()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      userName: data.username,
      firstName: data.first_name,
      lastName: data.last_name,
      gender: data.gender || 'Não informado',
      phone: data.phone,
      birthdate: date.format(data.birthdate),
      biography: data.bio || 'Não informada',
      role: data.role.name
    }
  });

  useEffect(() => {
    if(!role) {
      getRoles()
    }
    if(!user) {
      getUser()
    } else if(user.name === 'Admin'){
        setVisibleRole(true)
    }

    if(selectValue) {
      const convert = parseInt(selectValue)
      if(convert !== data.role.id) {
        setIsDisabled(!isDisabled)
        setAlterPayload(false)
      }
    }

    watch((values) => {
      setAlterPayload(false)
    });

    return () => { };

  }, [watch, selectValue]);

  
  

  const actionsButtons = {
    delete: 'DELETE',
    block: 'BLOCK',
  };

  
  const getUser = async() => {
    try {
      const {data} = await userApi.getUserMe()
      setUser(data.role)
      if(data.role.name === 'Admin') {
        setVisibleRole(!visibleRole)
      }
    } catch (error) {
      toast.error('Ocorreu um erro inesperado', {
        position: 'top-center'
      })
    }

  }
  const getRoles = async () => {
    try {
      const { data } = await rolesApi.get()
      setRole(data.data)
    } catch (error) {
      toast.error('Erro não foi possível encontrar nenhum usuário!')
    }
  }
 
  const onConfirmAction = () => {
    if (toogleAction === actionsButtons.delete) {
      deleteUser();
      return;
    }
    if (toogleAction === actionsButtons.block) {
      blockUser();
      return;
    }
  };

  const removeObjectWithId = (arr, id) => {
    const objWithIndex = arr.findIndex(obj => obj.id === id)
    if (objWithIndex > -1) {
      arr.splice(objWithIndex, 1)
    }
    return arr
  }
  

  const deleteUser = async () => {
    setIsloading(true);
    const payload = data.id;

    try {
      await userApi.delete(payload);
      toast.success('Usuário excluido com sucesso!', {
        position: 'top-center',
      });

      setUsersFilter({ ...usersFilter, loading: false, data: removeObjectWithId(usersFilter.data, data.id) })

    } catch (error) {
      toast.error('Erro não foi possível excluir o usuário!', {
        position: 'top-center',
      });
    } finally {
      setIsloading(false);
      closeModal();
    }


  };

  const submitRole = async() => {
    if(data.role.name === 'Admin') {
      toast.warn('Não é possível editar a permissão de um Admin!', {
        position: 'top-center'
      })
      return
    }
    const roleConvert = parseInt(selectValue)
    const payload = {
      user_id: data.id,
      role_id: roleConvert || data.role.id
    }
    try {
       await rolesApi.update(payload)
    } catch (error) {
      toast.error('Ocorreu um erro inesperado', {
        position: 'top-center'
      })
    }
  }

  const editUser = async ({ userName, firstName, lastName, gender, phone, birthdate, biography }) => {
    setIsloading(true)
    const id = data.id
    
    const payload = {
      id,
      username: userName,
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      phone: unformat.getNumbers(phone),
      birthdate: date.unformat(birthdate),
      bio: biography,
    }

    const filteredPayload = Object.entries(payload)
      .filter(([key, value]) => value !== 'Não informado' && value !== null)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {})

    try {
      setIsEdit(true)

      const { data } = await userApi.update(`${id}`, filteredPayload)

      const newContent = removeObjectWithId(usersFilter.data, data.id)
      setUsersFilter({ ...usersFilter, loading: true, data: [...newContent, filteredPayload] })

      if(user.name === 'Admin') {
        submitRole()
      }
      toast.success('Usuário editado com sucesso!', {
        position: 'top-center'
      })
    } catch (error) {
      toast.error('Não foi possível editar o usuário!', {
        position: 'top-center'
      })
    } finally {
      setIsloading(false)
      setUsersFilter({ ...usersFilter.data, loading: false })
      closeModal();
    }
  };

  const blockUser = async () => {
    setIsloading(true)
    const payload = {
      user_id: data.id,
      role_id: 4
    }

    try {
      await roleApi.block(payload)
      setUsersFilter({ ...usersFilter, loading: true })
      toast.success('Usuário bloqueado com sucesso!', {
        position: 'top-center'
      })
    } catch (error) {
      toast.error('Não foi possível bloquear o usuário!', {
        position: 'top-center'
      })
    } finally {
      setIsloading(false)
      closeModal()
      setUsersFilter({ ...usersFilter, loading: false })
      setToogleAction(false);
    }
  };

  return (
    <DetailsContainer>
      {
        isEdit ? (
          <ContainerEditUser>
            <Text
              name="highlight"
            >
              Editar dados do Usuário
            </Text>
            <StyledForm onSubmit={handleSubmit(editUser)}>
              <Fieldset
                layout='rounded'
                label='Nome de usuário'
                name='userName'
                register={register}
                validations={fieldValidations.name('nome de usuário')}
              />
              {errors.userName?.message && (
                <HorizontalCenter>
                  <Text
                    color={colors.danger}
                  >
                    {errors.userName?.message}
                  </Text>
                </HorizontalCenter>
              )}
              <Fieldset
                layout='rounded'
                label='Primeiro nome'
                name='firstName'
                register={register}
                validations={fieldValidations.name('primeiro nome')}
              />
              {errors.firstName?.message && (
                <HorizontalCenter>
                  <Text
                    color={colors.danger}
                  >
                    {errors.firstName?.message}
                  </Text>
                </HorizontalCenter>
              )}
              <Fieldset
                layout='rounded'
                label='Último nome'
                name='lastName'
                register={register}
                validations={fieldValidations.name('último nome')}
              />
              {errors.lastName?.message && (
                <HorizontalCenter>
                  <Text
                    color={colors.danger}
                  >
                    {errors.lastName?.message}
                  </Text>
                </HorizontalCenter>
              )}
              <Fieldset
                layout='rounded'
                label='Gênero'
                name='gender'
                register={register}
              />
              {errors.gender?.message && (
                <HorizontalCenter>
                  <Text
                    color={colors.danger}
                  >
                    {errors.gender?.message}
                  </Text>
                </HorizontalCenter>
              )}
              {
                visibleRole && (
                  <Column>
                    <ContainerRole>
                      <ContentRole>
                        <LabelRole>Selecione uma permissão</LabelRole>
                      </ContentRole>
                    </ContainerRole>
                      <SelectRole
                        name='role'
                        defaultValue={data.role.name}
                        onChange={e => setSelectValue(e.target.value)}
                      >
                        <option value={data.role.name} disabled={isDisabled} >{data.role.name}</option>
                        {Object.values(role).filter(el => el.name !== data.role.name).map((el) => (
                          <option value={el.id} key={el.name}>
                            {el.name}
                            </option>
                          ))}
                      </SelectRole>
                  </Column>
                )
              }
              <Fieldset
                layout='rounded'
                label='Telefone'
                mask={masks.phone}
                name='phone'
                register={register}
                validations={fieldValidations.phone()}
              />
              {errors.phone?.message && (
                <HorizontalCenter>
                  <Text
                    color={colors.danger}
                  >
                    {errors.phone?.message}
                  </Text>
                </HorizontalCenter>
              )}
              <Column>
                <ContentTextArea>
                  <ContentLabel>
                    <LabelTextArea>
                      Descrição
                    </LabelTextArea>
                  </ContentLabel>
                </ContentTextArea>

                <TextArea
                  name='biography'
                  maxLength={600}
                  register={register}
                />
              </Column>
              <Fieldset
                layout='rounded'
                label='Data de nascimento'
                mask={masks.date}
                name='birthdate'
                register={register}
                validations={fieldValidations.date('data de nascimento')}
              />
              {errors.birthdate?.message && (
                <HorizontalCenter>
                  <Text
                    color={colors.danger}
                  >
                    {errors.birthdate?.message}
                  </Text>
                </HorizontalCenter>
              )}
              {!isLoading ? (
                <Button
                  type='submit'
                  variant={alterPayload ? 'disabled' : 'primary'}
                >
                  Editar
                </Button>
              ) : (
                <Loader />
              )
              }
            </StyledForm>
          </ContainerEditUser>
        ) : (
          <>
            <Text name="highlight">Detalhes do Usuário </Text>

            <ContainerImage>
              <ImageDetail>
                {
                  data.avatar
                    ? <ImageContent src={data.avatar} />
                    : (
                      <ImageContentWord>
                        <Text name='hero' color={colors.neutral}> {data.first_name.charAt(0).toUpperCase()} </Text>
                      </ImageContentWord>
                    )
                }

              </ImageDetail>
            </ContainerImage>

            <ContentItems>
              <CardDetailsContent>
                <Text type='bold'> @{data.username} </Text>
              </CardDetailsContent>

              <CardDetailsContent>
                <Text type="bold" marginRight={sizes.micro} > Nome Completo: </Text>
                <Text> {data.first_name} {data.last_name} </Text>
              </CardDetailsContent>

              <CardDetailsContent>
                <Text type="bold" marginRight={sizes.nano} > Tipo de permissão: </Text>
                <Text> {data?.role?.name} </Text>
              </CardDetailsContent>

              <CardDetailsContent>
                <Text type="bold" marginRight={sizes.nano} > Gênero: </Text>
                <Text> {data.gender ? data.gender : 'Não Informado'} </Text>
              </CardDetailsContent>

              <CardDetailsContent>
                <Text type="bold" marginRight={sizes.nano} > Email: </Text>
                <Text> {data.email} </Text>
              </CardDetailsContent>

              <CardDetailsContent>
                <Text type="bold" > Data de nascimento: </Text>
                <Text marginLeft={sizes.nano}> {data.birthdate ? formatDate(data.birthdate) : 'Não Informada'} </Text>
              </CardDetailsContent>

              <CardDetailsContent>
                <Text type="bold" > Telefone: </Text>
                <Text marginLeft={sizes.nano}> {data.phone ? phoneFormatter(data.phone): 'Não Informado'} </Text>
              </CardDetailsContent>

              <CardDetailsContent>
                <Text type="bold"> Data de registro:</Text>
                <Text marginLeft={sizes.nano}>  {data.confirmed_email_at ? `${formatDate(data.confirmed_email_at)}` : 'Não verificado'} </Text>
              </CardDetailsContent>

            </ContentItems>


            <DividerContainer>
              <DividerContent>
                <Divider marginTop={sizes.big} />
              </DividerContent>
            </DividerContainer>
            {toogleAction === actionsButtons.delete || toogleAction === actionsButtons.block ? (
              !isLoading ? (

                <Column>
                  <Text
                    type="bold"
                    marginTop={sizes.normal}
                  >
                    Tem certeza dessa ação?
                  </Text>
                  <ButtonsContent>
                    <Button
                      size="small"
                      category="rounded"
                      marginRight={sizes.mini}
                      onClick={() => onConfirmAction()}
                    >
                      Confirmar
                    </Button>
                    <Button
                      size="small"
                      category="rounded"
                      onClick={() => setToogleAction(false)}
                    >
                      Cancelar
                    </Button>
                  </ButtonsContent>
                </Column>
              ) : (
                <Loader />
              )
            ) : (
              <ButtonsContent>
                <Button
                  marginRight={sizes.micro}
                  marginLeft={sizes.micro}
                  size="small"
                  onClick={() => setToogleAction(actionsButtons.delete)}
                >
                  Excluir
                </Button>
                <Button
                  marginRight={sizes.micro}
                  size="small"
                  onClick={() => setIsEdit(true)}
                >
                  Editar
                </Button>
                <Button
                  marginRight={sizes.micro}
                  size="small"
                  onClick={() => setToogleAction(actionsButtons.block)}
                >
                  Bloquear
                </Button>
              </ButtonsContent>
            )}
          </>
        )
      }
    </DetailsContainer>
  );
}

const DetailsContainer = styled(Column)`
    width: 100%;
`;
const CardDetailsContent = styled(Row)`
    width: auto;
    align-items: center;
    margin-top: 6px;

    &:first-child {
      margin-top: 20px;
      margin-bottom: 10px;
    }
`;
const ContainerImage = styled(FullyCentered)`
    width: 100%;
    margin-top: 20px;
`;
const ImageDetail = styled(Column)`
    width: 150px;
    height: 150px;
`;
const ImageContent = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
`;
const ButtonsContent = styled.div`
    display: flex;
    justify-content: space-around;
`;

const DividerContainer = styled(HorizontalCenter)`
    width: 100%; 
`;
const DividerContent = styled(HorizontalCenter)`
    width: 75%;
`;

const ContainerEditUser = styled(Column)`
    padding: ${sizes.none} ${sizes.none} ${sizes.none} ${sizes.none};
`;
const ContentTextArea = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;
const ContentLabel = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const LabelTextArea = styled.label`
  font-size: ${fonts.sizes.small};
  font-weight: ${fonts.weight.bold};
  font-family: 'Roboto', sans-serif;
`;

const ContentItems = styled(Column)`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ImageContentWord = styled(FullyCentered)`
  width: 150px;
  height: 150px;
  background: ${colors.night};
  border-radius: 50%;

  & > h1 {
    margin: 0;
    padding: 0;
  }
`;

const SelectRole = styled.select`
    width: 100%;
    outline-color: transparent;
    -webkit-transition: all ease .25s;
    transition: all ease .25s;
    background: transparent;
    outline: transparent;
    color: #707070;
    border: 2px solid #F2F2F2;
    border-radius: 30px;
    padding: 8px;
    font-weight: 700;
    background: transparent;
    color: #1A1A1A;
`;
const LabelRole = styled.label`
    color: #1A1A1A;
    font-size: 12px;
    font-weight: 700;
`;

const ContainerRole = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const ContentRole = styled.div`
  display: flex;
  justify-content: flex-start;
`