import React from "react";
import { GlobalStyle } from 'styled/global';
import Router from 'routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';



function App() {
  return (
    <>
      <Router />
      <GlobalStyle />
      <ToastContainer />
    </>
  );
};

export default App;
