import api from "api";

const naturalBeauty = {
    get: () => {
        return api().get('/natural-beauties')
    },
    create: (payload) => {
        return api().post('/natural-beauties', payload)
    },
    detail: (payload) => {
        return api().get(`/natural-beauties/detail/${payload}`)
    },
    delete: (payload) => {
        return api().delete(`/natural-beauties/${payload}`)
    }
}

export default naturalBeauty;