import React from 'react'
import TagCardResult from './card/TagCardResult'

export default function TagsContent() {
    
    return (
        <>
            <TagCardResult />
        </>
    )
}