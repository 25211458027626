import Text from 'components/_UI/Text'
import React from 'react'
import styled from 'styled-components'

export default function CardRoles({data}) {
  return (
    <>
      <Content>
          <Text name='highlight'>{data.name}</Text>
        {
          data.scopes.length ? (
            data.scopes.map((el) => (
                <Text name='small'>{el.name}</Text>
            ))
            ) : (
              <Text name='small' marginTop='10px'>Nenhum escopo atribuído!</Text>
          )
        }
      </Content>
    </>
  )
}

const Content = styled.div`
    width: 250px;
    height: 135px;
    margin: 12px 2px 12px 2px;
    cursor: pointer;
    transition: all ease-in-out .25s;
    margin: 10px 10px 10px 10px;
    border-radius: 10px;
    box-shadow: 2px 8px 10px #ccc;        
    padding: 6px;

    &:hover {
        transform: scale(1.01);
    }
   
`