import userApi from 'api/requests/user'
import Loader from 'components/_UI/Loader'
import Modal from 'components/_UI/Modal'
import Paginate from 'components/_UI/Paginate'
import SearchNotFound from 'components/_UI/SearchNotFound'
import Text from 'components/_UI/Text'
import { usePartnersList } from 'context/PartnersList'
import UserCard from 'modules/users/card/UserCard'
import UserCardDetails from 'modules/users/card/UserCardDetails'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import sizes from 'theme/sizes'
import PartnerCardDetails from '../detail/PartnerCardDetails'

export default function PartnerCardResult() {

    const {partnersList, setPartnersList} = usePartnersList()

    const [ totalUsers ,setTotalUsers] = useState([])   
    const [ currentPage , setCurrentPage ] = useState (1)
    const [ usersPerPage ,setUsersPerPage] = useState (null)
    const [totalPages , setTotalPages] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);



    useEffect(() => {
        getPartners()
    }, [])
    
    const getPartners = async(numberPage) => {
        
        const number = Number(numberPage)
        if(number === currentPage) {
            return
        }
        setIsLoading(true)
        try {
            const {data} = await userApi.get(numberPage)
            console.log(data);
            
            setPartnersList(data?.data)
            setCurrentPage(data?.meta.current_page);
            setUsersPerPage(data?.meta.per_page)
            setTotalPages(data?.meta.last_page)
            setTotalUsers(data?.meta.total)

        } catch (error) {
            toast.error('Não foi possível carregar nenhum usuário!', {
                position: 'top-center',
            });
        } finally {
            setIsLoading(false)

        }
    }

    const handleDetails = (item) => {
        setModalOpen(true);
        setSelectedItem(item);
    };
  return (
    <Container>
        <CardContainerResult>
            {
                !isLoading ? (
                    
                        partnersList ? (
                        partnersList?.filter(el => el?.role?.id == process.env.REACT_APP_PRODUCER_ROLE_ID || el.role.id == process.env.REACT_APP_ESTABLISHMENT_ROLE_ID).map(el => (
                            <ContentResult
                                key={el.id} 
                                onClick={() => handleDetails(el)}
                            >
                                <UserCard 
                                    data={el}
                                />
            
                            </ContentResult>
                        )) 
                    ) : (
                        <SearchNotFound />
                    )
                    
                

                ) : (
                    <FullyCentered>
                        <LoaderContainer>
                            <Loader 
                                size="large" 
                            />
                        </LoaderContainer>
                    </FullyCentered>

                )
            }

            {
                totalPages > 1 && (
                    <Paginate 
                      currentPage={currentPage} 
                      totalUsers={totalUsers}
                      totalPages={totalPages} 
                      usersPerPage={usersPerPage} 
                      onPaginate={getPartners}
                    />
                  )
            }
        </CardContainerResult>

        <Modal
            size="large"
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
        >
            <PartnerCardDetails
                data={selectedItem}
                closeModal={() => setModalOpen(false)}
            />
        </Modal>

    </Container>
  )
}


const Container = styled(Column)`
    margin-top: ${sizes.normal};
`;

const CardContainerResult = styled(Row)`
  width: 100%;
  overflow-y: unset;
  flex-wrap: wrap;
  @media screen and (max-width: 820px){
      justify-content: center;
  }
`;
const ContentResult = styled.div`
  cursor: pointer;
`;

const LoaderContainer = styled(Column)`
  width: 100%;
  height: 100vh;
`;