import Content from 'components/main/Content'
import PartnersListProvider from 'context/PartnersList'
import PartnersContent from 'modules/partners/PartnersContent'
import React from 'react'

export default function Partners() {
  return (
    <PartnersListProvider>
        <Content title='Parceiros'>
            <PartnersContent />
        </Content>
    </PartnersListProvider>
  )
}
