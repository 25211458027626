import React, {useState} from 'react'
import TagsSpotsResult from './TagsSpotsResult'
import CreateTagsForm from './form/CreateTagsForm'
import styled from 'styled-components'
import { Row } from 'styled/alignment/Row'
import ButtonAdd from 'components/_UI/ButtonAdd'


export default function TagsSpotsContent() {
  const [modalVisible, setModalVisible] = useState()
  return (
    <> 
        <ContentButtonCreateTags>
            <ButtonAdd
                onClick={() => setModalVisible(true)}
            >
                Adicionar Categoria
            </ButtonAdd>
        </ContentButtonCreateTags>
        {
            modalVisible && 
            <CreateTagsForm 
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
            />
        }
        <TagsSpotsResult />
    </>
  )
}

const ContentButtonCreateTags = styled(Row)`
    display: flex;
    justify-content: flex-end;
    width: 100%;

`;
