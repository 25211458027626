import api from "api";

const touristSpots =  {
    create: (payload) => {
        return api().post('/points', payload)
    },
    get: () => {
        return api().get('/points');
    }, 
    detail: (payload) => {
        return api().get(`/points/detail/${payload}`);
    }, 
    delete: (payload) => {
        return api().delete(`/points/${payload}`);
    }, 
}

export default touristSpots;