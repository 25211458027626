import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import React, {useState, useEffect} from 'react'
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { StyledForm } from 'styled/UI/StyledForm';
import sizes from 'theme/sizes';
import BasicInfo from './parts/BasicInfo';
import ImageInfo from 'modules/events/form/create/ImageInfo';
import { useNaturalBeauty } from 'context/NaturalBeauty';
import { toast } from 'react-toastify';
import imageUpload from 'api/requests/imageUpload';
import AddressInfo from './parts/AddressInfo';
import Loader from 'components/_UI/Loader';
import Button from 'components/_UI/Button';
import ExtraInfo from './parts/ExtraInfo';
import CategoriesInfos from './parts/CategoriesInfo';
import naturalBeautyApi from 'api/requests/naturalBeauty';

const emptyDays = {
  sunday: [] ,
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
}

export default function CreateNaturalBeautyForm({modalVisible, setModalVisible}) {
  const [formData] = useState(null);
  const {naturalBeauty, setNaturalBeauty} = useNaturalBeauty()
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const [tagsError, setTagsError] = useState(false);

  useEffect(() => {
    if (formData !== null) {
        setNaturalBeauty({ ...naturalBeauty, images: formData });
    }
  }, [formData, setNaturalBeauty]);


  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  async function uploadImage(file) {
    const formData = new FormData()

    formData.append('image', file)
    formData.append('folder', 'natural_beauties')

    try {
      const { data } = await imageUpload.uploadWithFolder(formData)
      setNaturalBeauty({ ...naturalBeauty, image_id: data.id })
    } catch (error) {
      toast.error('Não foi possível carregar imagem!', {
        position: 'top-center'
      })
    }
  }

  function createPayload(content) {
    
    let data = { ...content, ...naturalBeauty}

    if (data.days) {
      const extractedValues = {};

      Object.keys(data.days).forEach((day) => {
        extractedValues[day] = data.days[day].fields;
      });

      data = {...data, days: extractedValues}
    }

    if(!data.classification_id) {
      data = {...data, classification_id: 1}
    }

    if (data?.categories?.length) {
      data = { ...data, categories_id: [data.categories.map(el => el.id)] }
    }

    if (!data?.days) {
      data = {...data, days: emptyDays}
    }

    if (!data?.link_ticket) {
      delete data.link_ticket
    }

    return data
  }


  const createNaturalBeauty = async(content) => {
    const payload = createPayload(content);
    setIsLoading(true)
    try {
      setTagsError(false);
      const { data } = await naturalBeautyApi.create(payload);

      setNaturalBeauty({
          ...naturalBeauty, 
           data: [...naturalBeauty.data, data]
       })

      toast.success('Beleza Natural criada com sucesso!', {
          position: 'top-center'
      })
    } catch (error) {
      toast.error('Ocorreu um erro inesperado!', {
          position: 'top-center'
      })
    } finally {
      setIsLoading(false)
      setModalVisible(false)
    }
  }

  return (
    <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)}>
        <ModalWrapper>
          <StyledForm onSubmit={handleSubmit(createNaturalBeauty)}>

            <Text name='subtitle'>Criar Beleza Natural</Text>

            <BasicInfo register={register}/>

            <ImageInfo naturalBeauty onToggleImage={(e) => uploadImage(e)}/>

            <CategoriesInfos error={tagsError}/>

            <AddressInfo trigger={trigger} />

            <ExtraInfo register={register} errors={errors} />


            {!isLoading ? (
              <span onClick={() => setTrigger(true)}>
                <Button
                  marginTop={sizes.intermediary}
                  paddingBottom={sizes.grand}
                  type="submit"
                >
                  Criar beleza natural
                </Button>
              </span>
            ) : (
              <Loader />
            )
            }

          </StyledForm>
        </ModalWrapper>
    </Modal>
  )
}

const ModalWrapper = styled.div`
    padding: ${sizes.large} ${sizes.none} ${sizes.large} ${sizes.none};
`;