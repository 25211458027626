import React from 'react'
import StatisticsResult from './StatisticsResult'

export default function StatisticsContent() {
  return (
        <>
          <StatisticsResult />
        </>
  )
}
