import React, { createContext, useState, useContext } from 'react';

const ActionFlagContext = createContext();

export default function ActionFlagProvider({ children }) {
    const [actionFlag, setActionFlag] = useState('');
    return (
        <ActionFlagContext.Provider value={{ actionFlag, setActionFlag}}>
            {children}
        </ActionFlagContext.Provider>
    );
}

export function useActionFlag() {
    const context = useContext(ActionFlagContext);

    if (!context) throw new Error('useActionFlag must be used within a actionForFeatureFlag');

    const { actionFlag, setActionFlag } = context;
    return { actionFlag, setActionFlag };
}