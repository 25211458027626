import api from "api";

const tagsNaturalBeauty = {
   get: () => {
        return api().get('/natural-beauty-categories')
   },
   list: () => {
         return api().get('/natural-beauty-categories/list')
    },
   create: (payload) => {
        return api().post(`/natural-beauty-categories`, payload)
   },
   delete: (id) => {
        return api().delete(`/natural-beauty-categories/${id}`)
    },
   update: (id, payload) => {
        return api().put(`/natural-beauty-categories/${id}`, payload)
    }
}
export default tagsNaturalBeauty;