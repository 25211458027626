/* eslint-disable array-callback-return */
import userApi from 'api/requests/user'
import Button from 'components/_UI/Button'
import ButtonAdd from 'components/_UI/ButtonAdd'
import Fieldset from 'components/_UI/Fieldset'
import Loader from 'components/_UI/Loader'
import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import { useUsersFilter } from 'context/UsersFilter'
import date from 'helpers/date'
import fieldValidations from 'helpers/fieldValidations'
import masks from 'helpers/masks'
import unformat from 'helpers/unformat'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { StyledForm } from 'styled/UI/StyledForm'
import { HorizontalCenter } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import sizes from 'theme/sizes'



export default function UserToCreate() {
    const { usersFilter, setUsersFilter } = useUsersFilter()


    const [modalAddOpen, setModalAddOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const isCreateUser = async({userName, firstName, lastName, email, phone, cpf, birthdate, password, confirmPassword}) => {
        setIsLoading(true)
        const payload = {
            username: userName,
            first_name: firstName,
            last_name: lastName,
            email,
            phone: unformat.getNumbers(phone),
            document: unformat.getNumbers(cpf),
            birthdate: date.unformat(birthdate),
            password,
            c_confirm: confirmPassword
        }
        try {
            const { data } = await userApi.create(payload)
            setUsersFilter({...usersFilter, loading: false, data: [
                ...usersFilter.data, data
            ]})
            toast.success('Usuário criado com sucesso!', {
                position: 'top-center'
            })

        } catch ({response}) {
            if (response.status === 422) {
                Object.values(response.data.errors).map((error) => {
                    toast.error(`${error}`, {
                        position: 'top-center'
                    });
                })
                return
            } else {
                toast.error('Erro não foi possível criar usuário!', {
                    position: 'top-center'
                })
            }
        } finally {
            setIsLoading(false)
            setModalAddOpen(false)
        }
    }

  return (
   <>   
        <ButtonContainer>
            <ButtonAdd onClick={() => setModalAddOpen(true)}>
                Adicionar usuário
            </ButtonAdd>
        </ButtonContainer>

        <Modal isOpen={modalAddOpen} onClose={() => setModalAddOpen()}>
            <ModalWrapper>
                <StyledForm onSubmit={handleSubmit(isCreateUser)}>

                    <Text name='subtitle'>
                        Adicionar usuário
                    </Text>

                    <Fieldset 
                        name='userName'
                        register={register}
                        layout='rounded'
                        label='Nome de Usuário'
                        validations={fieldValidations.name('nome de usuário')}
                    />
                    {errors.userName?.message && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.userName?.message} </Text>
                        </HorizontalCenter>
                    )}

                    <Fieldset 
                        name='firstName'
                        register={register}
                        layout='rounded'
                        label='Primeiro Nome'
                        validations={fieldValidations.name('primeiro nome')}
                    />
                    {errors.firstName?.message && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.firstName?.message} </Text>
                        </HorizontalCenter>
                    )}

                    <Fieldset 
                        name='lastName'
                        register={register}
                        layout='rounded'
                        label='Último Nome'
                        validations={fieldValidations.name('último nome')}
                    />
                    {errors.lastName?.message && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.lastName?.message} </Text>
                        </HorizontalCenter>
                    )}

                    <Fieldset 
                        name='email'
                        register={register}
                        layout='rounded'
                        label='E-mail'
                        validations={fieldValidations.email()}
                    /> 
                    {errors.email?.message && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.email?.message}</Text>
                        </HorizontalCenter>
                    )}
                    <Fieldset 
                        name='phone'
                        register={register}
                        label='Telefone'
                        layout='rounded'
                        mask={masks.phone}
                        validations={fieldValidations.phone()}
                    />
                    {errors.phone?.message && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.phone?.message} </Text>
                        </HorizontalCenter>
                    )}

                    <Fieldset 
                        name='birthdate'
                        register={register}
                        label='Data de Nascimento'
                        layout='rounded'
                        mask={masks.date}
                        validations={fieldValidations.date('data de nascimento')}
                    />
                    {errors.birthdate?.message && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.birthdate?.message} </Text>
                        </HorizontalCenter>
                    )}

                    <Fieldset 
                        name='cpf'
                        register={register}
                        label='CPF'
                        layout='rounded'
                        mask={masks.cpf}
                        validations={fieldValidations.cpf()}
                    />
                    {errors.cpf?.message  && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.cpf?.message} </Text>
                        </HorizontalCenter>
                    )}

                    <Fieldset 
                        name='password'
                        register={register}
                        label='Senha'
                        layout='rounded'
                        validations={fieldValidations.password()}
                    />
                    {errors.password?.message && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.password?.message} </Text>
                        </HorizontalCenter>
                    )}

                    <Fieldset 
                        name='confirmPassword'
                        register={register}
                        label='Confirme a senha'
                        layout='rounded'
                        validations={fieldValidations.password()}
                    />
                    {errors.confirmPassword?.message && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.confirmPassword?.message} </Text>
                        </HorizontalCenter>
                    )}
                    {
                        !isLoading ? (

                            <Button marginTop={sizes.little} type='submit'>
                                Adicionar
                            </Button>
                        ) : (
                            <Loader />
                        )
                    }
                </StyledForm>
            </ModalWrapper>
        </Modal>
   </>
  )
}
const ButtonContainer = styled(Row)`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
const ModalWrapper = styled(Column)`
    height: auto;
    padding: ${sizes.none} ${sizes.tiny} ${sizes.tiny} ${sizes.tiny};
`