import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import PermissionsResult from './PermissionsResult';

export default function PermissionsContent() {
  return (
    <Container>
      <PermissionsResult />
    </Container>
  )
}
const Container = styled(Column)`
  width: 100%;
`;