import React from 'react';
import PropTypes from 'prop-types';
import { StyledLoaderBox, StyledSpinner } from 'styled/UI/Loader';

export default function Loader({size}) {
  return (
    <StyledLoaderBox>
        <StyledSpinner />
    </StyledLoaderBox>
  )
};

Loader.defaultProps = {
    size: 'default'
};

Loader.propTypes = {
    size: PropTypes.oneOf(['small', 'default', 'medium', 'large'])
};