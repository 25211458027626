import events from 'api/requests/events';
import Button from 'components/_UI/Button';
import Divider from 'components/_UI/Divider';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import { useEventsList } from 'context/EventsList';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import date from 'helpers/date';
import { FullyCentered } from 'styled/alignment/Center';
import breakpoints from 'theme/breakpoints';
import eventTypes from 'theme/eventTypes';
import CreateEventForm from '../CreateEventForm';
import fonts from 'theme/fonts';
import types from 'helpers/system/types';
import CronogramModal from './CronogramModal';
import LinkPage from 'components/_UI/LinkPage';
import Icon from 'components/_UI/Icon';

export default function EventDetail({ data, closeModal }) {
    const [isLoading, setIsLoading] = useState(false);
    const [setDisabledSubmit] = useState(true);
    const [readyToEdit, setReadyToEdit] = useState(false);
    const [fullCronogramModal, setFullCronogramModal] = useState(false)
    
    const  {eventsList, setEventsList} = useEventsList()
    const [toogleAction, setToogleAction] = useState(false)

    const {
        watch,
    } = useForm({
        defaultValues: {
          name: data.name,
          description: data.description,
          date: date.format(data.dates),
        }
    });

    useEffect(() => {
        watch((values) => {
            setDisabledSubmit(false)
        });
        return () => {};
    }, [watch])

    const actions = {
        delete: 'DELETE',
        edit: 'EDIT',
        block: 'BLOCK'
    }


        
    const onConfirmAction = () => {
        if (toogleAction === actions.delete) {
            deleteItem()
            return
        }

        if (toogleAction === actions.edit) {
            editItem()
            return
        }

        if (toogleAction === actions.block) {
            blockItem()
            return
        }
    }

    const deleteItem = async () => {
        setIsLoading(true)
        const payload = data.slug
        try {
            await events.delete(payload)
            setEventsList({
                ...eventsList,
                data: eventsList.data.filter(event => event.slug !== payload)
            });
            toast.success('Evento deletado com sucesso!', {
                position: "top-center",
                autoClose: 5000,
            })

        } catch (error) {
            
           toast.error('Erro não foi possível excluir o evento!', {
            position: 'top-center',
            autoClose: 5000,
           })
        } finally {
            setIsLoading(false)
            setToogleAction(false)
            closeModal()
        }
    }
    
    
    const editItem = () => {
        setReadyToEdit(true);
    }

    const blockItem = () => {
        try {
            console.log('block')
        } catch (error) {
            console.log(error)
        } finally {
            setToogleAction(false)
        }
    }

    return (
        <Container>   
            <EventDetailHeadContent>
                <StyledBackgroundContainer type={data?.classification?.name}>
                    {
                       data?.images &&  data?.images[0] ? (
                            <StyledBackground src={data?.images[0]} />
                        ) : (
                            <FullyCentered> 
                                <Text name='hero' color={colors.neutral}> {data?.name.charAt(0).toUpperCase()} </Text>
                            </FullyCentered>
                        )
                    }
                </StyledBackgroundContainer>


                <CardMainContent>
                    <Text color={colors.nightDark} name='title'> {data?.name} </Text>

                    {
                        data?.discount_type && data?.discount && (
                            <Row>
                                <Discount marginBottom='10px'>
                                    <Text
                                        color={colors.neutral}
                                        type='bold'
                                        name='small'
                                    >
                                        {
                                            data.discount_type === 'value'
                                                ? `R$ ${data.discount},00 de desconto`
                                                : `${data.discount}% de desconto`
                                        }
                                    </Text>
                                </Discount>
                            </Row>
                        )
                    }

                    <CategoriesInfos>
                        <EventType type={data.classification.name}>
                            {types.getBySlug(data?.classification?.name).label}
                        </EventType>

                        {
                            data?.adulthood && <Text color={colors.dangerDark} type='bold' marginLeft='10px'> +18 </Text>
                        }

                        <Text color={colors.nightDark} ellipsisAt='95%' marginLeft='10px'>
                            {data?.tags?.map(el => `#${el.name} `)}
                        </Text>
                    </CategoriesInfos>

                    <br></br>

                    <ContentInfoRow address>
                        <ContentIconContainer>
                            <Icon color={colors.night} name='marker-in-map' />
                        </ContentIconContainer>
                        <LinkPage isWrapper to={data.link_map}>
                            <Text color={colors.primaryDark} decoration='underline'>
                                {data?.address?.street?.name}, Bairro {data?.address?.street?.district}
                                {data?.address?.number && `, nº ${data?.address?.number}`}
                                {data?.address?.complement && ` - ${data?.address?.complement}`}
                            </Text>
                        </LinkPage>
                    </ContentInfoRow>

                    <ContentInfoRow>
                        <ContentIconContainer>
                            <Icon color={colors.night} name='calendar' />
                        </ContentIconContainer>

                        <DateContainer>
                            {
                                data?.dates?.length > 1 ? (
                                    <>
                                        <Text color={colors.nightDark}> 
                                            <b>Dia 1</b> - {date.getDay(data?.dates[0]?.date)} 
                                            de {date.getMounthName(data?.dates[0]?.date)} - {date.getTimeWithoutSeconds(data?.dates[0]?.start)} 
                                            {data?.dates[0]?.end ? `até ${date.getTimeWithoutSeconds(data?.dates[0]?.end)}` : ''}
                                        </Text>
                                        <Text color={colors.nightDark}> 
                                            <b>Dia 2</b> - {date.getDay(data?.dates[1]?.date)} 
                                            de {date.getMounthName(data?.dates[1]?.date)} - {date.getTimeWithoutSeconds(data?.dates[1]?.start)} 
                                            {data?.dates[1]?.end ? `até ${date.getTimeWithoutSeconds(data?.dates[1]?.end)}` : ''}
                                        </Text>

                                        {data?.dates?.length >= 3 && (
                                            <CronogramContainer>
                                                <Button
                                                    category='outline'
                                                    size='medium'
                                                    onClick={() => setFullCronogramModal(true)}
                                                >
                                                    Ver cronograma completo
                                                </Button>
                                                <CronogramModal
                                                    data={data.dates}
                                                    visible={fullCronogramModal}
                                                    setVisible={setFullCronogramModal}
                                                />
                                            </CronogramContainer>
                                        )}
                                    </>
                                ) : (
                                    <OnlyOnedate>
                                        <Text color={colors.nightDark}>
                                            {date.getDay(data?.dates[0]?.date)} 
                                            de {date.getMounthName(data?.dates[0]?.date)} 
                                            - {date.getTimeWithoutSeconds(data?.dates[0]?.start)} 
                                            {data?.dates[0]?.end ? `até ${date.getTimeWithoutSeconds(data?.dates[0]?.end)}` : ''}
                                        </Text>
                                    </OnlyOnedate>
                                )
                            }
                        </DateContainer>
                    </ContentInfoRow>


                    {
                        data?.link_ticket && (
                            <ButtonContainer>
                                <LinkPage isWrapper to={data?.link_ticket} target={'_blank'}>
                                    <Button>Garantir ingresso</Button>
                                </LinkPage>
                            </ButtonContainer>
                        )
                    }


                    {/* {
                        loading 
                            ? <Loader /> 
                            :  <Interest slug={data.slug} setLoading={setLoading} />
                    } */}

                    {/* { acceptMe && (

                        <FullyCentered marginTop='16px'>
                            <Text>Você e mais <b>{data?.presence?.accept}</b> pessoas confirmaram presença</Text>
                        </FullyCentered>
                    )
                  }  */}


                    {/* 
                    { 
                      !acceptMe   && (

                          data?.presence?.accept > 1 && (
                              <FullyCentered marginTop='16px'>
                                  <Text><b>{data?.presence?.accept}</b> pessoas confirmaram presença</Text>
                              </FullyCentered>
                          )
                      )
                        
                    }
                    
                    { !acceptMe && (

                            data?.presence?.accept === 1 && (
                                <FullyCentered marginTop='16px'>
                                    <Text><b>1</b> pessoa confirmou presença</Text>
                                </FullyCentered>
                            )
                        )
                    } */}

                </CardMainContent >
            </EventDetailHeadContent >

            <Description>
                <Text marginTop='30px' name='subtitle'>Descrição:</Text>
                <Text color={colors.nightDark} marginTop='2px' marginBottom='16px'> {data?.description} </Text>
            </Description>

            <Divider />

            {
                toogleAction ?
                (
                    <Column>
                        <Text type='bold' marginTop={sizes.normal}>Tem certeza dessa ação?</Text>
                        <Row>
                            <Button 
                                size='small' 
                                category='rounded' 
                                marginRight={sizes.mini} 
                                onClick={() => onConfirmAction()}>
                                Confirmar
                            </Button>
                            <Button size='small' category='rounded' onClick={() => setToogleAction(false)}>
                                Cancelar
                            </Button>
                        </Row>
                    </Column>,
                        
                    isLoading ? (
                        <Loader/>
                    ) : (
                        <Column>
                            <Text type='bold' marginTop={sizes.normal}>Tem certeza dessa ação?</Text>
                            <Row>
                                <Button 
                                    size='small' 
                                    category='rounded' 
                                    marginRight={sizes.mini} 
                                    onClick={() => onConfirmAction()}>
                                    Confirmar
                                </Button>
                                <Button size='small' category='rounded' onClick={() => setToogleAction(false)}>
                                    Cancelar
                                </Button>
                            </Row>
                        </Column>
                    )
                        
                ) : (
                    <Row>
                        <Button
                            size='small'
                            category='rounded'
                            onClick={() => setToogleAction(actions.delete)}
                        >
                            Excluir
                        </Button>
                        <Button
                            size='small'
                            marginLeft={sizes.mini}
                            marginRight={sizes.mini}
                            onClick={() => setToogleAction(actions.edit)}
                        >
                            Editar
                        </Button>
                        <Button
                            size='small'
                            onClick={() => setToogleAction(actions.block)}
                        >
                            Bloquear
                        </Button>
                    </Row>
                ) 
            }
        

            { readyToEdit && (
                    <CreateEventForm edit={data}/> 
                )
            }    
                  
        </Container>
    )
}

const Container = styled(Column)`
   width: 100%;
`;

const EventType = styled.div`
    color: ${colors.neutralLight};
    border-radius: 30px;
    padding: 4px 10px;
    font-size: ${fonts.sizes.small};
    ${({ type }) => type === eventTypes.free.slug && `
        background: ${eventTypes.free.color};
    `}
    ${({ type }) => type === eventTypes.paid.slug && `
        background: ${eventTypes.paid.color};
    `}
`;

const StyledBackgroundContainer = styled(FullyCentered)`
    width: 100%;
    height: auto;  
    position: relative;
    background: ${colors.neutralDark};

    ${({ type }) => type === eventTypes.free.slug && ` 
        & > div {
            background: ${eventTypes.free.color};
            height: 200px;
            border-radius: 0 0 18px 18px;
        }
        
    `}

    ${({ type }) => type === eventTypes.paid.slug && `
        & > div {
            background: ${eventTypes.paid.color};
            height: 200px;
            border-radius: 0 0 18px 18px;
        }    
    `}
    
    ${({ isImage }) => isImage && `
        background: ${colors.neutralLight};
        width: 100%;
    `}
`;

const StyledBackground = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 18px;
`;

const EventDetailHeadContent = styled(Column)`
    width: 100%;
`;

const ButtonContainer = styled(FullyCentered)`
    width: 300px;
    margin-top: 10px;
    flex-direction: column;
    padding: ${sizes.none} ${sizes.medium};
    @media screen and (min-width: ${breakpoints.sm}) {
        flex-direction: row;
        & > a:nth-child(2) {
            margin-left: 4% !important;
        }
    }
    
    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: column;
        & > a:nth-child(2) {
            margin-left: 0% !important;
        }
    }
`;

const CategoriesInfos = styled(Row)`
    width: 100%;
    align-items: center;

    & > p {
        padding-bottom: 0;
    }
`;

const CardMainContent = styled(Column)`
    padding: 16px 6%;
    justify-content: center;
    align-items: center;   
    width: 100%; 

    & > h1 {
        margin-top: 6px;
        margin-bottom: 4px;
    }
`;

const Description = styled(Column)`
    padding: 0 26px;
    align-items: center;
    text-align: justify;
    
`;

const ContentIconContainer = styled.div`
    width: 33px;
    height: 33px;
    margin-right: 14px;
    margin-bottom: 5px;
`;

const ContentInfoRow = styled(Row)`
    margin-bottom: 18px;
    align-items: flex-start;
    text-align: left;

    ${({ address }) => address && `
        align-items: center;
    `}

    &:nth-child(3){
        margin-bottom: 0;
    }

    & > p {
        padding: 0;
        margin: 0;
    }
`;

const CronogramContainer = styled.div`
    width: 100%;
`;

const DateContainer = styled.div`
    height: auto;
`;

const OnlyOnedate = styled.div`
    height: 100%;
    margin-top: 8px;
`;

const Discount = styled(Column)`
    padding: 4px 10px;
    background: ${colors.successDark};
    width: auto;
    border-radius: 30px;
    & > p {
        padding: 0;
    }
`;