import Text from 'components/_UI/Text'
import React, { useState } from 'react'
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import phoneFormatter from 'helpers/phone'
import date from 'helpers/date';
import Button from 'components/_UI/Button';
import Divider from 'components/_UI/Divider';
import userApi from 'api/requests/user';
import paths from 'routes/paths';
import Loader from 'components/_UI/Loader';




export default function PartnerCardDetails({data}) {

    const [loading, setLoading] = useState(false)

    const formatDate = (dateContent) => date.format(dateContent);

     async function partnerLogin(email)  {

        setLoading(true)

        const payload = {
            email
        }
        
            
        try {
            const { data } = await userApi.partner(payload);

            const token = data?.accessToken; 
            const domain = process.env.REACT_APP_DOMAIN; 
            
        
            const expiresDate = new Date();
            expiresDate.setMinutes(expiresDate.getMinutes() + 15);

            document.cookie = `token=${token}; Domain=${domain}; Path=/; Secure; SameSite=Strict; Expires=${expiresDate.toUTCString()}`;
    
            window.location.href = `${process.env.REACT_APP_DOMAIN_REDIRECT}/entrar?loginWithPartner=1`;

            
        } catch (error) {
            
        } finally {
            setLoading(false)
        }
        
    }
  return (
    <>
        <Text name="highlight">Detalhes do Usuário </Text>

        <ContainerImage>
            <ImageDetail>
            {
                data.avatar
                ? <ImageContent src={data.avatar} />
                : (
                    <ImageContentWord>
                        <Text name='hero' color={colors.neutral}> {data.first_name.charAt(0).toUpperCase()} </Text>
                    </ImageContentWord>
                )
            }

            </ImageDetail>
        </ContainerImage>

        <ContentItems>
            <CardDetailsContent>
                <Text type='bold'> @{data.username} </Text>
            </CardDetailsContent>

            <CardDetailsContent>
                <Text type="bold" marginRight={sizes.micro} > Nome Completo: </Text>
                <Text> {data.first_name} {data.last_name} </Text>
            </CardDetailsContent>

            <CardDetailsContent>
                <Text type="bold" marginRight={sizes.nano} > Tipo de permissão: </Text>
                <Text> {data?.role?.name} </Text>
            </CardDetailsContent>

            <CardDetailsContent>
                <Text type="bold" marginRight={sizes.nano} > Gênero: </Text>
                <Text> {data.gender ? data.gender : 'Não Informado'} </Text>
            </CardDetailsContent>

            <CardDetailsContent>
                <Text type="bold" marginRight={sizes.nano} > Email: </Text>
                <Text> {data.email} </Text>
            </CardDetailsContent>

            <CardDetailsContent>
                <Text type="bold" > Data de nascimento: </Text>
                <Text marginLeft={sizes.nano}> {data.birthdate ? formatDate(data.birthdate) : 'Não Informada'} </Text>
            </CardDetailsContent>

            <CardDetailsContent>
                <Text type="bold" > Telefone: </Text>
                <Text marginLeft={sizes.nano}> {data.phone ? phoneFormatter(data.phone): 'Não Informado'} </Text>
            </CardDetailsContent>

            <CardDetailsContent>
                <Text type="bold"> Data de registro:</Text>
                <Text marginLeft={sizes.nano}>  {data.confirmed_email_at ? `${formatDate(data.confirmed_email_at)}` : 'Não verificado'} </Text>
            </CardDetailsContent>

            <Divider /> 
            
            {
                loading ? (
                    <Loader />
                ) : (

                    <ContentButton>
                        <Button marginTop={'20px'} onClick={() => partnerLogin(data?.email)}>
                            Entrar como parceiro
                        </Button>
                    </ContentButton>
                )
            }
        </ContentItems>
    </>
  )
}



const CardDetailsContent = styled(Row)`
    width: auto;
    align-items: center;
    margin-top: 6px;

    &:first-child {
      margin-top: 20px;
      margin-bottom: 10px;
    }
`;
const ContainerImage = styled(FullyCentered)`
    width: 100%;
    margin-top: 20px;
`;
const ImageDetail = styled(Column)`
    width: 150px;
    height: 150px;
`;
const ImageContent = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
`;

const ImageContentWord = styled(FullyCentered)`
  width: 150px;
  height: 150px;
  background: ${colors.night};
  border-radius: 50%;

  & > h1 {
    margin: 0;
    padding: 0;
  }
`;

const ContentItems = styled(Column)`
  width: 100%;
  align-items: center;
  justify-content: center;
`;


const ContentButton = styled.div`
    width: 190px;
`
