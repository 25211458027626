import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import React from 'react'
import styled from 'styled-components'

export default function DetailsRoles({visibleDetails, setVisibleDetails, data}) {
  return (
    <Modal isOpen={visibleDetails} onClose={() => setVisibleDetails(false)}>
        <Text name='subtitle'>{data.name}</Text>
        {
            data.scopes.length ? (
                <ContentScopes>
                    {
                        data.scopes.map((el) => (
                            <ContainerScopes>
                                    <Text marginTop={'20px'}>{el.name}</Text>
                            </ContainerScopes>
                        ))
                    }
                </ContentScopes>
            ) : (
                <Text name='small' marginTop='10px'>Nenhum escopo atribuído!</Text>
            )
        }
    </Modal>
  )
}

const ContainerScopes = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`;

const ContentScopes = styled.div`
    margin-top: 15px;

`;