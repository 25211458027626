import axios from "axios";
import auth from "middlewares/auth";

const headers = {
    Authorization: `Bearer ${auth.getToken()}`,
    'Content-Type': 'multipart/form-data'
}

const imageUpload = {
    uploadWithFolder: (payload) => {
        return axios.post(`${process.env.REACT_APP_DEFAULT_URL}/images`, payload, {headers})
    },
    
};
 
export default imageUpload;  