import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import Roles from './Roles';
import Scopes from './Scopes';
import SearchNotFound from 'components/_UI/SearchNotFound';
import Text from 'components/_UI/Text';
import sizes from 'theme/sizes';

export default function Content({roles, scopes}) { 

  
  
  return (
    <>
      <Container>
          <Text name='title'>Roles</Text>
          {
            roles.length 
              ?  
                <Roles roles={roles} /> 
              : 
                <SearchNotFound />
          }
                
          <Text marginTop={sizes.medium} name='title'>Escopos</Text>
          
          {
            scopes.length 
              ? 
                <Scopes scopes={scopes} />
              :
                 <SearchNotFound />
          }
      </Container>
    </>
  )
}

const Container = styled(Column)`
  width: 100%;
`;
