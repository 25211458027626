import React, { createContext, useState, useContext } from 'react';

const EventsFilterContext = createContext();

export default function EventsFilterProvider({ children }) {
    const [eventsFilter, setEventsFilter] = useState(null);

    return (
        <EventsFilterContext.Provider value={{eventsFilter, setEventsFilter}}>
            {children}
        </EventsFilterContext.Provider>
    );
}

export function useEventsFilter() {
    const context = useContext(EventsFilterContext);
    
    if (!context) throw new Error('useEventsFilter must be used within a EventsFilter');
    
    const { eventsFilter, setEventsFilter } = context;
    return { eventsFilter, setEventsFilter };
}