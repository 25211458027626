import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { Row } from 'styled/alignment/Row'
import rolesApi from 'api/requests/roles'
import scopesApi from 'api/requests/scopes'
import Content from './card/Content'
import Loader from 'components/_UI/Loader'
import { useRoles } from 'context/Roles'
import { useScopes } from 'context/Scopes'
import { toast } from 'react-toastify'

export default function PermissionsResult() {
  const {roles, setRoles} = useRoles()
  const {scopes, setScopes} = useScopes()
  const [isLoading, setIsloading] = useState(false)

  useEffect(() => {
    if(!roles) {
      getRoles()
    }
    if(!scopes) {
      getScopes()
    }
  }, [])
  
  const getRoles = async() => {
    setIsloading(true)
    try {
      const {data: res} = await rolesApi.get()
      setRoles(res.data)
    } catch (error) {
      toast.error('Ocorreu um erro ao carregar Roles!', {
        position: 'top-center'
      })
    } finally {
      setIsloading(false)
    }
  }

  const getScopes = async() => {
    try {
      const {data} = await scopesApi.get()
      setScopes(data)
    } catch (error) {
      toast.error('Ocorreu um erro ao carregar Escopos!', {
        position: 'top-center'
      })
    }
  }
  return (
    <Container>
      {!isLoading ? (
        <Content scopes={scopes} roles={roles} />
      ) : (
        <Loader />
      )
    }
    </Container>
  )
}

const Container = styled(Row)`
  width: 100%;
`;