import React, {useEffect, useState} from 'react'
import { useFeatureFlag } from 'context/FeatureFlag';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import TableFeatureFlag from './table/TableFeatureFlag';
import { Row } from 'styled/alignment/Row';
import sizes from 'theme/sizes';
import Button from 'components/_UI/Button';
import Loader from 'components/_UI/Loader';
import Modal from 'components/_UI/Modal';
import Text from 'components/_UI/Text';
import featureFlagApi from 'api/requests/featureFlag';
import { toast } from 'react-toastify';
import ButtonAdd from 'components/_UI/ButtonAdd';
import CreateFeatureFlagForm from './form/CreateFeatureFlagForm';
import { useActionFlag } from 'context/ActionFlag';
import SearchNotFound from 'components/_UI/SearchNotFound';

export default function FeatureFlag() {

    const { featureFlag, setFeatureFlag } = useFeatureFlag()
    const {actionFlag, setActionFlag} = useActionFlag()
    const [modalOpen, setModalOpen] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState()

    useEffect(() => {
        getFeatureFlag()
    }, []);


    const getFeatureFlag = async () => {
        setIsLoading(true)
        try {
            const {data} = await featureFlagApi.get()
            const tableData = data?.data?.map(el => {
                return {
                    id: el.id,
                    name: el.name,
                    enabled: el.enabled ? 'Ativo' : 'Desativado',
                    action: ['active', 'disabled', 'delete']
                }
            })
            setFeatureFlag(tableData)
            setActionFlag(Object.values(tableData).map(el => el.action))

            
        } catch (error) {
            toast.error('Não foi possível carregar as feature flags!', {
                position: 'top-center'
            })
        }  finally {
            setIsLoading(false)
        }
    }
    
    const toggleActionClicked = (item) => {
        setItem(item)  
        setModalOpen(true);
    }

    const onConfirmAction = async () => {
        if (item.actionSelected === 'delete') {
            await deleteFeatureFlag()
            return
        }
        if (item.actionSelected === 'disabled') {
            await disableFeatureFlag()
            return
        }
        if (item.actionSelected === 'active') {
            await activatingFeatureFlag()
            return
        }
    }

    const activatingFeatureFlag = async () => {
        setIsLoading(true)
        const id = item.id

        const payload = {
            name: item.name,
            enabled: true
        }

        try {
            await featureFlagApi.update(`${id}`, payload)
            const updateFeatureFlag = featureFlag.map((feature) => {
                if(feature.id === id) {
                    return {...payload, id: id ,name: payload.name, enabled: payload.enabled ? 'Ativo' : 'Desativado', action: ['active', 'disabled', 'delete']}
                }
                return feature;
            })
            setFeatureFlag(updateFeatureFlag)
            toast.success('Feature ativada com sucesso!!', {
                position: 'top-center'
            })
        } catch (error) {
            toast.error('Erro não foi possível desativar essa feature!!', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
            setModalOpen(false)
        }
    }
    const disableFeatureFlag = async() => {
        setIsLoading(true)
        const id = item.id
        const payload = {
            name: item.name,
            enabled: false
        }
        try {
            await featureFlagApi.update(`${id}`, payload)

            const updateFeatureFlag = featureFlag.map((feature) => {
                if(feature.id === id) {
                    return {...payload, id: id, name: payload.name, enabled: payload.enabled ? 'Ativo' : 'Desativado', action: ['active', 'disabled', 'delete']}
                }
                return feature;
            })
            setFeatureFlag(updateFeatureFlag)
            toast.success('Feature flag desativada com sucesso!', {
                position: 'top-center'
            });
        } catch (error) {
           toast.error('Erro não foi possível desativar a feature flag!', {
            position: 'top-center'
           })
        } finally {
            setIsLoading(false)
            setModalOpen(false)
        }
    }

    const deleteFeatureFlag = async () => {
        setIsLoading(true)
        const id = item.id
        try {
            await featureFlagApi.delete(id)
            setFeatureFlag(featureFlag => featureFlag.filter(feature => feature.id !== id))
            toast.success('Feature deletada com sucesso!', {
                position: 'top-center'
            })
        } catch (error) {
            toast.error('Erro não foi possível deletar está feature!', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
            setModalOpen(false)
        }
    }


    return (
        <Container>
            <ContainerButton>
                <ButtonAdd
                    onClick={() => setModalVisible(true)}
                >
                    Adicionar Feature
                </ButtonAdd>
            </ContainerButton>
            
            { modalVisible && (
                    <CreateFeatureFlagForm 
                        setModalVisible={setModalVisible}
                        modalVisible={modalVisible}
                    />
                )
            }
    
            { 
                !isLoading ? (
                    featureFlag?.length === 0 ? (
                        <SearchNotFound />
                    ) : (
                        featureFlag  && actionFlag && (
                            <TableFeatureFlag 
                                rows={featureFlag}
                                columns={['Nome', 'Status', 'Ações']}
                                actionClicked={(i) => {
                                    toggleActionClicked(i)
                                }}
                            />
                        )
                    )
                ) : (
                    <Loader />

                )
            }

            {       
                modalOpen && (
                    <Modal
                        isOpen={modalOpen}
                        onClose={() => setModalOpen(false)}>
                        
                        <Text
                            name="highlight"> 
                            {
                               item.actionSelected === 'delete' ? (
                                    'Deseja realmente excluir essa feature?'
                               ) : (
                                item.actionSelected === 'disabled' ? (
                                    'Deseja realmente desativar essa feature?'
                                ) : (
                                    'Deseja realmente ativar essa feature?'
                                )
                               )
                            }     
                     
                        </Text>
                        {
                            item.actionSelected && (
                                <Content>
                                    <ButtonContentConfirmed>
                                        {
                                            !isLoading ? (

                                            <>
                                                <Button 
                                                    onClick={() => onConfirmAction()}
                                                >
                                                    Confirmar
                                                </Button>

                                                <Button 
                                                    marginLeft={sizes.tiny} 
                                                    onClick={() => setModalOpen(false)}
                                                >
                                                        Cancelar
                                                </Button>
                                            </>
                                            ) : (
                                                <Loader />
                                            )
                                        }
                                    </ButtonContentConfirmed>
                                </Content>
                            ) 
                        }
                    </Modal>
                )
            }
        </Container>
    )
}

const Container = styled(Column)`
    width: 100%;
`;

const Content = styled(Column)`
    width: 100%;
    display: flex;
    align-items: center;
`;
const ButtonContentConfirmed = styled(Row)`
    display: flex;
    height: ${sizes.gigantic};
    width: 300px;
`;

const ContainerButton = styled(Row)`
    width: 100%;
    display: flex;
    justify-content: flex-end
`;
