import Content from 'components/main/Content'
import TagsTouristSpotsProvider from 'context/TagsTouristSpots'
import TouristSpotsProvider from 'context/TouristSpots'
import TouristSpotsContent from 'modules/tourist-spots/TouristSpotsContent'
import React from 'react'

export default function TouristSpots() {
  return (
      <TouristSpotsProvider>
        <TagsTouristSpotsProvider>
            <Content title='Pontos Turísticos'>
                <TouristSpotsContent />
            </Content>
        </TagsTouristSpotsProvider>
      </TouristSpotsProvider>
     
  )
}
