import paths from './paths';
import Events from "screens/Events";
import Statistics from "screens/Statistics";
import Users from "screens/Users";
import Tags from "screens/Tags";
import FeatureFlag from 'screens/FeatureFlag';
import TouristSpots from 'screens/TouristSpots';
import TagsSpots from 'screens/TagsSpots';
import NaturalBeauty from 'screens/NaturalBeauty';
import TagsNaturalBeauty from 'screens/TagsNaturalBeauty';
import Permissions from 'screens/Permissions';
import Partners from 'screens/Partners';
export default [
    {
        exact: true,
        path: paths.events,
        element: <Events />
    },
    {
        exact: true,
        path: paths.statistics,
        element: <Statistics />
    },
    {
        exact: true,
        path: paths.users,
        element: <Users />
    },
    {
        exact: true,
        path: paths.tags,
        element: <Tags />
    },
    {
        exact: true,
        path: paths.usersPartners,
        element: <Partners />
    },
    {
        exact: true,
        path: paths.featureFlag,
        element: <FeatureFlag />
    },
    {
        exact: true,
        path: paths.touristSpots,
        element: <TouristSpots />
    },
    {
        exact: true,
        path: paths.tagsSpots,
        element: <TagsSpots />
    },
    {
        exact: true,
        path: paths.naturalBeauty,
        element: <NaturalBeauty />
    },
    {
        exact: true,
        path: paths.tagsNaturalBeauty,
        element: <TagsNaturalBeauty />
    },
    {
        exact: true,
        path: paths.permissions,
        element: <Permissions />
    },

]