import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import sizes from 'theme/sizes'
import CardNaturalBeauty from './card/CardNaturalBeauty'
import naturalBeautyApi from 'api/requests/naturalBeauty'
import { useNaturalBeauty } from 'context/NaturalBeauty'
import Loader from 'components/_UI/Loader'
import { toast } from 'react-toastify'
import Modal from 'components/_UI/Modal'
import NaturalBeautyDetail from './detail/NaturalBeautyDetail'

export default function NaturalBeautyResult() {
    const [isLoading, setIsLoading] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const {naturalBeauty, setNaturalBeauty} = useNaturalBeauty()
    const [item, setItem] = useState()

    useEffect(() => {
        getNaturalBeauty()
    }, [])

    const getNaturalBeauty = async() => {
        setIsLoading(true)
        try {
            const {data} = await naturalBeautyApi.get()
            setNaturalBeauty(data)
        } catch (error) {
            toast.error('Ocorreu um erro inesperado!', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
        }
    }
    const renderDetail = async(naturalBeauty) => {
        setIsLoading(true)
        try {
            const {data: res} = await naturalBeautyApi.detail(naturalBeauty.slug)
            setItem(res.data)
            setModalOpen(true)
            
        } catch (error) {
            toast.error('Ocorreu um erro inesperado!', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
        }
    }
    
    return (
        <Container>
            <Content>
                {!isLoading ? (
                     naturalBeauty?.data.map((el, index) => (
                        <CardResultNaturalBeauty key={index} onClick={() => renderDetail(el)}>
                            <CardNaturalBeauty data={el}/>
                        </CardResultNaturalBeauty>
                    ))
                ) : (
                    <Loader />
                )}
               
            </Content>
            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
               {naturalBeauty && <NaturalBeautyDetail data={item} setModalOpen={setModalOpen}/>}
            </Modal>
        </Container>
    ) 
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${sizes.normal};
    @media screen and (min-width: 425px) {
        flex-wrap: wrap;
        display: flex;
    }
`;

const Content = styled.div`
    flex-wrap: wrap;
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
`;

const CardResultNaturalBeauty = styled.div`

`
