import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import React from 'react'
import styled from 'styled-components'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import sizes from 'theme/sizes'

export default function OpeningHours({data, visible, setVisible}) {
 

  return (
   <Modal isOpen={visible} onClose={() => setVisible(false)}>
        <Container>
            <Text name='subtitle'>Horário de Funcionamento</Text>
            { data && (
                Object.entries(data).map(([key, el]) => (
                    <Day>
                        <Text name='subtitle' marginRight={'12px'}>{key}</Text>
                            {el.map((el) => (
                                <>
                                    <Text marginRight={sizes.tiny}>{el.start}</Text> 
                                    <Text marginRight={sizes.tiny} marginLeft={sizes.tiny}>-</Text>
                                    <Text marginRight={sizes.tiny}>{el.end}</Text> 
                                    <Text marginRight={sizes.tiny}>|</Text>
                                </>
                            )
                        )}
                    </Day>
                )))    
            } 
        </Container>
   </Modal>
  )
}
const Container = styled(Row)`
    flex-wrap: wrap;
    align-items: space-around;
    justify-content: center;
`;
const Day = styled(Row)`
    border: 2px solid ${colors.nightLight};
    padding: 10px 0;
    border-radius: 8px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top : 10px;
    max-width: 500px;
    flex-wrap: wrap;
`;
