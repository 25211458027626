import api from "api";

const featureFlag = {  
    get: () => {
        return api().get(`/feature-toggle`);
    },
    create: (payload) => {
        return api().post(`/feature-toggle`, payload);
    },
    delete: (id) => {
        return api().delete(`/feature-toggle/${id}`);
    },
    update: (id, payload) => {
        return api().put(`/feature-toggle/${id}`, payload);
    },
    
};
 
export default featureFlag;  