
import Fieldset from 'components/_UI/Fieldset'
import Text from 'components/_UI/Text'
import React, { useEffect, useState } from 'react'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import styled from 'styled-components'
import sizes from 'theme/sizes'
import ButtonSelect from 'components/_UI/ButtonSelect'
import { FullyCentered } from 'styled/alignment/Center'
import Button from 'components/_UI/Button'
import date from 'helpers/date';
import masks from 'helpers/masks';
import tagsApi from 'api/requests/tags'
import Loader from 'components/_UI/Loader'
import eventsApi from 'api/requests/events'
import coreTypes from 'theme/eventTypes'
import { useEventsTypes } from 'context/EventsTypes'
import { useTags} from 'context/Tags'
import { useSelectedTag } from 'context/SelectedTag'
import { useForm } from 'react-hook-form'
import { StyledForm } from 'styled/UI/StyledForm'
import { toast } from 'react-toastify'
import { useEventsList } from 'context/EventsList'



let types = []
let categories = []
export default function EventsFilters() {
  
  const{ eventsTypes, setEventsTypes} = useEventsTypes()
  const{ tags, setTags} = useTags()

  const { eventsList, setEventsList } = useEventsList()
  const { selectedTag, setSelectedTag } = useSelectedTag()
  const [name, setName] = useState('')
  const [inputDate, setInputDate] = useState('')

  const isDisabled  = eventsTypes === null && inputDate === '' && name === '' && selectedTag == null;
  
  useEffect(() => {
    getCategories()
  }, [eventsList])
  
  
  const {
    handleSubmit,
    formState: { errors }
    } = useForm();

  const removeObjectWithId = (arr, id) => {
    const objWithIndex = arr.findIndex(obj => obj.id === id)

    if (objWithIndex > -1) {
      arr.splice(objWithIndex, 1)
    }

    return arr
  }
  function createTagsData() {
    let formatedTags = ''

    selectedTag?.categories.map((el, index) => {
        if (index === 0) {
            formatedTags = el.name
        }

        if (index > 0) {
            formatedTags = `${formatedTags},${el.name}`
        }
    })

    return formatedTags
}

  const getCategories = async ()  => {
    try {
      const { data } = await tagsApi.get()
      setTags(data)
    } catch (error) {
      toast.error('Não foi possível carregar Categorias!', {
        position: 'top-center'
      })
    } 
  }

  const typeFilter = (active, item) => {
    const typeObject = {
      name: item.slug,
      active: active === 'ACTIVE' || false,
      id: item.id
    }

    if (active === 'INATIVE') {
      const newArray = removeObjectWithId(types, typeObject.id)

      setEventsTypes(newArray)
      return;
    }
    setEventsTypes(types)
    types.push(typeObject)

  }
  
  const categoriesFilter = (active, item) => {
    const categoriesObject = {
      active: active === 'ACTIVE' || false,
      name: item.name,
      id: item.id
    }

    if (active === 'INATIVE') {
      const newArray = removeObjectWithId(categories, categoriesObject.id)
      setSelectedTag(newArray)
      
      return;
    }
    
    categories.push(categoriesObject)
    setSelectedTag({ ...selectedTag, categories })

  }

  const filterItems = async () => {
    const payload = {
      max_date: inputDate ? date.unformat(inputDate) : null,
      tags: selectedTag?.categories && createTagsData(),
      term: name ? name : null,
      classification_id: eventsTypes?.length ? eventsTypes.map(el => el.id) : null
    }
    setEventsList({...eventsList, isFilter: true, loading: true})
    try {
      const { data } = await eventsApi.filter(payload)
      if(data.data.length === 0 ) {
        setEventsList({...data, loading: false, isFilter: true, isNotFound: true, isNotPaginate: true})
      } else {
        setEventsList({...data, loading: false, isFilter: true, isNotFound: false, isNotPaginate: false})
      }
    } catch (error) {
      setEventsList({...eventsList, loading: false, isFilter: true})
    } 

  }


  return (
    <>
      <Column >
        <StyledForm onSubmit={handleSubmit(filterItems)}>

        <ContainerInputsFilters>
            <InputContainer>
              <Fieldset
                placeholder='Filtrar por nome ou localização'
                layout='straight'
                onChangeValue={(e) => setName(e)}
              />
            </InputContainer>
            <InputContainer>
              <Fieldset
                placeholder='Filtrar por data'
                layout='straight'
                onChangeValue={(e) => setInputDate(e)}
                mask={masks.date}
              />
            </InputContainer>
        </ContainerInputsFilters>

          <Row marginTop={sizes.grand}>
            <ContainerFilters>
              <Text name='subtitle'>Tipo de Evento</Text>
              <Row>
                {
                  Object.values(coreTypes).map((el) => (
                    <ButtonSelect
                      key={el.id}
                      onToggle={(state) => typeFilter(state, el)}
                    >
                      {el.label}
                    </ButtonSelect>
                  ))
                }
              </Row>
              <Text name='subtitle'>Categorias</Text>
              <ContainerTags>
                {
                  tags ?
                   tags.filter(el => el.is_active === 1).map((el, i) => (
                      <ContainerTagItem key={i}>
                        <ButtonSelect onToggle={(state) => categoriesFilter(state, el, i)}>
                          {el.name}
                        </ButtonSelect>
                      </ContainerTagItem>
                    ))
                    : (
                      <Loader/>
                    )
                    
                }
              </ContainerTags>
            </ContainerFilters>
          </Row>
          <FullyCentered>
            <FullyCentered width='200px'>
              <Button 
                size='default'
                type='submit'
                variant={
                    isDisabled 
                      ? 'disabled' 
                      : eventsList
                      ? 'primary'
                      : 'disabled'
                    }
              >
                Filtrar
              </Button>
            </FullyCentered>
          </FullyCentered>
        </StyledForm>
       
      </Column>
    </>
  )
}


const InputContainer = styled.div`
  width: 15rem; 
  margin-right: ${sizes.normal};
  @media screen and (max-width: 490px) {
    margin-top: 25px;
  }
`;
const ContainerInputsFilters = styled.div`
  flex-direction: row;
  display: flex;
  max-width: 100%;
  @media screen and (min-width: 700px) and (max-width: 1024px) {
     max-width: 100%;
  }
  @media screen and (max-width: 490px) {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
  @media screen and (max-width: 680px) {
    margin-top:  20px;
    align-items: center;
  }
`
const ContainerTags = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  @media screen and (max-width: 700px){
    justify-content: center;
    align-items: center;
    display: flex;
  }

  flex-wrap: wrap;
`;

const ContainerTagItem = styled.div`
  width: 110px;
  margin-right: ${sizes.big};
  
`;

const ContainerFilters = styled.div`
  flex-direction: column;
  display: flex;
  @media screen and (min-width: 600px) and (max-width: 1300px) {
      max-width: 450px;
  }
`