import React from 'react'
import styled from 'styled-components'
import colors from 'theme/colors'
import fonts from 'theme/fonts'
import sizes from 'theme/sizes'

export default function TextArea({
    name,
    register,
    validations,
    ...props
}) {
    return (
        <StyledTextArea
            {...props}
            {...register(name, validations)}
        />
    )
}


const StyledTextArea = styled.textarea`
    resize: none;
    border: 2px solid ${colors.neutralDark};
    border-radius: 30px;
    padding: ${sizes.normal} ${sizes.mini};
    font-weight: ${fonts.weight.bold};
    background: transparent;
    color: ${colors.nightDark};
    outline: none;

    &:focus {
        border: ${sizes.quark} solid ${colors.night};
    }


    &::placeholder {
        color: ${colors.nightLight};
    }
`