import Fieldset from 'components/_UI/Fieldset'
import Text from 'components/_UI/Text'
import eventTypes from 'theme/eventTypes'
import { useEvents } from 'context/Events'
import fieldValidations from 'helpers/fieldValidations'
import React, { useEffect } from 'react'
import { HorizontalCenter } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'

export default function ExtraInfo({ register, errors }) {
    const { events, setEvents } = useEvents();

    useEffect(() => {
        setEvents({
            ...events,
            adulthood: false,
            visibility: 'public'
        })
    }, [])

    return (
        <Column marginTop='18px'>
            <Text size='small' type='bold'> Informações extras </Text>
            <Fieldset
                placeholder='Link do ingresso'
                layout='rounded'
                name='link_ticket'
                register={register}
                validations={fieldValidations.link('link do ingresso')}
            />

            {errors && (
                <HorizontalCenter>
                    <Text color={colors.danger}> {errors.link?.message} </Text>
                </HorizontalCenter>
            )}

            <Row marginLeft='4px'>
                <input
                    type='checkbox'
                    value={eventTypes.free.id}
                    onChange={({ target }) => {
                        setEvents({
                            ...events,
                            classification_id: !target.checked ? eventTypes.free.id : eventTypes.paid.id
                        })
                    }}

                />
                <Text
                    size='small'
                    color={colors.night}
                    paddingTop={'10px'}
                    paddingLeft={'8px'}
                >
                    O evento é pago
                </Text>
            </Row>

            <Row marginLeft='4px'>
                <input
                    type='checkbox'
                    onChange={({ target }) => {
                        setEvents({
                            ...events,
                            adulthood: target.checked
                        })
                    }}
                />
                <Text
                    size='small'
                    color={colors.night}
                    paddingTop={'10px'}
                    paddingLeft={'8px'}
                >
                    O evento é para maiores de 18 anos?
                </Text>
            </Row>
        </Column>
    )
}
