import React, { useState } from 'react'
import sizes from 'theme/sizes';
import Button from './Button'

export default function ButtonSelect({ children, isActive = false, onToggle }) {
    const states = {
        active: 'ACTIVE',
        inative: 'INATIVE',
    }

    const [currentState, setCurrentState] = useState(isActive);

    const toggleState = () => {
        if (currentState === states.active) {
            setCurrentState(states.inative);
            onToggle(states.inative)

            return;
        }
        
        setCurrentState(states.active);
        onToggle(states.active)
    };

    return (
        <Button
            variant={currentState === states.active ? 'dark' : 'light'}
            size={'default'}
            onClick={(e) => toggleState(e)}
            marginRight={sizes.medium}
            marginBottom={sizes.medium}
            textTransform='capitalize'
        >
            {children}
        </Button>
    )
};
