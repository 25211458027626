import React, { useState } from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import Button from 'components/_UI/Button';
import Fieldset from 'components/_UI/Fieldset';
import Text from 'components/_UI/Text';
import { FullyCentered, HorizontalCenter } from 'styled/alignment/Center';
import { StyledForm } from 'styled/UI/StyledForm';
import signInTexts from 'texts/general/sign-in';
import Icon from 'components/_UI/Icon';
import breakpoints from 'theme/breakpoints';
import loginApi from 'api/requests/login';
import { useForm } from 'react-hook-form';
import Loader from 'components/_UI/Loader';
import fieldValidations from 'helpers/fieldValidations';
import userApi from 'api/requests/user';
import { toast } from 'react-toastify';
import { useUser } from 'context/User';
import paths from 'routes/paths';

function SignIn() {


    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const [isLoading, setIsLoading] = useState(false);
    const {setUser} = useUser()

    const login = async ({ email, password }) => {
        const payload = { email, password };

        setIsLoading(true);

        try {
            const { data } = await loginApi.signIn(payload);
            localStorage.setItem('token', data.accessToken);
            verifyUser()
        } catch (error) {
            if(error.response.status === 403) {
                toast.error('Erro usuário sem permissão!', {
                    position: 'top-center'
                })
            } else {
                toast.error('E-mail ou senha incorreto!', {
                    position: 'top-center'
                })
            }
        } finally {
            setIsLoading(false);
        }
    };

    
    const verifyUser = async () => {
        setIsLoading(true)
        try {
            const { data } = await userApi.getUserMe()
            if(data.role.name === 'Admin') {
                setUser(data)
                window.location.href = paths.statistics;
            } 
        } catch (error) {
            toast.error('Usuário não tem permissão', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
        }
    }
    return (
        <BoxContainer fullyCentralized>
            <LogoContainer>
                <Icon name={'whizr-logo'} color={colors.primary} />
            </LogoContainer>

            <StyledForm onSubmit={handleSubmit(login)}>
                <Fieldset
                    placeholder={signInTexts.inputs.username}
                    badgeIcon={'avatar'}
                    badgePosition={'left'}
                    name="email"
                    register={register}
                    validations={fieldValidations.email()}
                />
                {errors.email?.message && (
                    <HorizontalCenter>
                        <Text color={colors.danger}> {errors.email?.message} </Text>
                    </HorizontalCenter>
                )}

                <Fieldset
                    placeholder={signInTexts.inputs.password}
                    badgeIcon={'lock'}
                    badgePosition={'left'}
                    marginTop={sizes.big}
                    inputType='password'
                    name="password"
                    register={register}
                    validations={fieldValidations.passwordWithoutPatterns()}
                />
                {
                    errors.password?.message && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.password?.message} </Text>
                        </HorizontalCenter>
                    )
                }
                {!isLoading ? (
                    <Button
                        variant='primary'
                        size='large'
                        marginTop={sizes.normal}
                        type='submit'
                    >
                        {signInTexts.buttons.enter}
                    </Button>
                ) : <Loader />}

            </StyledForm>
        </BoxContainer>
    )
};

const BoxContainer = styled(FullyCentered)`
    flex-direction: column;
    max-width: 420px;
    height: 100%;
    padding: 40px;
    background: ${colors.neutralDark};
`;

const LogoContainer = styled.div`
    width: 110px;
    height: 110px;
    margin-bottom: ${sizes.gigantic};

    // @media screen and (min-width: ${breakpoints.lg}) {
    //     display: none;
    // }
`;

export default SignIn;