import React from 'react';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import Text from 'components/_UI/Text'
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import { Row } from 'styled/alignment/Row';
import { FullyCentered } from 'styled/alignment/Center';
import date from 'helpers/date';


export default function UserCard({ data }) {
    const formatDate = dateContent => date.format(dateContent)

    return (
        <CardContainer>
            <Row>
                {
                    data.avatar
                        ? <CardImage src={data.avatar} />
                        : (
                            <CardImageWord>
                                <Text name='title' color={colors.neutral}> {data.first_name.charAt(0).toUpperCase()} </Text>
                            </CardImageWord>
                        )
                }



                <CardImageContainer>
                    <Row>
                        <Text
                            name='small'
                            type='bold'
                            marginRight={sizes.micro}
                        >
                            Nome:</Text>
                        <Text name='small'>{data.first_name} {data.last_name}</Text>
                    </Row>
                    <CardContent>
                        <Row>
                            <Text
                                name='small'
                                type='bold'
                                marginRight={sizes.micro}
                            >
                                Tipo de permissão:
                            </Text>

                            <Text>
                                {data.role?.name}
                            </Text>

                        </Row>
                        <Row>
                            <Text
                                name='small'
                                type='bold'
                                marginRight={sizes.micro}
                            >
                                Data de registro:
                            </Text>
                            <Text name='small'>
                                {
                                    data.confirmed_email_at
                                        ? `${formatDate(data.confirmed_email_at)}`
                                        : 'Não verificado'
                                }
                            </Text>
                        </Row>
                    </CardContent>
                </CardImageContainer>
            </Row>
            <CardResultInfos>
                <CardContent>
                    <Text
                        name='small'
                        type='bold'
                        marginRight={sizes.micro}
                    >
                        E-mail
                    </Text>
                    <Text name='small'>{data.email}</Text>
                </CardContent>
                <CardContent>
                    <Text
                        name='small'
                        type='bold'
                        marginRight={sizes.micro}
                    >
                        Gênero
                    </Text>
                    <Text name='small'>
                        {data.gender ? data.gender : 'Não Informado'}
                    </Text>
                </CardContent>
            </CardResultInfos>
        </CardContainer>
    )
}
const CardContainer = styled(Column)`
    width: 320px;
    height: 180px;
    margin: ${sizes.small};
    @media screen and (max-width: 820px){
        margin: 0px;
        &:nth-child(1) {
            margin-top: 15px;
        }
    }
    background: ${colors.neutralLight};
    border-radius: ${sizes.tiny};
    box-shadow: ${sizes.micro} ${sizes.mini} ${sizes.tiny} ${colors.occasionalGrey};
    transition: all ease-in-out .25s; 
    border: 1px solid ${colors.neutralDark};
    padding: 14px;
    &:hover {
        transform: scale(1.1);
    }
`;
const CardImageContainer = styled(Column)`
    margin: ${sizes.small} ${sizes.small} ${sizes.none} ${sizes.small};
`;

const CardImage = styled.img`
    width: ${sizes.huge};
    height: ${sizes.tremendous};
    object-fit: cover;
    border-radius: 30px;
    margin: ${sizes.small} ${sizes.none} ${sizes.micro} ${sizes.tiny};
`
const CardContent = styled(Column)`
    width: 100%;
    height: ${sizes.medium};
    align-items: center;

`
const CardResultInfos = styled(FullyCentered)`
    margin: ${sizes.tiny} ${sizes.none} ${sizes.nano} ${sizes.nano};
    padding: 14px;
`

const CardImageWord = styled(FullyCentered)`
    margin: ${sizes.small} ${sizes.none} ${sizes.micro} ${sizes.tiny};
    background: ${colors.night};
    border-radius: 50%;
    width: 100px;
    height: 65px;

    & > h1 {
        padding: 0;
        margin: 0;
    }
`