import Text from 'components/_UI/Text';
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import eventTypes from 'theme/eventTypes';
import types from 'helpers/system/types';
import fonts from 'theme/fonts';
import Icon from 'components/_UI/Icon';
import LinkPage from 'components/_UI/LinkPage';
import Button from 'components/_UI/Button';
import OpeningHours from './OpeningHours';
import Divider from 'components/_UI/Divider';
import sizes from 'theme/sizes';
import breakpoints from 'theme/breakpoints';
import Loader from 'components/_UI/Loader';
import touristSpotsApi from 'api/requests/touristSpots';
import { toast } from 'react-toastify';
import { useTouristSpots } from 'context/TouristSpots';

export default function TouristSpotsDetail({item, closeModal}) {
    
    const [showOpeningHours, setShowOpeningHours] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const {touristSpots, setTouristSpots} = useTouristSpots()
    
   
    const [toogleAction, setToogleAction] = useState(false)

    const actions = {
        delete: 'DELETE',
    }


    const onConfirmAction = () => {
        if(toogleAction === actions.delete) {
            deleteItem()
            return
        }
    }

    const deleteItem = async() => {
        setIsLoading(true)
        const payload = item.slug
        try {
            await touristSpotsApi.delete(payload)
            setTouristSpots({
                ...touristSpots,
                data: touristSpots.data.filter(el => el.slug !== payload) 
            })
            toast.success('Ponto turístico deletado com sucesso!', {
                position: "top-center",
                autoClose: 5000,
            })
        } catch (error) {
            toast.error('Ocorreu um erro inesperado', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
            closeModal()
        }
        
    }
  
  return (
    <Container>
        <HeadContent>
            <StyledBackgroundContainer type={item?.classification?.name}>
                {
                    item?.photo &&  item?.photo ? (
                        <StyledBackground src={item?.photo} />
                    ) : (
                        <FullyCentered> 
                            <Text name='hero' color={colors.neutral}> {item?.name.charAt(0).toUpperCase()} </Text>
                        </FullyCentered>
                    )
                }
            </StyledBackgroundContainer>
            <MainContent>
                <Text color={colors.nightDark} name='title'> {item?.name} </Text>
                <CategoriesInfos>
                        <EventType type={item.classification.name}>
                            {types.getBySlug(item?.classification?.name).label}
                        </EventType>

                        <Text color={colors.nightDark} ellipsisAt='95%' marginLeft='10px'>
                            {item?.categories?.map(el => `#${el.name} `)}
                        </Text>
                </CategoriesInfos>

                <br></br>

                <ContentInfoRow address>
                        <ContentIconContainer>
                            <Icon color={colors.night} name='marker-in-map' />
                        </ContentIconContainer>
                        <LinkPage >
                            <Text color={colors.primaryDark} decoration='underline'>
                                {item?.address?.name}, Bairro {item?.address?.district}
                                {item?.address?.number && `, nº ${item?.address?.number}`}
                                {item?.address?.complement && ` - ${item?.address?.complement}`}
                            </Text>
                        </LinkPage>
                </ContentInfoRow>

                <ContentInfoRow>
                    <ContentIconContainer>
                        <Icon color={colors.night} name='calendar' />
                    </ContentIconContainer>
                    
                    <ContainerOpeningHours>
                       { item?.times.length !== 0 ? (
                           <>
                                <Button
                                    category='outline'
                                    size='medium'
                                    onClick={() => setShowOpeningHours(true)}
                                >
                                    Ver horário de funcionamento
                                </Button>
                                {
                                    item?.times && 
                                    <OpeningHours 
                                        data={item?.times}
                                        visible={showOpeningHours}
                                        setVisible={setShowOpeningHours}
                                    />
                                }
                            </>
                        ) : (
                            <Text paddingBottom={0} color={colors.occasionalGreen} type='italic' name='medium'>24 Horas</Text>
                        )
                       }
                    </ContainerOpeningHours>
                </ContentInfoRow>

            {
                item?.link && (
                    <ButtonContainer>
                        <LinkPage isWrapper to={item?.link} target={'_blank'}>
                            <Button>Garantir ingresso</Button>
                        </LinkPage>
                    </ButtonContainer>
                )
            }

            </MainContent>
        </HeadContent>

        <Description>
            <Text marginTop='30px' name='subtitle'>Descrição</Text>
            <Text color={colors.nightDark} marginTop='2px' marginBottom='16px'> {item?.description} </Text>
        </Description>

        <Divider />
        <ContentActions>
            {!isLoading ? (
                toogleAction ? (
                    <>
                        <Button size='small' category='rounded' onClick={() => setToogleAction(false)}>
                            Cancelar
                        </Button>
                        <Button 
                            marginLeft={sizes.tiny}
                            size='small' 
                            category='rounded' 
                            marginRight={sizes.mini} 
                            onClick={() => onConfirmAction()}
                        >
                            Confirmar
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            size='small'
                        >
                            Editar
                        </Button>
                        <Button
                            size='small'
                            marginLeft={sizes.tiny}
                            onClick={() => setToogleAction(actions.delete)}
                        >
                            Excluir
                        </Button>
                    </>
                )
            ): (
                <Loader />
            )}
        </ContentActions>
    </Container>
  )
}
const Container = styled(Column)`
    width: 100%;
`;

const HeadContent = styled(Column)`
    width: 100%;
`;

const StyledBackgroundContainer = styled(FullyCentered)`
    width: 100%;
    height: auto;  
    position: relative;
    background: ${colors.neutralDark};

    ${({ type }) => type === eventTypes.free.slug && ` 
        & > div {
            background: ${eventTypes.free.color};
            height: 200px;
            border-radius: 0 0 18px 18px;
        }
        
    `}

    ${({ type }) => type === eventTypes.paid.slug && `
        & > div {
            background: ${eventTypes.paid.color};
            height: 200px;
            border-radius: 0 0 18px 18px;
        }    
    `}
    
    ${({ isImage }) => isImage && `
        background: ${colors.neutralLight};
        width: 100%;
    `}
`;

const StyledBackground = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 0 0 18px 18px;
`;

const MainContent = styled(Column)`
    padding: 16px 6%;
    justify-content: center;
    align-items: center;   
    width: 100%; 

    & > h1 {
        margin-top: 6px;
        margin-bottom: 4px;
    }
`;

const EventType = styled.div`
    color: ${colors.neutralLight};
    border-radius: 30px;
    padding: 4px 10px;
    font-size: ${fonts.sizes.small};
    ${({ type }) => type === eventTypes.free.slug && `
        background: ${eventTypes.free.color};
    `}
    ${({ type }) => type === eventTypes.paid.slug && `
        background: ${eventTypes.paid.color};
    `}
`;

const CategoriesInfos = styled(Row)`
    width: 100%;
    align-items: center;

    & > p {
        padding-bottom: 0;
    }
`;

const ContentInfoRow = styled(Row)`
    margin-bottom: 18px;
    align-items: flex-start;
    text-align: left;
    align-items: center;

    ${({ address }) => address && `
        align-items: center;
    `}

    &:nth-child(3){
        margin-bottom: 0;
    }

    & > p {
        padding: 0;
        margin: 0;
    }
`;

const ContentIconContainer = styled.div`
    width: 33px;
    height: 33px;
    margin-right: 14px;
    margin-bottom: 5px;
`;

const ContainerOpeningHours = styled.div`
    height: auto;
`;
const Description = styled(Column)`
    padding: 0 26px;
    align-items: center;
    text-align: justify;
    
`;

const ContentActions = styled(FullyCentered)`
    width: 100%;
`;
const ButtonContainer = styled(FullyCentered)`
    width: 300px;
    margin-top: 10px;
    flex-direction: column;
    padding: ${sizes.none} ${sizes.medium};
    @media screen and (min-width: ${breakpoints.sm}) {
        flex-direction: row;
        & > a:nth-child(2) {
            margin-left: 4% !important;
        }
    }
    
    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: column;
        & > a:nth-child(2) {
            margin-left: 0% !important;
        }
    }
`;
