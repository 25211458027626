import Content from 'components/main/Content'
import ActionFlagProvider from 'context/ActionFlag'
import FeatureFlagProvider from 'context/FeatureFlag'
import FeatureFlagContent from 'modules/feature-flags/FeatureFlagContent'
import React from 'react'

export default function FeatureFlag() {
    return (
        <FeatureFlagProvider>
            <ActionFlagProvider>
                <Content title='Feature Flag'>
                    <FeatureFlagContent />
                </Content>
            </ActionFlagProvider>
        </FeatureFlagProvider>
    )
}
