import styled, { keyframes } from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import colors from 'theme/colors';

const spin = keyframes`
    from {
        transform: rotate(0);
    }
    to{
        transform: rotate(359deg);
    }
`;

export const StyledLoaderBox = styled(FullyCentered)`
    width: 100%;
    height: auto;
    background-color: transparent;
    margin: 10px 0;
`;

export const StyledSpinner = styled.div`
    width: 26px;
    height: 26px;
    border: 3px solid ${colors.primary};
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: ${spin} .5s linear 0s infinite;
`