import Text from 'components/_UI/Text'
import React from 'react'
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import sizes from 'theme/sizes'

export default function Tags({data}) {
  return (
    <>
        <Text
            name='subtitle'
            marginTop={sizes.medium}
        >
            Categorias
        </Text>
        <Row>
            <Card large>
                <Row fullyCentralized>
                    <Column fullyCentralized>
                        <Text name='hero'>
                            {data?.count_tags.length}
                        </Text>
                        <Text name='highlight'>
                            Utilizadas
                        </Text>
                    </Column>
                    <Column fullyCentralized>
                        <Text name='highlight'> Top 3 </Text>
                        <Text>{data?.count_tags[0]?.name} - {data?.count_tags[0]?.total} </Text>
                        <Text>{data?.count_tags[1]?.name} - {data?.count_tags[1]?.total} </Text>
                        <Text>{data?.count_tags[2]?.name} - {data?.count_tags[2]?.total} </Text>
                    </Column>
                </Row>
            </Card>
    
        </Row>
    </>
  )
}
const Card = styled(FullyCentered)`
    width: 19.5%;
    height: 130px;
    box-shadow: ${sizes.none} ${sizes.nano} ${sizes.nano} ${colors.nightLight};
    border-radius: ${sizes.mini};
    background: ${colors.neutral};
    flex-direction: column;
    margin-left: ${sizes.medium};
    ${({large}) => large && `
        width: 40%;
    `}
    &:nth-child(1) {
        margin-left: ${sizes.none};
    }
    @media screen and (max-width: 820px) {
        flex-direction: column;
        width: 100%;
        margin-left: 0;
        margin-top: 15px;
    }
`;
