import Fieldset from 'components/_UI/Fieldset';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import masks from 'helpers/masks';
import regex from 'helpers/regex';
import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import addressApi from 'api/requests/address';
import { HorizontalCenter } from 'styled/alignment/Center';
import colors from 'theme/colors';
import fieldMessages from 'texts/messages/field';

import { useEvents } from 'context/Events';

export default function AddressInfo({ trigger, isEdit}) {
    const { events, setEvents } = useEvents();

    const [address, setAddress] = useState(null);
    const [addressLoading, setAddressLoading] = useState(false);
    const [cepFilled, setCepFilled] = useState(false);
    const [withoutNumber, setWithoutNumber] = useState(false);
    const [errors, setErrors] = useState(false);
   
    
    useEffect(() => {
        if (trigger && !cepFilled) {
            setErrors({ ...errors, cep: 'CEP invalido' })
        }
        if(isEdit) {
            setAddress(isEdit.address)
            setCepFilled(true)
            if(isEdit.address.name && !isEdit.address.number) {
                setWithoutNumber(true)
            } 
            
            if(!isEdit.address.name) {
                setWithoutNumber(false)
            }
        }
        
        if (trigger && cepFilled && !address?.number) {
            setErrors({ ...errors, number: fieldMessages.required('número') })
        }

    }, [trigger])

    async function getAddress(payload) {
        setAddressLoading(true)
        if(isEdit) {
            if(withoutNumber) {
                setWithoutNumber(false)
            }
        }
        try {
            const { data } = await addressApi.get(payload);
            setAddress(data)
            setEvents({ ...events, address: data })
        } catch (error) {
            setErrors({ ...errors, cep: 'CEP invalido' })
            setAddress(false)
        } finally {
            setAddressLoading(false)
        }
    }

    function validateCEP(cep) {
        return regex.cep.test(cep);
    }

    function showAddressFields(e) {

        if (!validateCEP(e)) {
            if (trigger) {
                setErrors({ ...errors, cep: 'CEP invalido' })
            }
            return
        }

        const postalCodeNumbers = e.replace(regex.onlyNumber, '');

        if (postalCodeNumbers.length === 8) {
            setErrors({ ...errors, cep: false })
            setCepFilled(true);
            getAddress(postalCodeNumbers)
        }
    };

    function onChangeNameValue(e) {
        let formatedValue = events

        delete formatedValue.address.number
        delete formatedValue.address.postalcode


        setEvents({
            ...formatedValue,
            address: {
                ...events.address,
                name: e
            }
        })
    }

    function onChangeNumber(e) {
        setErrors({ ...errors, number: false });

        setEvents({
            ...events,
            address: {
                postalcode: address.street.postalcode,
                number: e,
            },
        });
    }

    return (
        <Column marginTop='18px'>
            <Text size='small' type='bold'> Endereço </Text>

            <Fieldset
                placeholder='CEP do local'
                layout='rounded'
                mask={masks.cep}
                name="cep"
                value={isEdit?.address.street.postalcode}
                onChangeValue={(e) => showAddressFields(e)}
            />

            {errors && (
                <HorizontalCenter>
                    <Text color={colors.danger}> {errors?.cep} </Text>
                </HorizontalCenter>
            )}

            {/* <GooglePlacesAutocomplete apiKey="AIzaSyBVAZSo_T393vB6yTtawzS_NGwo5IZqXZg" 
                selectProps={{
                    value,
                    onChange: setValue,
                }}/>   */}
            {/* {console.log(value)}      */}

            {address && (
                addressLoading
                    ? <Loader />
                    : (
                        <AddressFields visible={cepFilled} withoutNumber={withoutNumber}>

                            <Fieldset
                                placeholder='Bairro'
                                layout='rounded'
                                value={address.street.district}
                                badgeText='Bairro'
                                disabled
                            />
                            <Fieldset
                                placeholder='Rua'
                                layout='rounded'
                                value={address.street.name}
                                badgeText='Rua'
                                disabled
                            />
                            <Row>
                                <Fieldset
                                    placeholder='Número'
                                    layout='rounded'
                                    marginRight='14px'
                                    value={isEdit ? address?.number : null}
                                    variant={withoutNumber && 'disabled'}
                                    onChangeValue={(e) => onChangeNumber(e)}
                                />
                                <Fieldset
                                    placeholder='Complemento'
                                    layout='rounded'
                                    value={isEdit ? address?.complement : null}
                                    onChangeValue={(e) => setEvents({
                                        ...events,
                                        address: {
                                            ...events.address,
                                            complement: e
                                        }
                                    })}
                                />
                            </Row>
                            <Row marginLeft='4px'>
                                <input
                                    type="checkbox"
                                    value={withoutNumber}
                                    checked={withoutNumber}
                                    onChange={({ target }) => {
                                        setWithoutNumber(target.checked);
                                        setEvents({
                                            ...events,
                                            address: { ...events.address, number: null },
                                        });
                                        onChangeNumber(false)
                                    }}
                                />
                                <Text
                                    size='small'
                                    color={colors.night}
                                    paddingTop={'10px'}
                                    paddingLeft={'8px'}
                                >
                                    O endereço não possui número
                                </Text>
                            </Row>
                            {
                                withoutNumber && (
                                    <Fieldset
                                        layout='rounded'
                                        placeholder='Nome do local'
                                        value={isEdit ? address.name : null}
                                        onChangeValue={(e) => onChangeNameValue(e)}
                                    />
                                )
                            }

                          
                        </AddressFields>
                    )
            )}

            {errors && (
                <HorizontalCenter marginTop='12px'>
                    <Text color={colors.danger}> {errors?.number} </Text>
                </HorizontalCenter>
            )}
        </Column>
    )
}

const AddressFields = styled.div`
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-in;

    ${({ visible }) => visible && `
        visibility: visible;
        opacity: 1;
    `};

    ${({ visible, withoutNumber }) => visible && !withoutNumber && `
        height: 150px;
    `};

    ${({ visible, withoutNumber }) => visible && withoutNumber && `
        height: 190px;
    `};
`;