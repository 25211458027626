import React from 'react'
import styled, {keyframes} from 'styled-components'
import colors from 'theme/colors';
import indexPriority from 'theme/z-index';
import Sidebar from 'components/main/Sidebar';
import Icon from './Icon';
import { Column } from 'styled/alignment/Column';


  const moveToLeft = keyframes`
      from {
        transform: translateX(300px);
      }
      to {
        transform: translateX(0px);
      }
  `;


export default function Menu({visibleMenu, setVisibleMenu}) {
 
  const closeMenu = () => {
    setVisibleMenu(false)
  }
  return (
    <Column>
      <ContentMenu>
        <ContainerClose>
          <ContentButtonClose
            onClick={() => closeMenu()}
          >
            <Icon name='close'/>
          </ContentButtonClose>
        </ContainerClose>
        <ContentBackground>
            <Sidebar/>
        </ContentBackground>
      </ContentMenu>
    </Column>
  )
}

const ContentMenu = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: ${indexPriority.max};
    background: ${colors.secondaryLight};
    display: flex;
    width: 100%; 
    align-items: center;
    flex-direction: column;
    transition: ease-in  0.3s;
    animation: ${moveToLeft} 0.3s ease-in-out; 
  `;

const ContentBackground = styled.div`
`
const ContainerClose = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const ContentButtonClose = styled.div`
  padding: 10px 10px 0px 0px;
  width: 40px;
  height: 30px;
`;