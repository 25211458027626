import api from "api";

const address = {
    
    get: (payload) => {
        return api(false, true).get(`/addresses/${payload}`);
    }
    
   
};
 
export default address;  