import React, { createContext, useState, useContext } from 'react';

const TagsContext = createContext();

export default function TagsProvider({ children }) {
    const [tags, setTags] = useState('');
    return (
        <TagsContext.Provider value={{ tags, setTags}}>
            {children}
        </TagsContext.Provider>
    );
}

export function useTags() {
    const context = useContext(TagsContext);

    if (!context) throw new Error('useTags must be used within a UserProvider');

    const { tags, setTags } = context;
    return { tags, setTags };
}