const example = {
    texts: {
        title: 'Whizr',
        forgotPassword: 'Esqueceu seu nome de usuário ou senha?',
        restoreAccount: 'Recuperar conta',
        dontHaveAccount: 'Ainda não possui uma conta?',
        resendEmailText: 'Não recebeu seu e-mail de verificação?',
        resendEmailButton: 'Reenviar e-mail',
        modalVerify: 'O e-mail foi reenviado com sucesso!'
    },
    inputs: {
        username: 'E-mail',
        password: 'Senha'
    },
    buttons: {
        enter: 'Entrar',
        newAccount: 'Criar nova conta'
    }
}

export default example;