import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    StyledFieldset,
    StyledLabel,
    StyledInput,
    StyledBadgeText,
    StyledBadgeIcon
} from 'styled/UI/StyledForm';
import Icon from './Icon';

const Fieldset = React.forwardRef(({
    // layout props
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    // action props
    layout,
    label,
    value,
    placeholder,
    disabled,
    inputType,
    badgeText,
    badgeIcon,
    badgeIconColor,
    badgePosition,
    badgeIconBackground,
    isBadgeClickable,
    mask,
    name,
    register,
    validations,
    onChangeValue,
    onBadgeClick,
    onChangeFocus,
    syncProp,
    withDebounce,
    readOnly
}, ref
) => {

    const [newValue, setNewValue] = useState(value);

    const toggleValue = (e) => {
        setNewValue(e);
        onChangeValue(e || true);
    };

    return (
        <StyledFieldset
            marginTop={marginTop}
            marginRight={marginRight}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
        >
            {label && (
                <StyledLabel layout={layout}>
                    {label}
                </StyledLabel>
            )}
            {!register ? (
                <StyledInput
                    value={syncProp ? value : newValue}
                    placeholder={placeholder}
                    layout={layout}
                    type={inputType}
                    variant={'disabled'}
                    icon={badgeIcon}
                    mask={mask}
                    readOnly={readOnly}
                    onChange={(e) => toggleValue(e.target.value)}
                    onFocus={(e) => onChangeFocus(e)}
                />
            ) : (
                <StyledInput
                    ref={ref}
                    placeholder={placeholder}
                    layout={layout}
                    type={inputType}
                    variant={'disabled'}
                    icon={badgeIcon}
                    mask={mask}
                    onChange={(e) => toggleValue(e.target.value)}
                    onFocus={(e) => onChangeFocus(e)}
                    {...register(name, validations)}
                    readOnly={readOnly}
                />
            )}
            {badgeText && (
                <StyledBadgeText
                    position={badgePosition}
                    clickable={isBadgeClickable && !disabled}
                    layout={layout}
                    onClick={() => onBadgeClick()}
                >
                    {badgeText}
                </StyledBadgeText>
            )}

            {badgeIcon && (
                <StyledBadgeIcon
                    layout={layout}
                    bgColor={badgeIconBackground}
                    position={badgePosition}
                    clickable={isBadgeClickable && !disabled}
                    onClick={() => onBadgeClick()}
                >
                    <Icon name={badgeIcon} color={badgeIconColor} />
                </StyledBadgeIcon>
            )}
        </StyledFieldset>
    )
})

Fieldset.propTypes = {
    layout: PropTypes.oneOf(['straight', 'straight-center', 'rounded', 'roundedDark']),
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.string || PropTypes.bool,
    inputType: PropTypes.string,
    badgeText: PropTypes.elementType || PropTypes.string,
    badgeIcon: PropTypes.elementType || PropTypes.string,
    badgeIconColor: PropTypes.string,
    badgeIconBackground: PropTypes.string,
    badgePosition: PropTypes.oneOf(['left', 'right']),
    isBadgeClickable: PropTypes.bool,
    syncProp: PropTypes.bool,
    onChangeValue: PropTypes.func,
    onBadgeClick: PropTypes.func,
    debounce: PropTypes.number || PropTypes.bool,
    readOnly: PropTypes.bool
};

Fieldset.defaultProps = {
    layout: 'straight',
    label: '',
    value: '',
    placeholder: '',
    disabled: '',
    inputType: 'text',
    badgeText: '',
    badgeIcon: '',
    // badgeIconColor: '',
    // badgeIconBackground: 'transparent',
    badgePosition: 'right',
    isBadgeClickable: false,
    mask: '',
    readOnly: false,
    syncProp: false,
    onChangeValue: () => '',
    onBadgeClick: () => '',
    onChangeFocus: () => '',
};

export default Fieldset;