import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';
import fonts from 'theme/fonts';
import Icon from 'components/_UI/Icon';
import Button from 'components/_UI/Button';
import paths from 'routes/paths';
import { FullyCentered } from 'styled/alignment/Center';
import { Link } from 'react-router-dom';
import sizes from 'theme/sizes';
import userApi from 'api/requests/user';
import { toast } from 'react-toastify';

export default function Sidebar() {
    const [submenus, setSubmenus] = useState({});
    const toggleSubmenu = (itemName) => {
        setSubmenus((prevSubmenus) => ({
            ...prevSubmenus,
            [itemName]: !prevSubmenus[itemName],
        }));
    };

    const logout = async () => {
        try {
            await userApi.logout()
        } catch (error) {
            toast.error('Não foi possível desconectar do sistema!',{
                position: 'top-center'
            })
        } finally {
            localStorage.clear();
            window.location.href = paths.login;
        }
    }
    const sidebarItems = [
        { name: 'feature flag', route: paths.featureFlag, icon: 'feature-toogle'},
        { name: 'estatisticas', route: paths.statistics, icon: 'statistics' },
        { name: 'eventos', icon: 'mark', subItems: [{name: 'listagem', route: paths.events, icon: 'list'}, {name: 'categorias', route: paths.tags, icon: 'tags'}]},
        { name: 'usuários', icon: 'users', subItems: [{name: 'listagem', route: paths.users, icon: 'list'},{ name: 'permissões', route: paths.permissions, icon: 'permissions' },], },
        { name: 'parceiros', icon: 'partners', subItems:  [ {name: 'listagem', route: paths.usersPartners, icon: 'list'}, {name: 'estabelecimentos', route: paths.establishment, icon: 'establishment'}, {name: 'promoções', route: paths.promotions, icon: 'promotion'}]},
        { name: 'belezas naturais',  icon: 'natural-beauty', subItems: [{name: 'listagem', route: paths.naturalBeauty, icon: 'list'}, {name: 'categorias', route: paths.tagsNaturalBeauty, icon: 'tags'}]},
        { name: 'pontos turísticos',  icon: 'tourist-spots', subItems: [{name: 'listagem', route: paths.touristSpots, icon: 'list'}, {name: 'categorias', route: paths.tagsSpots, icon: 'tags'}]},
    ]
    return (
        <StyledSidebar>
            <ContentSidebar>
                <Link to='/eventos'>
                    <IconLogo>
                        <Icon name='whizr-vertical-logo' color={colors.neutralLight} />
                    </IconLogo>
                </Link>
                <SidebarItems>
                    {sidebarItems.map(el => (
                        <>
                            <SidebarContainerItem>
                                <SidebarContentItem>
                                    <IconContainer>
                                        <Icon name={el.icon}  />
                                    </IconContainer>
                                    <SidebarItem
                                        category='minimal'
                                        textTransform="capitalize"
                                        marginBottom='none'
                                        path={el.route}
                                        onClick={() => toggleSubmenu(el.name)} 
                                    >
                                        {el.name}
                                    </SidebarItem>
                                </SidebarContentItem>
                            </SidebarContainerItem>
                            <ContainerItems>
                                {submenus[el.name] && (
                                    <SubMenu>
                                        {el?.subItems?.map((items) => (
                                            <ContentLink>
                                                <Link to={items.route}>
                                                    <ContentSubMenu>
                                                        <IconContainer subMenu>
                                                            <Icon name={items.icon}  />
                                                        </IconContainer>
                                                        <SubMenuItem>
                                                            {items.name}
                                                        </SubMenuItem>
                                                    </ContentSubMenu>
                                                </Link>
                                            </ContentLink>
                                        ))}
                                    </SubMenu>
                                )}
                            </ContainerItems>
                        </>
                    ))}
                </SidebarItems>
            </ContentSidebar>     

            <ContentLogout>
                <SidebarContainerItem>
                    <SidebarContentItem>                
                        <IconContainer>
                            <Icon name='logout' />
                        </IconContainer>
                        <SidebarItem
                            category='minimal'
                            textTransform='capitalize'
                            marginBottom='none'
                            onClick={() => logout()}
                         >
                            Sair
                        </SidebarItem>
                    </SidebarContentItem>
                </SidebarContainerItem>   
            </ContentLogout>
        </StyledSidebar>
    )
};


const StyledSidebar = styled.div`
    flex-direction: column;
    width: 240px;
    background: ${colors.secondaryLight};
    padding: ${sizes.gigantic} ${sizes.little};
    @media screen and (max-width: 720px) {
        padding: 0;
    }
    justify-content: space-between;
    display: flex;  
`;

const IconLogo = styled.div`
    width: auto;
    height: 120px;
    margin-bottom: ${sizes.tiny};
    transition: all ease-in-out .2s;
    cursor: pointer;
    
    &:hover {
        transform: scale(1.1);
    }   
`;
const SidebarItems = styled(Column)`
    width: 100%;
    display: flex;
    padding: ${sizes.normal} ${sizes.none};
`;
const IconContainer = styled.div`
    width: ${sizes.large};
    height: ${sizes.large};
    margin: ${sizes.none} ${sizes.nano} ${sizes.none} ${sizes.normal};

    ${({ subMenu }) => subMenu && css`
       width: 15px;
       height: 15px;
    `};

`;
const SidebarContainerItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;

`;
const SidebarContentItem = styled(FullyCentered)`
    height: ${sizes.large};
    width: auto;
    flex-direction: row;
    margin: ${sizes.little} ${sizes.none};
    cursor: pointer;
    transition: all ease-in-out .2s;
    
    &:hover {
        transform: scale(1.1);
    }
`;
const SidebarItem = styled(Button)`
    color: ${colors.neutralLight};
    font-size: ${fonts.sizes.highlight};
    background: transparent;
    align-items: center;
    margin-top: ${sizes.nano};
    font-size: 1.2em !important;
    padding: ${sizes.tiny} ${sizes.none} !important;
    cursor: pointer;
    transition: all ease-in-out .2s;
`;
const ContentSidebar = styled(Column)`
    display: flex;
    justify-content: space-between;
`;

const ContentLogout = styled.div`
    width: 100%;
    margin-top: 100px;
`;
const SubMenu = styled.div`
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: flex-start;
`;

const SubMenuItem = styled.button`
    margin-left: 5px;
    color: ${colors.neutralLight};
    text-transform: capitalize;
    font-size: ${fonts.sizes.highlight};
    background: transparent;
    margin-top: ${sizes.none};
    font-size: 0.8em !important;
`;

const ContentSubMenu = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all ease-in-out .2s;
    &:hover {
        transform: scale(1.1);
    }
`;

const ContainerItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


const ContentLink = styled.div`
    margin-top: ${sizes.small};
    &:first-child {
        margin-top: ${sizes.none};
    }
`;