import Modal from 'components/_UI/Modal';
import Text from 'components/_UI/Text';
import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { StyledForm } from 'styled/UI/StyledForm';
import sizes from 'theme/sizes';
import BasicInfo from './parts/BasicInfo';
import CategoriesInfo from './parts/CategoriesInfo';
import ImageInfo from 'modules/events/form/create/ImageInfo';
import { useTouristSpots } from 'context/TouristSpots';
import { toast } from 'react-toastify';
import imageUpload from 'api/requests/imageUpload';
import { useForm } from 'react-hook-form';
import Loader from 'components/_UI/Loader';
import Button from 'components/_UI/Button';
import AddressInfo from './parts/AddressInfo';
import ExtraInfo from './parts/ExtraInfo';
import touristSpotsApi from 'api/requests/touristSpots';


const emptyDays = {
  sunday: [] ,
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
}

export default function CreateTouristSpotsForm({ modalVisible, setModalVisible }) {
  const { touristSpots, setTouristSpots } = useTouristSpots()
  const [isLoading, setIsLoading] = useState(false)
  const [trigger, setTrigger] = useState(false);
  const [tagsError, setTagsError] = useState(false);

  const [formData] = useState(null);
  

  useEffect(() => {
    if (formData !== null) {
        setTouristSpots({ ...touristSpots, images: formData });
    }
  }, [formData, setTouristSpots]);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();


  async function uploadImage(file) {
    const formData = new FormData()

    formData.append('image', file)
    formData.append('folder', 'points')

    try {
      const { data } = await imageUpload.uploadWithFolder(formData)
      setTouristSpots({ ...touristSpots, image_id: data.id})
    } catch (error) {
      toast.error('Não foi possível carregar imagem!', {
        position: 'top-center'
      })
    }
  }



  function createPayload(content) {

    let data = { ...content, ...touristSpots }

    if (data.days) {
      const extractedValues = {};

      Object.keys(data.days).forEach((day) => {
        extractedValues[day] = data.days[day].fields;
      });

      data = {...data, days: extractedValues}
    }

    // if ('24hrs') {
        // data.days = []
        // delete data.days
    // }

    if(!data.classification_id) {
      data = {...data, classification_id: 1}
    }

    if (data?.categories?.length) {
      data = { ...data, categories_id: [data.categories.map(el => el.id)] }
    }

    if (!data?.days) {
      data = {...data, days: emptyDays}
    }

    if (!data?.link_ticket) {
      delete data.link_ticket
    }


    return data
  }

  // function isValidTags() {
  //   if (!touristSpots.tags) {
  //         setTagsError('min');
  //         return false;
  //   }    
  // }

  async function createTouristSpots(content) {

    // if (!isValidTags()) {
    //     return;
    // }

    const payload = createPayload(content);
    setIsLoading(true)
    try {
      setTagsError(false);
      const { data } = await touristSpotsApi.create(payload);

      setTouristSpots({
          ...touristSpots, 
           data: [...touristSpots.data, data]
       })
      toast.success('Evento criado com sucesso!', {
          position: 'top-center'
      })
    } catch (error) {
      toast.error('Não foi possível criar o evento!!', {
          position: 'top-center'
      })
    } finally {
      setIsLoading(false)
      // setModalVisible(false)
    }
  }


  return (
    <>
      <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)}>
        <ModalWrapper>
          <StyledForm onSubmit={handleSubmit(createTouristSpots)}>

            <Text name='subtitle'>Criar Ponto Turístico</Text>

            <BasicInfo register={register} errors={errors} />
            <ImageInfo touristSpots onToggleImage={(e) => uploadImage(e)} />
            <CategoriesInfo error={tagsError} />

            <AddressInfo trigger={trigger} />

            <ExtraInfo register={register} errors={errors} />

            {!isLoading ? (
              <span onClick={() => setTrigger(true)}>
                <Button
                  marginTop={sizes.intermediary}
                  paddingBottom={sizes.grand}
                  type="submit"
                >
                  Criar ponto turístico
                </Button>
              </span>
            ) : (
              <Loader />
            )
            }
          </StyledForm>
        </ModalWrapper>
      </Modal>
    </>
  )
}

const ModalWrapper = styled.div`
  padding: ${sizes.large} ${sizes.none} ${sizes.large} ${sizes.none};
`;