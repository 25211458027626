const masks = {
    date: '99/99/9999',
    phone: '(99) 99999-9999',
    postalCode: '99999-999',
    cpf: '999.999.999-99',
    cep: '99999-999',
    creditCard: '9999-9999-9999-9999',
    time: '99:99',
};

export default masks;