import React from 'react';
import Content from 'components/main/Content';
import StatisticsContent from 'modules/statistics/StatisticsContent';

export default function Statistics() {
    return (
        <Content title='Estatísticas'>
           <StatisticsContent />
        </Content>
    );
}
