const paths = {
    login: '/entrar',
    events: '/eventos',
    statistics: '/estatisticas',
    featureFlag: '/feature-flag',
    usersPartners: '/usuarios-parceiros',
    users: '/usuarios',
    tags: '/categorias',
    touristSpots: '/pontos-turisticos',
    tagsSpots: '/categorias-pontos-turisticos',
    naturalBeauty: '/belezas-naturais',
    tagsNaturalBeauty: '/categorias-belezas-naturais',
    permissions: '/permissoes',
};

export default paths;