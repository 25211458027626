import React, {useState} from 'react'
import EventsFilters from './filters/EventsFilters'
import EventCardResultFilter from './card/EventCardResultFilter'
import CreateEventForm from './CreateEventForm'
import { useEventsList } from 'context/EventsList';
import Loader from 'components/_UI/Loader'
import SearchNotFound from 'components/_UI/SearchNotFound'
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import sizes from 'theme/sizes';
import { Row } from 'styled/alignment/Row';
import ButtonAdd from 'components/_UI/ButtonAdd';

export default function EventsContent() {
  const {eventsList} = useEventsList()
  const [modalVisible, setModalVisible] = useState(false)
 
  
  return (
    <Container>  
        <ButtonAddContainer>
            <ButtonAdd 
                variant={
                  eventsList?.data?.length > 0
                    ? 'primary'
                    : eventsList?.isFilter
                    ? 'primary'
                    : 'disabled' 
                }
                onClick={() => setModalVisible(true)}
                >
                Adicionar Evento
            </ButtonAdd>
         </ButtonAddContainer>

         {
          modalVisible && (
              <CreateEventForm 
                  setModalVisible={setModalVisible} 
                  modalVisible={modalVisible}
                  create
              />
          )    
         }

      <EventsFilters />
      {eventsList?.loading ? <Loader /> : <EventCardResultFilter />}

      {eventsList?.isNotFound &&(
          <ContainerSearchNotFound>
            <SearchNotFound />
          </ContainerSearchNotFound>
      )}

      {eventsList?.data.length === 0 && (
        !eventsList.isNotFound  &&
          <>  
            <SearchNotFound />
          </>
        )
      }
    </Container>
    

  )
};

const ContainerSearchNotFound = styled(FullyCentered)`
  padding: ${sizes.tiny};
`;
const ButtonAddContainer = styled(Row)`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const Container = styled.div`
  flex-direction: column;
  /* @media screen and (max-width: 1000px){
    width: 750px;
    background: red;
  } */
`

