import React from 'react';
import Content from 'components/main/Content';
import UsersContent from 'modules/users/UsersContent';
import UsersFilterProvider from 'context/UsersFilter';
import UserProvider from 'context/User';

export default function Users() {
    return (
        <UserProvider>
            <UsersFilterProvider>
                <Content title='Usuários'>
                    <UsersContent />
                </Content>
            </UsersFilterProvider>
        </UserProvider>
    );
}
