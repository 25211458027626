import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import Events from './Events'
import Users from './Users'
import Tags from './Tags'
export default function StatisticsContent({data}) {
  return (
    <>
        <EventsContainer>
            <Events data={data} />
            <Users data={data} />
            <Tags data={data} />
        </EventsContainer>
    </>
  )
}

const EventsContainer = styled(Column)`
    width: 100%;
`;
