import eventTypes from "theme/eventTypes"

const types = {
    getBySlug: (type) => {
        const selected = Object.values(eventTypes).find(el => el.slug === type)

        return selected
    },

    getById: (id) => {
        const selected = Object.values(eventTypes).find(el => el.id === id)

        return selected
    }
}

export default types;