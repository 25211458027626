import dayjs from 'dayjs';

const mounths = [
	'Janeiro',
	'Feveiro',
	'Março', 
	'Abril', 
	'Maio',
	'Junho',
	'Julho',
	'Agosto',
	'Setembro',
	'Outubro',
	'Novembro',
	'Dezembro'
]

const date = {
	format: (d, separator = '/') => dayjs(d).format(`DD${separator}MM${separator}YYYY`),

	unformat: (d) => {
		const dateParts = d.split('/');
        const unformatedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
		
		return unformatedDate
	},

	getDay: (d) => dayjs(d).format('DD'),

	getMonth: (d) => dayjs(d).format('MM'),

	getYear: (d) => dayjs(d).format('YYYY'),

	getTimeUntilNow: (d) => dayjs().to(dayjs(d), true),

	getCurrentDate: () => dayjs(),

	getTimeWithoutSeconds: (t) => {
		const values = t.split(':')
		const hour  = values[0]
		const minutes  = values[1]

		return `${hour}:${minutes}`
	},	

	getMounthName: (d) => {
		const selected = Number(dayjs(d).format('MM'))
		
		return mounths[selected - 1]
	}

};

export default date;