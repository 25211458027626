import React, { createContext, useState, useContext } from 'react';

const NaturalBeautyContext = createContext();

export default function NaturalBeautyProvider({ children }) {
    const [naturalBeauty, setNaturalBeauty] = useState(null);

    return (
        <NaturalBeautyContext.Provider value={{naturalBeauty, setNaturalBeauty}}>
            {children}
        </NaturalBeautyContext.Provider>
    );
}

export function useNaturalBeauty() {
    const context = useContext(NaturalBeautyContext);
    
    if (!context) throw new Error('useNaturalBeautyProvider must be used within a naturalBeauty');
    
    const { naturalBeauty, setNaturalBeauty } = context;
    return { naturalBeauty, setNaturalBeauty };
}