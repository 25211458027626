import Button from 'components/_UI/Button'
import Fieldset from 'components/_UI/Fieldset'
import Text from 'components/_UI/Text'
import TextArea from 'components/_UI/TextArea'
import { useNaturalBeauty } from 'context/NaturalBeauty'
import fieldValidations from 'helpers/fieldValidations'
import masks from 'helpers/masks'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FullyCentered, HorizontalCenter } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import sizes from 'theme/sizes'

export default function BasicInfo({ register, errors }) {

    const field = { start: '00:00', end: '00:00' }
    const days = {
        sunday: { label: 'domingo', active: false, fields: [field] },
        monday: { label: 'segunda', active: false, fields: [field] },
        tuesday: { id: 'tuesday', label: 'terça', active: false, fields: [field] },
        wednesday: { label: 'quarta', active: false, fields: [field] },
        thursday: { label: 'quinta', active: false, fields: [field] },
        friday: { label: 'sexta', active: false, fields: [field] },
        saturday: { label: 'sábado', active: false, fields: [field] }
    }
    const {naturalBeauty, setNaturalBeauty} = useNaturalBeauty()
    const [selectedDay, setSelectedDay] = useState(null)
    const [selectedDays, setSelectedDays] = useState([]);
    const [renderDays, setRenderDays] = useState(days);

    function toggleDaySelection(day) {
        if (selectedDays.includes(day)) {
            setSelectedDays(selectedDays.filter(selectedDay => selectedDay !== day));
        } else {
            setSelectedDays([...selectedDays, day]);
        }
    }

    function renderColum(day) {
        setRenderDays(prevRenderDays => {
            return {
                ...prevRenderDays,
                [day]: {
                    ...prevRenderDays[day],
                    fields: [...prevRenderDays[day].fields, field]
                }
            };
        });
    }

    function updateField(dayKey, index, fieldToUpdate, value) {
        setRenderDays((prevRenderDays) => {
            return {
                ...prevRenderDays,
                [dayKey]: {
                    ...prevRenderDays[dayKey],
                    fields: prevRenderDays[dayKey].fields.map((field, i) => {
                        if (i === index) {
                            return {
                                ...field,
                                [fieldToUpdate]: value,
                            };
                        }
                        return field;
                    }),
                },
            };
        });

        setNaturalBeauty({...naturalBeauty, days: renderDays})
    }


    return (
        <Column marginTop={sizes.little}>
            <Fieldset
                placeholder='Nome da beleza natural'
                layout='rounded'
                name='name'
                register={register}
                validations={fieldValidations.generic('name', 2, 100)}
            />

            {errors && (
                <HorizontalCenter>
                    <Text color={colors.danger}> {errors.name?.message} </Text>
                </HorizontalCenter>
            )}

            <TextArea
                placeholder='Descrição'
                name='description'
                validations={fieldValidations.description('descrição')}
                register={register}
            />

            {errors && (
                <HorizontalCenter marginTop={'10px'}>
                    <Text color={colors.danger}> {errors.description?.message} </Text>
                </HorizontalCenter>
            )}

            <Text
                size='small'
                type='bold'
                marginTop={sizes.medium}
                marginBottom={sizes.tiny}
            >
                Horário de funcionamento
            </Text>

            <ContainerDays>
                <Column fullyCentered>
                    <ContainerDays>
                        <Column fullyCentered>
                            <ContainerDays>
                                <Column fullyCentered>
                                    <ContentDays>
                                        {Object.keys(renderDays).map(dayKey => {
                                            const day = renderDays[dayKey];
                                            return (
                                                <Days key={dayKey}>
                                                    <Row>
                                                        <InputDay
                                                            type='checkbox'
                                                            checked={selectedDays.includes(dayKey)}
                                                            onChange={() => toggleDaySelection(dayKey)}
                                                        />
                                                        <label>{day.label}</label>
                                                    </Row>
                                                    <ContainerFieldDays>
                                                        {selectedDays.includes(dayKey) && (
                                                            <Column marginTop={sizes.tiny}>
                                                                {day.fields.map((field, index) => (
                                                                    <Row verticalCenter key={index}>
                                                                        <Fieldset
                                                                            badgeText='Horas'
                                                                            placeholder={`Abre`}
                                                                            marginRight={sizes.tiny}
                                                                            mask={masks.time}
                                                                            onChangeValue={(e) => updateField(dayKey, index, 'start', e)}
                                                                        />
                                                                        <span>-</span>
                                                                        <Fieldset
                                                                            badgeText='Horas'
                                                                            placeholder={`Fecha`}
                                                                            marginLeft={sizes.tiny}
                                                                            mask={masks.time}
                                                                            onChangeValue={(e) => updateField(dayKey, index, 'end', e)}
                                                                        />
                                                                    </Row>
                                                                ))}
                                                                <Button onClick={() => renderColum(dayKey)}> Adicionar Campo </Button>
                                                            </Column>
                                                        )}
                                                    </ContainerFieldDays>
                                                </Days>
                                            );
                                        })}

                                    </ContentDays>
                                </Column>

                            </ContainerDays>
                        </Column>
                    </ContainerDays>

                </Column>
            </ContainerDays >



        </Column >
    )
}

const DaysCountLabel = styled.div`
    text-align: left;
`;
const Days = styled.div`
    /* width: 100%; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

`
const ContainerDays = styled(FullyCentered)`
    width: 100%;
`;
const ContentDays = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const InputDay = styled.input`
    margin-right: 5px;
`
const ContainerFieldDays = styled.div`
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 350px;
`