import Content from 'components/main/Content'
import NaturalBeautyProvider from 'context/NaturalBeauty'
import TagsNaturalBeautyProvider from 'context/TagsNaturalBeauty'
import NaturalBeautyContent from 'modules/natural-beauty/NaturalBeautyContent'
import React from 'react'

export default function NaturalBeauty() {
  return (
    <NaturalBeautyProvider>
      <TagsNaturalBeautyProvider>
        <Content title='Belezas Naturais'>
            <NaturalBeautyContent />
        </Content>
      </TagsNaturalBeautyProvider>
    </NaturalBeautyProvider>
  )
}
