import Button from 'components/_UI/Button'
import Fieldset from 'components/_UI/Fieldset'
import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import fieldValidations from 'helpers/fieldValidations'
import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { StyledForm } from 'styled/UI/StyledForm'
import { HorizontalCenter } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'
import sizes from 'theme/sizes'
import tagsNaturalBeautyApi from 'api/requests/tagsNaturalBeauty'
import { toast } from 'react-toastify'
import Loader from 'components/_UI/Loader'
import { useTagsNaturalBeauty } from 'context/TagsNaturalBeauty'
import { useActionForTables } from 'context/ActionForTables'

export default function CreateTagsNaturalBeautyForm({openModal, closeModal}) {
    
    const {setActionForTables} = useActionForTables()
    const {tagsNaturalBeauty, setTagsNaturalBeauty} = useTagsNaturalBeauty()
    const [isLoading, setIsLoading] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({});

    
    const createTag = async ({ nameTag }) => {
        setIsLoading(true)
        const payload = {
            name: nameTag
        }
        try {
            const { data } = await tagsNaturalBeautyApi.create(payload)

            const newContent = {
                id: data.id, name: data.name, action: ['edit', 'delete']
            }
            const updatedTags = [...tagsNaturalBeauty, newContent];
            setTagsNaturalBeauty(updatedTags);
            const newData = updatedTags?.map(el => {
                return {
                    id: el.id,
                    name: el.name,
                    action: ['edit', 'delete']
                }
            })
            setActionForTables(Object.values(newData).map(el => el.action))
            toast.success('Categoria criada com sucesso!', {
                position: "top-center",
                autoClose: 5000,
            });
    
        } catch (error) {
            toast.error('Ocorreu um erro inesperado', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
            closeModal()
        }
    }
  return (
    <Modal isOpen={openModal} onClose={closeModal}>
        <ModalWrapper>
            <StyledForm onSubmit={handleSubmit(createTag)}>
                <Text name='subtitle'>Criar Categoria</Text>
                <Fieldset 
                    name='nameTag'
                    placeholder='Nome da Categoria'
                    register={register}
                    validations={fieldValidations.name('nome da categoria')}
                />
                 {
                    (errors.nameTag?.message) && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.nameTag?.message} </Text>
                        </HorizontalCenter>
                    )
                }
                {
                    !isLoading ? (
                        <Button
                            type='submit'
                            marginTop={sizes.tiny}
                        >
                            Criar
                        </Button>

                    ) : (
                        <Loader />
                    )
                }
            </StyledForm>
        </ModalWrapper>
    </Modal>
  )
}

const ModalWrapper = styled(Column)`
    padding: ${sizes.large} ${sizes.none} ${sizes.large} ${sizes.none};
`