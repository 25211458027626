import React, {useState, useEffect} from 'react'
import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import sizes from 'theme/sizes'
import Button from 'components/_UI/Button'
import eventTypes from '../../theme/eventTypes'
import date from 'helpers/date'
import eventsApi from 'api/requests/events'
import { toast } from 'react-toastify'
import { useEvents } from 'context/Events'
import Loader from 'components/_UI/Loader'
import BasicInfos from './form/create/BasicInfos'
import CategoriesInfo from './form/create/CategoriesInfo'
import AddressInfo from './form/create/AddressInfo'
import ExtraInfo from './form/create/ExtraInfo'
import imageUpload from 'api/requests/imageUpload'
import ImageInfo from './form/create/ImageInfo'
import { useEventsList } from 'context/EventsList'

export default function CreateEventForm({edit, create, setModalVisible, modalVisible}) {
    const { events, setEvents } = useEvents();
    const {eventsList, setEventsList} = useEventsList()
    
    const [isLoading, setIsLoading] = useState(false)
    const [trigger, setTrigger] = useState(false);
    const [tagsError, setTagsError] = useState(false);
    const [formData] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: !edit ? {} : {
            name:edit?.name,
            description:edit?.description,
            link_ticket: edit?.link_ticket
        }
            
    });
    useEffect(() => {
        if (formData !== null) {
            setEvents({ ...events, images: formData });
        }
        if(create) {            
            setModalVisible(true)
        }
        if(edit) {
            setModalVisible(true)
        }
    }, [formData, setEvents]);
    
    function removeDuplicates(obj) {
        const result = obj;

        for (const key in result) {
            if (key.startsWith('start') || key.startsWith('end') || key.startsWith('date')) {
                delete result[key];
            }
        }

        return result;
    }

    function createDatesPayload(content) {
    
        const result = [];

        const regex = /^date\d+$/
        let count = 0;

        for(const key in content) {
            if(regex.test(key)) {
                count++;
            }
        }

        for (let i = 1; i <= count; i++) {
            const dateKey = "date" + i;
            const startKey = "start" + i;
            const endKey = "end" + i;

            if (content[dateKey] && content[startKey] && content[endKey]) {
                result.push({
                    date: date.unformat(content[dateKey]),
                    start: `${content[startKey]}:00`,
                    end: `${content[endKey]}:00`
                });
            }
        }

        return result;
    }

    async function uploadImage(file) {
        const formData = new FormData()

        formData.append('image', file)
        formData.append('folder', 'events')

        try {
            const { data } = await imageUpload.uploadWithFolder(formData)
            setEvents({...events, images_id: [data.id]})
        } catch (error) {
            toast.error('Não foi possível carregar imagem!', {
                position: 'top-center'
            })
        }
    }

    function isValidTags() {
        if (events && events.tags) {
            if (events.tags.length > 3) {
                setTagsError('max');
                return false;
            }

            if (events.tags.length <= 0) {
                setTagsError('min');
                return false;
            }
            
            return true;
        }
    }

    function createPayload(content) {
        const data = content

        if(!data?.link_ticket) {
            delete data.link_ticket
        }

        return {
            address: events.address,
            dates: createDatesPayload(content),
            ...removeDuplicates(content),
            ...events,
            tags: events?.tags?.map(el => el.id),
            classification_id: events.classification_id || eventTypes.free.id
        }
    }

    async function createEvent(content) {
    
        if (!isValidTags()) {
            return;
        }

        const payload = createPayload(content);
       
        setIsLoading(true)
        try {
            setTagsError(false);
            const { data } = await eventsApi.create(payload);

            setEventsList({
                ...eventsList, 
                 data: [...eventsList.data, data]
             })
            toast.success('Evento criado com sucesso!', {
                position: 'top-center'
            })
        } catch (error) {
            toast.error('Não foi possível criar o evento!!', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
            setModalVisible(false)
        }
    }

  return (
         <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)}>
            <ModalWrapper>
                <FormContainer onSubmit={handleSubmit(createEvent)}>
                    
                    <Text name='title'>{create ? 'Criar Evento': 'Editar Evento'}</Text>

                    <BasicInfos register={register} errors={errors} />
                    <ImageInfo onToggleImage={(e) => uploadImage(e)} />
                    <CategoriesInfo error={tagsError} />

                    <AddressInfo trigger={trigger} isEdit={edit} />
                    <ExtraInfo register={register} errors={errors} />
                    
                    { !isLoading ? (
                        <span onClick={() => setTrigger(true)}>
                            <Button
                                marginTop={sizes.intermediary}
                                paddingBottom={sizes.grand}
                                type="submit"
                            >
                                Criar evento
                            </Button>
                        </span>

                    ) : (
                        <Loader />
                    )}
                </FormContainer>
            </ModalWrapper>
         </Modal>
  )
}
const ModalWrapper = styled.div`
    padding: ${sizes.large} ${sizes.none} ${sizes.large} ${sizes.none};
`;

const FormContainer = styled.form`
    height: 100%;
    text-align: center;
`;
