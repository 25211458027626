import styled from 'styled-components'
import React, { useEffect, useState, useRef } from 'react'
import { Row } from 'styled/alignment/Row'
import Text from 'components/_UI/Text';
import EventCard from './EventCard';
import Modal from 'components/_UI/Modal';
import EventDetail from '../detail/EventDetail';
import { useEventsList } from 'context/EventsList';
import eventsApi from 'api/requests/events';
import sizes from 'theme/sizes';
import Loader from 'components/_UI/Loader';
import { toast } from 'react-toastify';
import colors from 'theme/colors';
import fonts from 'theme/fonts';


export default function EventCardResultFilter() {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null)
    const {eventsList, setEventsList} = useEventsList()
    const ref = useRef(null);
    const [ totalEvents ,setTotalEvents] = useState([])   
    const [ currentPage , setCurrentPage ] = useState (1)
    const [ eventsPerPage ,setEventsPerPage ] = useState (null)
    const [totalPages , setTotalPages] = useState()
    const [isLoading , setIsLoading] = useState(false)

    const maxItems = 8;
    const maxLeft = Math.floor(maxItems / 2);
    const maxRight = Math.ceil(maxItems / 2);
    const firstPage = Math.max(currentPage - maxLeft, 1);
    const lastPage = Math.min(currentPage + maxRight, totalPages);

   
    const previousPage = () => {
        if (currentPage !== 1) {
            const number = currentPage - 1
            getEvents(number)
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(totalEvents  / eventsPerPage)) {
            const number = currentPage + 1
            getEvents(number);
        }
    }

    const handlePages = async(e) => {
       const numberPage = e.target.value
       await getEvents(numberPage)
    }

    useEffect(() => {
        if(eventsList?.isFilter) {
            ref.current?.scrollIntoView({ behavior: 'smooth' });
            return
        }
        getEvents()

    },[])
    
    const getEvents = async (number) => {
        const numberPage = Number(number)
        if(numberPage === currentPage) {
            return
        }
        setIsLoading(true)   
        try {
            const { data } = await eventsApi.list(numberPage)
            setCurrentPage(data.meta.current_page);
            setEventsPerPage(data.meta.per_page)
            setTotalPages(data.meta.last_page)
            setTotalEvents(data.meta.total)
            setEventsList(data)

        } catch (error) {
            toast.error('Não foi possível carregar eventos!', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
        }
    }
    
    const handleDetails = (item) => {
        setModalOpen(true)
        setSelectedItem(item)
    }
   

    
    return (
        <CardResultFilter>
                  <Text name='default' type='bold'>{eventsList ? eventsList?.data.length : '0'} Resultado de eventos</Text>
                    <CardContainerResult ref={ref}>
                        {!isLoading && 
                            eventsList ? (
                                eventsList?.data.map((eventsResult, index) => (
                                        <CardContentResult key={index} onClick={() => handleDetails(eventsResult)}>
                                            <EventCard  
                                                data={eventsResult} 
                                            />
                                        </CardContentResult>
                                    ))   
                            ) : (
                                <Loader/>
                            )   
                        }
                   </CardContainerResult>

                   { !isLoading && (
                        totalPages > 1 && (
                            eventsList?.data?.length !== 0 && (
                                !eventsList?.isNotPaginate && (
                                        <ContainerPaginate>
                                            <ContentPaginate>
                                                {totalPages > 1 && (
                                                    <ContainerButton>
                                                        <BtnPageAction 
                                                            previous
                                                            disabled={currentPage !== 1 ? false : true} onClick={() => previousPage()}
                                                        >
                                                            Anterior
                                                        </BtnPageAction>
                                                    </ContainerButton>
                                                )}
                                               
                                               {
                                                    Array.from({length: lastPage - firstPage + 1})
                                                    .map((_, index)  => index + firstPage)
                                                    .map((page) => (
                                                        <ContainerButton>
                                                            <BtnPageNumber
                                                                key={page} 
                                                                value={page}
                                                                onClick={(e) => handlePages(e)}
                                                                active={page === currentPage}
                                                            >
                                                                {page}
                                                            </BtnPageNumber>
                                                        </ContainerButton>   
                                                    ))
                                               }
                                                {totalPages > 1 && (
                                                    <ContainerButton>
                                                        <BtnPageAction next disabled={currentPage !== totalPages ? false : true} onClick={() => nextPage()}>Próxima</BtnPageAction>
                                                    </ContainerButton>
                                                )}
                                            </ContentPaginate> 
                                        </ContainerPaginate>
                                ) 
                           ) 
                        )
                    )
                   }    
            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <EventDetail data={selectedItem} closeModal={() => setModalOpen(false)} />
            </Modal>
        </CardResultFilter>
    )
}
const CardResultFilter = styled.div`
  flex-direction: column;
  display: flex; 
  margin-top: ${sizes.normal};
  @media screen and (min-width: 425px) {
    flex-wrap: wrap;
    display: flex;
  }
`;
const CardContainerResult = styled.div`
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    @media screen and (max-width: 1400px) {
        max-width: 100%;
    }   
`;
const CardContentResult = styled.div`
    cursor: pointer;
`;
const ContainerPaginate = styled(Row)`
    margin-top: ${sizes.immense};
    height: ${sizes.immense};
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ContentPaginate = styled(Row)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ContainerButton = styled(Row)`
    width: ${sizes.immense};
    height: ${sizes.medium};
    display: flex;
    align-items: center;
    justify-content: center;
`;
const BtnPageAction = styled.button`
    height: auto;
    width: 60px;
    background: ${colors.neutralLight};
    border: ${colors.neutralLight};
    color: ${colors.primary};
    border-radius: 3px;
    font-size: ${sizes.little};
    font-weight: ${fonts.weight.bold};
    box-shadow: -2px 3px 5px ${colors.nightLight};
    padding: 5px;
    font-size: ${sizes.little};
    &:hover {
        background: ${colors.primary};
        border:  ${colors.neutral};
        font-size: ${sizes.little};
        color: ${colors.neutral};
        padding: 5px;
    }
    ${({previous}) => previous && `
        margin-right: ${sizes.medium};
    `}
    ${({next}) => next && `
        margin-left: ${sizes.medium};
    `}
`;

const BtnPageNumber = styled.button`
    height: ${sizes.big};
    width: ${sizes.large};
    background: ${colors.neutral};
    border:  ${colors.neutral};
    color: ${colors.primary};
    border-radius: 3px;
    font-size: ${fonts.sizes.small};
    font-weight: ${fonts.weight.bold};
    box-shadow: -2px 3px 5px ${colors.nightLight};
    &:hover {
        background: ${colors.primary};
        border: ${colors.neutralLight};
        color: ${colors.neutral};
    }
    ${({active}) => active && `
        background: ${colors.primary};
        border: ${colors.neutralLight};
        color: ${colors.neutral};
    `}
`