import styled, { css } from 'styled-components';
import fonts from 'theme/fonts';
import sizes from 'theme/sizes';
import marginProps from 'styled/shared-props/margin';
import paddingProps from 'styled/shared-props/padding';

export const StyledTitle = styled.h1`
     font-weight: ${fonts.weight.bold};
     ${marginProps};
     ${paddingProps};

     ${({ color }) => color && css`
        color: ${color}
     `};

     ${({ name }) => name === 'hero' && css`
        font-size: ${fonts.sizes.hero};
     `};

     ${({ name }) => name === 'title' && css`
        font-size: ${fonts.sizes.title};
     `};
     
     ${({ ellipsisAt }) => ellipsisAt && css`
        max-width: ${ellipsisAt};
        white-space: nowrap;                  
        overflow: hidden;
        text-overflow: ellipsis;
     `};
`;

export const StyledSubtitle = styled.h2`
    font-weight: ${fonts.weight.bold};
    ${marginProps};
    ${paddingProps};
    
    ${({ color }) => color && css`
        color: ${color}
     `};

    ${({ name }) => name === 'subtitle' && css`
        font-size: ${fonts.sizes.subtitle};
    `};

    ${({ ellipsisAt }) => ellipsisAt && css`
        max-width: ${ellipsisAt};
        white-space: nowrap;                  
        overflow: hidden;
        text-overflow: ellipsis;
     `};
`;

export const StyledHighlight = styled.h3`
    font-weight: ${fonts.weight.bold};
    ${marginProps};
    ${paddingProps};
    
    ${({ color }) => color && css`
        color: ${color}
     `};

    ${({ name }) => name === 'subtitle' && css`
        font-size: ${fonts.sizes.highlight};
    `};

    ${({ ellipsisAt }) => ellipsisAt && css`
        max-width: ${ellipsisAt};
        white-space: nowrap;                  
        overflow: hidden;
        text-overflow: ellipsis;
    `};
    `;

export const StyledParagraph = styled.p`
    ${marginProps};
    ${paddingProps};
    
    ${({ color }) => color && css`
        color: ${color};
    `};

    ${({ type }) => type === 'italic' && css`
        font-style: ${fonts.style.italic};
    `};

    ${({ type }) => type === 'bold' && css`
        font-weight: ${fonts.weight.bold};
    `};

    ${({ type }) => type === 'normal' && css`
        font-weight: ${fonts.weight.regular};
    `};
    
    ${({ decoration }) => decoration && css`
        text-decoration: ${decoration};
    `};

    ${({ name }) => name === 'default' && css`
        font-size: ${fonts.sizes.default};
    `};
    
    ${({ name }) => name === 'small' && css`
        font-size: ${fonts.sizes.small};
    `};
    
    ${({ name }) => name === 'mini' && css`
        font-size: ${fonts.sizes.mini};
    `};

    ${({ ellipsisAt }) => ellipsisAt && css`
        max-width: ${ellipsisAt};
        white-space: nowrap;                  
        overflow: hidden;
        text-overflow: ellipsis;
    `};
`;

StyledTitle.defaultProps = {
    paddingBottom: sizes.small
}

StyledSubtitle.defaultProps = {
    paddingBottom: sizes.little
}

StyledHighlight.defaultProps = {
    paddingBottom: sizes.tiny
}

StyledParagraph.defaultProps = {
    paddingBottom: sizes.mini
}
