import api from "api";

const tagsSpots = {
    get: () => {
        return api().get('/point-categories')
    },
    create: (payload) => {
        return api().post('/point-categories', payload);
    },
    listSpots: () => {
        return api().get('/point-categories/list');
    },
    delete: (payload) => {
        return api().delete(`/point-categories/${payload}`);
    },
    update: (id, payload) => {
        return api().put(`/point-categories/${id}`, payload);
    },
}

export default tagsSpots;