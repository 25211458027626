import React, { createContext, useState, useContext } from 'react';

const ScopesContext = createContext();

export default function ScopesProvider({ children }) {
    const [scopes, setScopes] = useState('');
    return (
        <ScopesContext.Provider value={{ scopes, setScopes}}>
            {children}
        </ScopesContext.Provider>
    );
}

export function useScopes() {
    const context = useContext(ScopesContext);

    if (!context) throw new Error('useScopes must be used within a ScopesProvider');

    const { scopes, setScopes } = context;
    return { scopes, setScopes };
}