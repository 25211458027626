import api from "api";

const tags = {
    create: (payload) => {
        return api().post('/tags', payload);
    },
    get: () => {
        return api().get('/tags');
    },
    delete: (payload) => {
        return api().delete(`/tags/${payload}`);
    },
    update: (id, payload) => {
        return api().put(`/tags/${id}`, payload);
    },
};
 
export default tags;  