import React from 'react'
import FeatureFlag from './FeatureFlag'

export default function FeatureFlagContent() {
  return (
    <>
      <FeatureFlag />
    </>
  )
}
