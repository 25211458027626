import React, { createContext, useState, useContext } from 'react';

const UsersFilterContext = createContext();

export default function UsersFilterProvider({ children }) {
    const [usersFilter, setUsersFilter] = useState('');

    return (
        <UsersFilterContext.Provider value={{ usersFilter, setUsersFilter }}>
            {children}
        </UsersFilterContext.Provider>
    );
}

export function useUsersFilter() {
    const context = useContext(UsersFilterContext);

    if (!context) throw new Error('useUser must be used within a UserProvider');

    const { usersFilter, setUsersFilter } = context;
    return { usersFilter, setUsersFilter };
}