import React from 'react';
import Content from 'components/main/Content';
import EventsContent from 'modules/events/EventsContent';
import EventsListProvider from 'context/EventsList';
import EventsFilterProvider from 'context/EventsFilter';
import EventsTypesProvider from 'context/EventsTypes';
import TagsProvider from 'context/Tags';
import SelectedTagProvider from 'context/SelectedTag';
import EventsProvider from 'context/Events';

export default function Events() {
    return (
        <EventsListProvider>
            <EventsProvider>
                <EventsFilterProvider>
                    <EventsTypesProvider>
                        <TagsProvider>
                            <SelectedTagProvider>
                                <Content title='Eventos'>
                                    <EventsContent />
                                </Content>
                            </SelectedTagProvider>
                        </TagsProvider>
                    </EventsTypesProvider>
                </EventsFilterProvider>
            </EventsProvider>
        </EventsListProvider>
    );
}
