import React, { createContext, useState, useContext } from 'react';

const FeatureFlagContext = createContext();

export default function FeatureFlagProvider({ children }) {
    const [featureFlag, setFeatureFlag] = useState(null);

    return (
        <FeatureFlagContext.Provider value={{featureFlag, setFeatureFlag}}>
            {children}
        </FeatureFlagContext.Provider>
    );
}

export function useFeatureFlag() {
    const context = useContext(FeatureFlagContext);
    
    if (!context) throw new Error('usefeatureFlag must be used within a featureFlag');
    
    const { featureFlag, setFeatureFlag } = context;
    return { featureFlag, setFeatureFlag };
}