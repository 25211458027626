import colors from "theme/colors";
const eventTypes = {
    free: {
        id: process.env.REACT_APP_EVENT_TYPE_FREE,
        label: 'Gratuito',
        color: colors.primaryLight,
        slug: 'free'
    },
    paid: {
        id: process.env.REACT_APP_EVENT_TYPE_PAID,
        label: 'Pago',
        color: colors.occasionalPurple,
        slug: 'private'
    }
};

export default eventTypes;