import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import tagsNaturalBeautyApi from 'api/requests/tagsNaturalBeauty'
import { toast } from 'react-toastify'
import { useTagsNaturalBeauty } from 'context/TagsNaturalBeauty'
import Table from 'components/_UI/Table'
import Loader from 'components/_UI/Loader'
import { useActionForTables } from 'context/ActionForTables'
import { Row } from 'styled/alignment/Row'
import Button from 'components/_UI/Button'
import sizes from 'theme/sizes'
import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import { StyledForm } from 'styled/UI/StyledForm'
import Fieldset from 'components/_UI/Fieldset'
import fieldValidations from 'helpers/fieldValidations'
import { useForm } from 'react-hook-form'
import colors from 'theme/colors'
import SearchNotFound from 'components/_UI/SearchNotFound'

export default function TagsNaturalBeautyResult() {
  const {tagsNaturalBeauty, setTagsNaturalBeauty} = useTagsNaturalBeauty()
  const {actionForTables, setActionForTables} = useActionForTables()
  const [isLoading, setIsLoading] = useState(false);
  const [modalActionOpen, setModalActionOpen] = useState(false)
  const [nameTag, setNameTag] = useState('')
  const [item, setItem] = useState(null)

  let isDisabled = nameTag === ''

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    getTags()
  }, [])

  const deleteTag = async() => {
    setIsLoading(true)
    try {
        await tagsNaturalBeautyApi.delete(item.id)
        const updateTag = tagsNaturalBeauty.filter(el => el.id !== item.id)
        setTagsNaturalBeauty(updateTag)
        toast.success('Categoria Excluída com sucesso!', {
          position: 'top-center'
        })
    } catch (error) {
        toast.error('Ocorreu um erro inesperado', {
          position: 'top-center'
        })
    } finally {
      setIsLoading(false)
      setModalActionOpen(false)
    }
  }

  const onConfirmAction = async() => {
    if(item.actionSelected === 'delete') {
        await deleteTag()
      return
    }
    if(item.actionSelected === 'edit') {
        await editTag()
      return
    }
   }

   const editTag = async() => {
    setIsLoading(true)
      const payload = {
        id: item.id,
        name: nameTag
      }
      try {
        await tagsNaturalBeautyApi.update(payload.id, payload)
        const updateTags = tagsNaturalBeauty.map((tag => {
          if(tag.id === payload.id) {
            return {
              ...payload, action: ['edit', 'delete']
            }
          }
          return tag
        }))
        setTagsNaturalBeauty(updateTags)
        toast.success('Categoria editada com sucesso!', {
          position: 'top-center'
        })
      } catch (error) {
        toast.error('Ocorreu um erro Inesperado', {
          position: 'top-center'
        })
      } finally {
        setIsLoading(false)
        setModalActionOpen(false)
      }
   }

  const getTags = async() => {
    setIsLoading(true)
    try {
      const {data} = await tagsNaturalBeautyApi.list()
      const tableData = data?.map(el => {
        return {
            id: el.id,
            name: el.name,
            action: ['edit', 'delete']
        }
      })
      setTagsNaturalBeauty(tableData)
      setActionForTables(Object.values(tableData).map(el => el.action))
    } catch (error) {
      toast.error('Ocorreu um erro inesperado', {
        position: 'top-center'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const toggleActionClicked = (item) => {
    setModalActionOpen(true);
    setItem(item)
  }


  return (
    <Container>
       {
          !isLoading ? (
            !tagsNaturalBeauty || tagsNaturalBeauty.length === 0 ? (
              <SearchNotFound />
            ) : (
              tagsNaturalBeauty && actionForTables && (
                <Table
                  rows={tagsNaturalBeauty}
                  columns={['ID', 'Nome', 'Ações']}
                  actionClicked={(item) => toggleActionClicked(item)}
                />
              )
            )
          ) : (
            <Loader />
          )
        }

       {
          modalActionOpen && (
            <Modal isOpen={modalActionOpen} onClose={() => setModalActionOpen(false)}>
              <ModalWrapper>
                <Text
                    name='subtitle'
                  >
                    {
                        item.actionSelected === 'edit'
                            ? 'Editar Categoria'
                            : 'Tem certeza que deseja excluir essa categoria?'
                    }
                </Text>
              {
                item.actionSelected === 'edit' && (
                  <StyledForm onSubmit={handleSubmit(onConfirmAction)}>
                    <Fieldset
                      name='tagName'
                      {...register}
                      value={item.name}
                      onChangeValue={(e) => setNameTag(e)}
                      validations={fieldValidations.name('nome categoria')}
                    />
                    {
                      (errors.tagName?.message) && (
                        <Text color={colors.danger}>{errors.tagName?.message}</Text>
                      )
                    }
                    {
                      !isLoading ? (
                        <Button
                          type='submit'
                          marginTop={sizes.little}
                          variant={isDisabled ? 'disabled' : 'primary'}
                        >
                          Editar
                        </Button>
                      ) : (
                        <Loader />
                      )
                    }
                  </StyledForm>
                )
              }

              {
                item.actionSelected === 'delete' && (
                    <>  
                        {
                            !isLoading ? (
                              <Row marginTop={sizes.tiny}>
                                <Button 
                                    onClick={() => onConfirmAction()}
                                >
                                    Confirmar
                                </Button>

                                <Button 
                                    marginLeft={sizes.tiny} 
                                    onClick={() => setModalActionOpen(false)}
                                >
                                        Cancelar
                                </Button>
                              </Row>
                            ) : (
                                <Loader />
                            )
                        }
                    </>
                )
              }
              </ModalWrapper>
            </Modal>
          )
       }
       
    </Container>
  )
}
const Container = styled(Column)`
  width: 100%;
`;

const ModalWrapper = styled(Column)`
  padding: ${sizes.large} ${sizes.none} ${sizes.large} ${sizes.none};
`