import Button from 'components/_UI/Button';
import Fieldset from 'components/_UI/Fieldset'
import Text from 'components/_UI/Text'
import TextArea from 'components/_UI/TextArea';
import { useEvents } from 'context/Events';
import fieldValidations from 'helpers/fieldValidations';
import masks from 'helpers/masks';
import React, { useState } from 'react'
import styled from 'styled-components';
import { HorizontalCenter } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import sizes from 'theme/sizes';

export default function BasicInfos({ register, errors }) {

    const {events, setEvents} = useEvents()
    
    const deleteDay = async(indexToDelete) => {
        const updatedFields = [...fields];
        updatedFields.splice(indexToDelete, 1);
        setFields(updatedFields);
    }
    
    const [multipleDays, setMultipleDays] = useState(false)
    const [field, setField] = useState({
        date: '',
        start: '',
        end: ''
    })
    const [fields, setFields] = useState([field])

    function renderNewEventDate() {
        return fields.map((el, index) => (
            <React.Fragment key={index}>
                <DaysCountLabel>
                    <Text color={colors.night} name='small' type='bold'>Dia {index + 2}: </Text>
                </DaysCountLabel>
                <Fieldset
                    layout='rounded'
                    placeholder='Data'
                    mask={masks.date}
                    name={`date${index + 2}`}
                    register={register}
                    validations={fieldValidations.date()}
                />

                {
                    errors && Object.entries(errors).map(el => {
                        if (el[0] === `date${index + 2}`) {
                            return (
                                <HorizontalCenter key={index}>
                                    <Text color={colors.danger}> {el[1].message} </Text>
                                </HorizontalCenter>
                            )
                        }
                    })
                }

                <Fieldset
                    layout='rounded'
                    placeholder='Inicio'
                    badgeText='Horas'
                    mask={masks.time}
                    name={`start${index + 2}`}
                    register={register}
                    validations={fieldValidations.time('inicio')}
                />

                {
                    errors && Object.entries(errors).map(el => {
                        if (el[0] === `start${index + 2}`) {
                            return (
                                <HorizontalCenter key={index}>
                                    <Text color={colors.danger}> {el[1].message} </Text>
                                </HorizontalCenter>
                            )
                        }
                    })

                }

                <Fieldset
                    layout='rounded'
                    placeholder='Fim'
                    badgeText='Horas'
                    mask={masks.time}
                    name={`end${index + 2}`}
                    register={register}
                    validations={fieldValidations.time('Fim')}
                />

                {
                    errors && Object.entries(errors).map(el => {
                        if (el[0] === `end${index + 2}`) {
                            return (
                                <HorizontalCenter key={index}>
                                    <Text color={colors.danger}> {el[1].message} </Text>
                                </HorizontalCenter>
                            )
                        }
                    })

                }
            </React.Fragment>
        ))
    }

    function addNewField() {
        setFields([...fields, field])
        renderNewEventDate()
    }

    return (
        <Column marginTop={sizes.little}>
            <Text size='small' type='bold'> Informações basicas</Text>

            <Fieldset
                layout='rounded'
                placeholder='Nome do evento'
                name="name"
                register={register}
                validations={fieldValidations.generic('name', 2, 100)}
            />

            {errors && (
                <HorizontalCenter>
                    <Text color={colors.danger}> {errors.name?.message} </Text>
                </HorizontalCenter>
            )}

            <TextArea
                placeholder='Descrição'
                name='description'
                validations={fieldValidations.description('descrição')}
                register={register}
            />
            {errors && (
                <HorizontalCenter marginTop={'10px'}>
                    <Text color={colors.danger}> {errors.description?.message} </Text>
                </HorizontalCenter>
            )}

            {
                multipleDays && (
                    <DaysCountLabel>
                        <Text color={colors.night} name='small' type='bold'> Dia 1: </Text>
                    </DaysCountLabel>
                )
            }
            <Fieldset
                layout='rounded'
                placeholder='Data'
                mask={masks.date}
                name="date1"
                register={register}
                validations={fieldValidations.date()}
            />

            {errors && (
                <HorizontalCenter>
                    <Text color={colors.danger}> {errors.date1?.message} </Text>
                </HorizontalCenter>
            )}

            <Fieldset
                layout='rounded'
                placeholder='Inicio'
                badgeText='Horas'
                mask={masks.time}
                name="start1"
                register={register}
                validations={fieldValidations.time()}
            />
            {errors && (
                <HorizontalCenter>
                    <Text color={colors.danger}> {errors.start1?.message} </Text>
                </HorizontalCenter>
            )}

            <Fieldset
                layout='rounded'
                placeholder='Fim'
                badgeText='Horas'
                mask={masks.time}
                name={'end1'}
                register={register}
                validations={fieldValidations.time('termino')}
            />
            {errors && (
                <HorizontalCenter>
                    <Text color={colors.danger}> {errors.end1?.message} </Text>
                </HorizontalCenter>
            )}

            <Row marginLeft={sizes.mini} marginTop='-6px'>
                <input
                    type='checkbox'
                    value={multipleDays}
                    checked={multipleDays}
                    onChange={() => setMultipleDays(!multipleDays)}
                />
                <ContainerTextCheckbox>
                    <Text
                        size='small'
                        color={colors.night}
                        paddingTop={sizes.tiny}
                        paddingLeft={sizes.nano}
                    >
                        O evento tem mais de um dia de duração
                    </Text>
                </ContainerTextCheckbox>
            </Row>

            {
                multipleDays && (
                    <AddFieldContainer>
                        {renderNewEventDate()}
                        {fields.length > 0 && (
                            <Button onClick={() => deleteDay(fields.length - 1)}> Excluir </Button>
                        )}
                        <Button variant='secondary' onClick={() => addNewField()}> Adicionar campo </Button>
                    </AddFieldContainer>
                )
            }

                <Fieldset
                    marginTop='10px'
                    layout='rounded'
                    placeholder='valor do desconto'
                    onChangeValue={(e) => setEvents({...events, discount: e})}
                />
                
                <Row width='80px'>
                    <Row marginLeft={sizes.mini} marginTop='-6px'>
                        <input type='checkbox' onChange={(e) => {
                            if (e.target.checked) {
                                setEvents({...events, discount_type: 'percent'})
                            }
                        }} 
                            />
                        <Text size='small' color={colors.night} paddingTop={sizes.tiny} paddingLeft={sizes.nano}> % </Text>
                    </Row>

                    <Row marginLeft={sizes.mini} marginTop='-6px'>
                        <input type='checkbox' onChange={(e) => {
                            if (e.target.checked) {
                                setEvents({...events, discount_type: 'value'})
                            }
                        }} />
                        <Text size='small' color={colors.night} paddingTop={sizes.tiny} paddingLeft={sizes.nano}> R$ </Text>
                    </Row>
                </Row>
        </Column>
    )
}


const AddFieldContainer = styled(Column)`
    padding-top: ${sizes.tiny};
`;

const DaysCountLabel = styled.div`
    text-align: left;
`;
const ContainerTextCheckbox = styled.div`
    @media screen and (max-width: 600px) {
        width: 300px
    }
`
