import React, { createContext, useState, useContext } from 'react';

const SelectedTag = createContext();

export default function SelectedTagProvider({ children }) {
    const [selectedTag, setSelectedTag] = useState(null);

    return (
        <SelectedTag.Provider value={{selectedTag, setSelectedTag}}>
            {children}
        </SelectedTag.Provider>
    );
}

export function useSelectedTag() {
    const context = useContext(SelectedTag);
    
    if (!context) throw new Error('useSelectedTag must be used within a SelectedTag');
    
    const { selectedTag, setSelectedTag } = context;
    return { selectedTag, setSelectedTag };
}