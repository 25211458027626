import React, { useState, useEffect } from 'react';
import { Column } from 'styled/alignment/Column';
import styled from 'styled-components';
import Fieldset from 'components/_UI/Fieldset';
import sizes from 'theme/sizes';
import colors from 'theme/colors';
import Button from 'components/_UI/Button';
import date from 'helpers/date';
import { FullyCentered } from 'styled/alignment/Center';
import masks from 'helpers/masks';
import { StyledForm } from 'styled/UI/StyledForm';
import { useUsersFilter } from 'context/UsersFilter';
import { useForm } from 'react-hook-form';
import userApi from 'api/requests/user';
import rolesApi from 'api/requests/roles';
import { toast } from 'react-toastify';


export default function UsersFilters() {

  const { usersFilter, setUsersFilter } = useUsersFilter()

  const [role, setRole] = useState('')
  const [selectValue, setSelectValue] = useState('')

  useEffect(() => {
    getRoles()
  }, []);

  const {
    register,
    handleSubmit,
  } = useForm();

  const getRoles = async () => {
    try {
      const { data } = await rolesApi.get()
      setRole(data.data)
    } catch (error) {
      toast.error('Erro não foi possível encontrar nenhum usuário!')
    }
  }

  const filterItems = async ({term, created_at}) => {
    const payload = {
      term: term || null,
      created_at: created_at instanceof Date ? date.unformat(created_at) : null,
      role: selectValue || null
    }
    setUsersFilter({...usersFilter, isFilter: true, loading: true})
    try {
      const {data} = await userApi.filter(payload)
      if (data.data.length === 0) {
        setUsersFilter({...data, loading: false, isFilter: true, isNotFound: true})
      } else {
        setUsersFilter({...data, loading: false, isFilter: true, isNotFound: false})
      }
    } catch (error) {
      setUsersFilter({...usersFilter, loading: false, isFilter: true})
    } 
  }

  return (
    <>
      <Column>
        <StyledForm onSubmit={handleSubmit(filterItems)}>
          <ContentFilterUsers>
            <InputContainer>
              <Fieldset
                placeholder='Nome, email, username, etc'
                name='term'
                register={register}
              />
            </InputContainer>
            <InputContainer>
              <Fieldset
                placeholder="Data de cadastro"
                mask={masks.date}
                name='created_at'
                register={register}
              />
            </InputContainer>
            <InputContainer>
              <SelectRole
                name='role'
                defaultValue={'Selecione uma permissão'}
                onChange={e => setSelectValue(e.target.value)}

              >
                <option name='selected' disabled >Selecione uma permissão</option>
                  {Object.values(role).map((el) => (
                    <option value={el.id} key={el.name}>
                      {el.name}
                    </option>
                  ))}
              </SelectRole>
            </InputContainer>
            <ContainerButton>
              <Button size='small' type='submit' >Filtrar</Button>
            </ContainerButton>
          </ContentFilterUsers>
        </StyledForm>
      </Column>
    </>
  )
}

const InputContainer = styled.div`
    width: 200px;
    margin-right: ${sizes.normal};
    display: flex;
    height: ${sizes.gigantic};
    align-items: center;
    justify-content: space-between;
`;
const SelectRole = styled.select`
    border: ${sizes.none};
    border-bottom: ${sizes.quark} solid ${colors.nightLight};
    margin-bottom: ${sizes.mini};
    padding: ${sizes.quark};
    font-family: 'Roboto', sans-serif;
    background-color: transparent;
    outline: transparent;
    color: ${colors.night};
`;
const ContainerButton = styled(FullyCentered)`
  width: 200px;
`;

const ContentFilterUsers = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 820px) {
    margin-top: 20px;
    flex-direction: column;
  }
`
