import Button from 'components/_UI/Button';
import Fieldset from 'components/_UI/Fieldset';
import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import fieldValidations from 'helpers/fieldValidations';
import React, {useState} from 'react'
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { HorizontalCenter } from 'styled/alignment/Center';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import featureFlagApi from 'api/requests/featureFlag';
import { toast } from 'react-toastify';
import Loader from 'components/_UI/Loader';
import { useFeatureFlag } from 'context/FeatureFlag';
import { useActionFlag } from 'context/ActionFlag';


export default function CreateFeatureFlagForm({setModalVisible, modalVisible}) {
  const {setActionFlag} = useActionFlag()
  
  const [isLoading, setIsLoading] = useState(false)
  const {featureFlag, setFeatureFlag} = useFeatureFlag()

  const createFeatureFlag = async({ nameFeature }) => {
      setIsLoading(true)
      const payload = {
        name: nameFeature,
        enabled: false
      }
      
      try {
        const {data} = await featureFlagApi.create(payload)
        const newFeatureFlag = {id: data.id, name: data.name, enabled: data.enabled ? 'Ativo' : 'Desativado', action: ['active', 'disabled', 'delete']}
         
        const updatedFeatureFlag = [...featureFlag, newFeatureFlag]
        setFeatureFlag(updatedFeatureFlag)
        const newData = updatedFeatureFlag?.map(el => {
          return {
            id: el.id,
            name: el.name,
            enabled: el.enabled ? 'Ativo' : 'Desativado',
            action: ['active', 'disabled', 'delete']
        }

        })
        setActionFlag(Object.values(newData).map(el => el.action))
        toast.success('Feature Flag criada com sucesso!', {
          position: 'top-cente'
        })
      } catch (error) {
        toast.error('Não foi possível criar feature flag', {
          position: 'top-center'
        })
      } finally {
        setIsLoading(false)
        setModalVisible(false)
      }
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  return (
    <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)}>
      <ModalWrapper>
        <FormContainer onSubmit={handleSubmit(createFeatureFlag)}>

          <Text 
            name='subtitle'
          >
            Criar Feature Flag
          </Text>

          <Fieldset
            name='nameFeature'
            layout='rounded'
            placeholder='Nome'
            marginTop={sizes.medium}
            register={register}
            validations={fieldValidations.name()}
          />

          {
            (errors.name?.message) && (
                <HorizontalCenter>
                    <Text color={colors.danger}> {errors.name?.message} </Text>
                </HorizontalCenter>
            )
          }

        {
          !isLoading ? (
            <Button
              marginTop={sizes.tiny}
              type='submit'
            >
              Criar
            </Button>
          ) : (
            <Loader />
          )
        }
        </FormContainer>
      </ModalWrapper> 
    </Modal>
  )
}

const ModalWrapper = styled.div`
  padding: ${sizes.large} ${sizes.none} ${sizes.large} ${sizes.none};
`;
const FormContainer = styled.form`
  height: 100%;
  text-align: center;
`;