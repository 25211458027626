import React, { useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import Text from 'components/_UI/Text';
import { Row } from 'styled/alignment/Row';
import UserCard from './UserCard';
import sizes from 'theme/sizes';
import Modal from 'components/_UI/Modal';
import UserCardDetails from './UserCardDetails';
import userApi from 'api/requests/user';
import { useUsersFilter } from 'context/UsersFilter';
import { toast } from 'react-toastify';
import Loader from 'components/_UI/Loader';
import { FullyCentered } from 'styled/alignment/Center';
import Paginate from 'components/_UI/Paginate';
import SearchNotFound from 'components/_UI/SearchNotFound';

export default function UserCardResultFilter() {
  const [modalOpen, setModalOpen] = useState(false);
  const ref = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

    const [ totalUsers ,setTotalUsers] = useState([])   
    const [ currentPage , setCurrentPage ] = useState (1)
    const [ usersPerPage ,setUsersPerPage] = useState (null)
    const [totalPages , setTotalPages] = useState()
  

  const { usersFilter, setUsersFilter } = useUsersFilter();
  
  const handleDetails = (item) => {
    setModalOpen(true);
    setSelectedItem(item);
  };

  useEffect(() => {
    if (usersFilter.isFilter) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      return
    }

    getUsers();
  }, []);


  const getUsers = async (numberPage) => {   
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    const number = Number(numberPage)
    if(number === currentPage) {
      return
    }
    setIsLoading(true)
    try {
      const { data } = await userApi.get(numberPage);
      setCurrentPage(data.meta.current_page);
      setUsersPerPage(data.meta.per_page)
      setTotalPages(data.meta.last_page)
      setTotalUsers(data.meta.total)
      setUsersFilter(data);
    } catch (error) {
      toast.error('Não foi possível carregar nenhum usuário!', {
        position: 'top-center',
      });
    } finally {
      setIsLoading(false)
    }
    setIsLoading(false)
  };

  return (
    <CardContainer ref={ref}>
      <Text name="default" type="bold">
        {usersFilter?.data?.length ? usersFilter?.data?.length : '0'} usuário(s) encontrado(s)
      </Text>
      {!isLoading ? (
        <CardContainerResult ref={ref}>
          { usersFilter.data ? (
            usersFilter?.data?.map((userResult) => (
                <CardContentResult
                  key={userResult.id} 
                  onClick={() => handleDetails(userResult)}
                >
                  <UserCard 
                      data={userResult} 
                    />
                </CardContentResult>
              
              ))
          ) : (
            <SearchNotFound />
          )
          }
          {
            usersFilter?.data?.length !== 0 && (
              totalPages > 1 && (
                <Paginate 
                  currentPage={currentPage} 
                  totalUsers={totalUsers}
                  totalPages={totalPages} 
                  usersPerPage={usersPerPage} 
                  onPaginate={getUsers}
                />
              )
            )
          }
        </CardContainerResult>
      ) : (
        <FullyCentered>
          <LoaderContainer>
            <Loader 
              size="large" 
            />
          </LoaderContainer>
        </FullyCentered>
      )}
      <Modal
        size="large"
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <UserCardDetails
          data={selectedItem}
          closeModal={() => setModalOpen(false)}
        />
      </Modal>
    </CardContainer>
  );
}
const CardContainer = styled(Column)`
  margin-top: ${sizes.normal};
`;
const CardContainerResult = styled(Row)`
  width: 100%;
  overflow-y: unset;
  flex-wrap: wrap;
  @media screen and (max-width: 820px){
      justify-content: center;
  }
`;
const CardContentResult = styled.div`
  cursor: pointer;
`;
const LoaderContainer = styled(Column)`
  width: 100%;
  height: 100vh;
`;