import React, { createContext, useState, useContext } from 'react';

const TagsTouristSpots = createContext();

export default function TagsTouristSpotsProvider({ children }) {
    const [tagsTouristSpots, setTagsTouristSpots] = useState('');
    return (
        <TagsTouristSpots.Provider value={{ tagsTouristSpots, setTagsTouristSpots}}>
            {children}
        </TagsTouristSpots.Provider>
    );
}

export function useTagsTouristSpots() {
    const context = useContext(TagsTouristSpots);

    if (!context) throw new Error('useTagsTouristSpots must be used within a useTagsTouristSpots');

    const { tagsTouristSpots, setTagsTouristSpots } = context;
    return { tagsTouristSpots, setTagsTouristSpots };
}