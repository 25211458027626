import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import tagsSpotsApi from 'api/requests/tagsSpots'
import { useTagsTouristSpots } from 'context/TagsTouristSpots'
import Table from 'components/_UI/Table'
import Loader from 'components/_UI/Loader'
import Modal from 'components/_UI/Modal'
import sizes from 'theme/sizes'
import Text from 'components/_UI/Text'
import { StyledForm } from 'styled/UI/StyledForm'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Button from 'components/_UI/Button'
import { Row } from 'styled/alignment/Row'
import Fieldset from 'components/_UI/Fieldset'
import fieldValidations from 'helpers/fieldValidations'
import { FullyCentered } from 'styled/alignment/Center'
import colors from 'theme/colors'
import SearchNotFound from 'components/_UI/SearchNotFound'
import { useActionForTables } from 'context/ActionForTables'

export default function TagsSpotsResult() {
    const {tagsTouristSpots, setTagsTouristSpots} = useTagsTouristSpots()
    const {actionForTables, setActionForTables} = useActionForTables()
    const [item, setItem] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [modalActionOpen, setModalActionOpen] = useState(false)
    const [nameTag, setNameTag] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        getTags()
    }, [])
    
    const getTags = async() => {
        setIsLoading(true)
        try {
            const { data } = await tagsSpotsApi.get()
            const tableData = data?.data?.map(el => {
                return {
                    id: el.id,
                    name: el.name,
                    action: ['edit', 'delete']
                }
            })
           setTagsTouristSpots(tableData)
           setActionForTables(Object.values(tableData).map(el => el.action))
        } catch (error) {
            toast.error('Ocorreu um erro inesperado', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
        }
    }

    const toggleActionClicked = (item) => {
        setModalActionOpen(true);
        setItem(item)
    }

    const onConfirmAction = async () => {
        if (item.actionSelected === 'delete') {
            await deleteTag()
            return
        }
        if (item.actionSelected === 'edit') {
            await editTag()
            return
        }
    }
    const editTag = async() => {
        setIsLoading(true)
        
        const payload = {
          id:item.id,
          name: nameTag,
        };
        try {
            await tagsSpotsApi.update(`${payload.id}`, payload)
            const updatedTags = tagsTouristSpots.map((tag => {
                if(tag.id === payload.id) {
                    return {
                        ...payload, action: ['edit', 'delete']
                    }
                }
                return tag
            }))
            setTagsTouristSpots(updatedTags)
            toast.success('Categoria editada com sucesso!', {
                position: 'top-center'
            })
        } catch (error) {
            toast.error('Ocorreu um erro inesperado', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
            setModalActionOpen(false)
        }
    }

    const deleteTag = async() => {
        setIsLoading(true)
        const id = item.id
        try {
            await tagsSpotsApi.delete(id)
            setTagsTouristSpots(tags => tags.filter(tag => tag.id !== id))
            toast.success('Categoria deletada com sucesso!', {
                position: 'top-center'
            })
        } catch (error) {
            toast.error('Um erro inesperado aconteceu', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
            setModalActionOpen(false)
        }
    }

  return (
    <Container>
        {
            !isLoading ? (
                !tagsTouristSpots || tagsTouristSpots.length === 0 ? (
                    <SearchNotFound />
                ) : (
                    tagsTouristSpots && actionForTables && (
                        <Table
                            rows={tagsTouristSpots}
                            columns={['ID', 'Nome', 'Ações']}
                            actionClicked={(item) => toggleActionClicked(item)}
                        />
                    )
                )
            ) : (
                <Loader />
            )
        }

        {
            modalActionOpen && (
                <Modal isOpen={modalActionOpen} onClose={() => setModalActionOpen(false)}>
                    <Text
                        name='subtitle'
                    >
                        {
                            item.actionSelected === 'edit'
                                ? 'Editar Categoria'
                                : 'Tem certeza que deseja excluir essa categoria?'
                        }

                    </Text>
                    <ModalWrapper>
                            {
                                item.actionSelected === 'edit' && (
                                    <StyledForm onSubmit={handleSubmit(onConfirmAction)}>
                                        <ContentAction>
                                            <Fieldset 
                                                marginTop={sizes.tiny}
                                                name='tagName'
                                                onChangeValue={(e) => setNameTag(e)}
                                                {...register}
                                                value={item.name}
                                                defaultValues={item.name}
                                                validations={fieldValidations.name()}
                                            />
                                            {
                                                errors.tagName?.message && (
                                                    <FullyCentered textAlign='center'>
                                                        <Text 
                                                            color={colors.danger}
                                                        > 
                                                            Preencha corretamente o campo! 
                                                        </Text>
                                                    </FullyCentered>
                                                )
                                            }
                                            {
                                                !isLoading ? (
                                                    <Button
                                                        type='submit'
                                                        marginTop={sizes.little}
                                                    >
                                                        Editar
                                                    </Button>

                                                ) : (
                                                    <Loader />
                                                )
                                            }
                                        </ContentAction>
                                     </StyledForm>   
                                )
                            }


                        {
                            item.actionSelected === 'delete' && (
                                <>  
                                    {
                                        !isLoading ? (
                                        <Row>
                                            <Button 
                                                onClick={() => onConfirmAction()}
                                            >
                                                Confirmar
                                            </Button>
        
                                            <Button 
                                                marginLeft={sizes.tiny} 
                                                onClick={() => setModalActionOpen(false)}
                                            >
                                                    Cancelar
                                            </Button>
                                        </Row>
                                        ) : (
                                            <Loader />
                                        )
                                    }
                                </>
                            )
                        }
                    </ModalWrapper>
                </Modal>
            )
        }

    </Container>
  )
}

const Container = styled(Column)`
    width: 100%;
`;

const ModalWrapper = styled.div`
    padding: ${sizes.large} ${sizes.none} ${sizes.large} ${sizes.none};
`;

const ContentAction = styled(Column)`
    width: 100%;
    display: flex;
    align-items: center;
`;