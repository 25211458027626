import React, { createContext, useState, useContext } from 'react';

const ActionForTablesContext = createContext();

export default function ActionForTablesProvider({ children }) {
    const [actionForTables, setActionForTables] = useState('');
    return (
        <ActionForTablesContext.Provider value={{ actionForTables, setActionForTables}}>
            {children}
        </ActionForTablesContext.Provider>
    );
}

export function useActionForTables() {
    const context = useContext(ActionForTablesContext);

    if (!context) throw new Error('useActionForTables must be used within a actionForTables');

    const { actionForTables, setActionForTables } = context;
    return { actionForTables, setActionForTables };
}