import Text from 'components/_UI/Text'
import React from 'react'
import styled, { css } from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import breakpoints from 'theme/breakpoints'
import colors from 'theme/colors'
import eventTypes from 'theme/eventTypes'
import sizes from 'theme/sizes'

export default function CardTouristSpots({data}) {
  return (
    <CardContainer>
        <ImageContainer>
            {data?.photo &&  data?.photo ? (
                <CardImage src={data?.photo} />
        ) : (
                <CardTextWrapper category={data?.classification?.name}>
                    <Text
                        color={colors.neutralLight}
                        type='bold'
                        name='hero'
                    >
                        {data?.name?.charAt(0).toUpperCase()}
                    </Text>
                </CardTextWrapper>
            )}

        </ImageContainer>
        <Text
            name='small'
            type='bold'
            marginTop='6px'
            ellipsisAt='95%'
            paddingBottom={'2px'}
        >
           {data?.name}
        </Text>
        {/* <Text
            name='small'
            color={colors.nightLight}
            ellipsisAt='95%'
            paddingBottom={'4px'}
        >
            {`${data?.address?.street?.name} ${data?.address.number ? `${data?.address.number}` : ''}, ${data?.address?.street?.district} - ${data?.address?.street?.city?.name} - ${data?.address?.street?.city?.state?.uf}  `}
        </Text>
        <Row>
            <CategoryCircle category={data?.classification.name} />
            &nbsp;
            <Text name='mini'
                color={colors.nightLight}
                ellipsisAt='95%'
            >
                {data?.tags?.map(item => `#${item.name} `)}
            </Text>
        </Row> */}
    </CardContainer>
  )
}
const CardContainer = styled(Column)`
    width: 188px;
    cursor: pointer;
    transition: all ease-in-out .25s;
    margin: 10px 10px 10px 10px;
    border-radius: 10px;
    box-shadow: 2px 8px 10px #ccc;        
    padding: 6px;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 210px;
        margin: 10px 10px 10px 10px;
        border-radius: 10px;
        box-shadow: 2px 8px 10px #ccc;        
        padding: 6px;
    }

    &:hover {
        transform: scale(1.01);
    }
    &:nth-child(1) {
        margin-left: ${sizes.none};
    };
`
const ImageContainer = styled.div`
    display: flex;
    width: 100%;
    height: 85px;

    @media screen and (min-width: ${breakpoints.lg}) {
        height: 114px;
    }
    
`;
const CardTextWrapper = styled(FullyCentered)`
    width: 100%;
    text-align: center;
    padding: 20px;
    border-radius: 4px;

    ${({ category }) => category === 'free' && css`
        background: ${eventTypes.free.color};
    `};

    ${({ category }) => category === 'private' && css`
        background: ${eventTypes.paid.color};
    `};

`;
const CardImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
`;

const CategoryCircle = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 30px;
    ${({ category }) => category === 'free' && css`
        background: ${eventTypes.free.color};
    `};
    
    ${({ category }) => category === 'private' && css`
        background: ${eventTypes.paid.color};
    `};
`;

