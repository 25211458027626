import paths from "routes/paths";

const unauthorized = (api) => {
    api.interceptors.request.use((config) => config);

    api.interceptors.response.use((response) => response,
        (error) => {
            if (error.response.status === 401) {
                localStorage.clear();

                if (window.location.pathname !== paths.login) {
                    window.location.reload();
                }
            };

            return Promise.reject(error);
        });
};

export default unauthorized;