import React from "react"
import Text from "./Text"
import { FullyCentered } from "styled/alignment/Center"
import sizes from "theme/sizes"
import colors from "theme/colors"
import Icon from "./Icon"
import styled from "styled-components"

export default function SearchNotFound() {
    return (
        <Container>
            <Content>
                <Icon name='search-nothing' color={colors.nightLight} marginBottom={sizes.grand} />
                <FullyCentered width='100%'>
                    <Text name='subtitle' color={colors.nightLight}>Nenhum resultado encontrado</Text>
                </FullyCentered>
            </Content>
        </Container>
    )    
};

const Container = styled(FullyCentered)`
    padding-top: 20px;
    width: 100%; 
    `

const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: 150px;

    @media screen and (max-width: 720px) {
        align-items: center;
        height: 150px;
        display: flex;
        justify-content: center;
        text-align: center;
        
    }
`