import api from "api";

const scopes = {
    get: () => {
        return api().get('/scopes')
    },
    create: (payload) => {
        return api().post('/scopes', payload)
    }
   
};
 
export default scopes;  