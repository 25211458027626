import React, {useState} from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import sizes from 'theme/sizes'
import CardRoles from './CardRoles'
import DetailsRoles from '../detail/DetailsRoles';

export default function Roles({roles}) {
    const [visibleDetails, setVisibleDetails] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)

    const handleDetails = (item) => {
        setVisibleDetails(true)
        setSelectedItem(item)
    }
  return (
    <Container>
        <Content>
            {
                Object.values(roles).map((el) => (
                    <ContentRoles
                        onClick={() => handleDetails(el)}
                    >
                        <CardRoles data={el} />
                    </ContentRoles>
                ))
            }
        </Content>
        {
            visibleDetails && 
                <DetailsRoles visibleDetails={visibleDetails} data={selectedItem} setVisibleDetails={setVisibleDetails}/>
        }
    </Container>
  )
}

const Container = styled(Column)`
    width: 100%;
    margin-top: ${sizes.medium};
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const ContentRoles = styled.div`
    cursor: pointer;
`
