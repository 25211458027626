import React from "react"
import Icon from "./Icon"
import styled from "styled-components";
import colors from "theme/colors";
import PropTypes from 'prop-types';
import buttons from 'theme/buttons';


import sizes from "theme/sizes";

export default function ButtonAdd({ children, onClick, variant }) {
    return (
        <>
            <ButtonContent
              onClick={(e) => onClick && onClick(e)}
              variant={variant}
              disabled={variant === 'disabled'}
             >
                <Content>
                    <Icon name='add'/>
                </Content>
                {children}
            </ButtonContent>
        </>
    )
}
ButtonAdd.propTypes = {
    variant: PropTypes.oneOf([
        ...buttons.variant
    ]),
};
const ButtonContent = styled.button`
    background: ${colors.primaryDark};
    padding: ${sizes.little} ${sizes.big};
    display: flex;
    align-items: center;
    color: ${colors.neutral};
    border-radius: ${sizes.immense};
    font-weight: bold;
    cursor: pointer;
    transition: all ease-in-out .2s;
    
    &:hover {
        transform: scale(1.1);
    } 
`
const Content = styled.div`
    width: ${sizes.normal};
    height: ${sizes.normal};
    margin-right: ${sizes.micro};
`;