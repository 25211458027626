import Button from 'components/_UI/Button';
import Fieldset from 'components/_UI/Fieldset';
import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text';
import fieldValidations from 'helpers/fieldValidations';
import React, {useState} from 'react'
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { StyledForm } from 'styled/UI/StyledForm';
import sizes from 'theme/sizes';
import tagsSpotsApi from 'api/requests/tagsSpots';
import { useTagsTouristSpots } from 'context/TagsTouristSpots';
import { toast } from 'react-toastify';
import Loader from 'components/_UI/Loader';
import { useActionForTables } from 'context/ActionForTables';

export default function CreateTagsForm({modalVisible, setModalVisible}) {
    const [isLoading, setIsLoading] = useState(false)
    const {setActionForTables} = useActionForTables()
    const {tagsTouristSpots, setTagsTouristSpots} = useTagsTouristSpots()
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();


    const createTagsSpots = async({nameTagSpots}) => {
        setIsLoading(true)
        const payload = {
            name: nameTagSpots
        }
        try {
            const { data } = await tagsSpotsApi.create(payload)
            const newContent = {
                id: data.id, name: data.name, action: ['edit', 'delete']
            }
            const updatedTags = [...tagsTouristSpots, newContent];
            setTagsTouristSpots(updatedTags);
            const newData = updatedTags?.map(el => {
                return {
                    id: el.id,
                    name: el.name,
                    action: ['edit', 'delete']
                }
            })
            setActionForTables(Object.values(newData).map(el => el.action))
            toast.success('Categoria criada com sucesso!', {
                position: 'top-center'
            })
        } catch (error) {
            toast.error('Um erro inesperado aconteceu!', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
            setModalVisible(false)
        }
    }
  return (
    <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)}>
        <ModalWrapper>
            <Text name='subtitle'>
                Criar Categoria
            </Text>
            <StyledForm onSubmit={handleSubmit(createTagsSpots)}>
                <Fieldset 
                    marginTop={sizes.tiny}
                    placeholder='Nome da categoria'
                    register={register}
                    name='nameTagSpots'
                    validations={fieldValidations.name()}
                />
                { !isLoading ? (
                    <Button 
                        type='submit' 
                        marginTop='12px'
                    > 
                        Criar categoria
                    </Button>
                ) : (
                    <Loader />
                )}
            </StyledForm>
        </ModalWrapper>
    </Modal>
  )
}
const ModalWrapper = styled.div`
    padding: ${sizes.large} ${sizes.none} ${sizes.large} ${sizes.none};
`;
