import Text from 'components/_UI/Text'
import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import CardTouristSpots from './card/CardTouristSpots'
import sizes from 'theme/sizes'
import Modal from 'components/_UI/Modal'
import TouristSpotsDetail from './detail/TouristSpotsDetail'
import touristSpotsApi from 'api/requests/touristSpots'
import { useTouristSpots } from 'context/TouristSpots'
import Loader from 'components/_UI/Loader'
import { toast } from 'react-toastify'
import SearchNotFound from 'components/_UI/SearchNotFound'

export default function TouristSpotsResult() {
    const {touristSpots, setTouristSpots} = useTouristSpots()
    const [modalOpen, setModalOpen] = useState(false)
    useEffect(() => {
        getTouristSpots()
    },[])
    const [isLoading, setIsloading] = useState(false)
    const [item, setItem] = useState()

    const getTouristSpots = async() => {
        setIsloading(true)
        try {
            const {data} = await touristSpotsApi.get()
            setTouristSpots(data)
            
        } catch (error) {
            toast.error('Ocorreu um erro inesperado', {
                position: 'top-center'
            })
        } finally {
            setIsloading(false)
        }
    }

    const renderDetail = async(touristSpots) => {
        setIsloading(true)
        try {
            const { data: res } = await touristSpotsApi.detail(touristSpots.slug)
            setModalOpen(true)
            setItem(res.data) 
        } catch (error) {
            toast.error('Ocorreu um erro inesperado', {
                position: 'top-center'
            })
        } finally {
            setIsloading(false)
        }
    }
    


  return (
    <>
        <ContainerTouristSpots>
            <Text type='bold'>{touristSpots ? touristSpots.data.length : '0'} Resultados de Pontos Turísticos</Text>
            <Content>
                { !isLoading ? (
                    touristSpots?.data?.length !== 0 ? (
                        touristSpots?.data?.map((el) => (
                            <Container key={el.id} onClick={(e) => renderDetail(el)}>
                                <CardTouristSpots data={el} />
                            </Container>
                        ))
                    ) : ( 
                        <SearchNotFound />
                    )
                ) : (
                        <Loader />
                    )
                }
            </Content>
            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                {

                    item && <TouristSpotsDetail item={item} closeModal={() => setModalOpen(false)}/>
                }
            </Modal>
        </ContainerTouristSpots>
    </>
  )
}

const ContainerTouristSpots = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${sizes.normal};
`;

const Content = styled.div`
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    @media screen and (max-width: 1400px) {
        max-width: 100%;
    }   
`
const Container = styled.div`
    cursor: pointer;
`