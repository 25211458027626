import Text from "components/_UI/Text";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "components/_UI/Modal";
import { Column } from "styled/alignment/Column";
import Button from "components/_UI/Button";
import ModalTagContent from "../ModalTagContent";
import tagsApi from "api/requests/tags";
import Table from "components/_UI/Table";
import { Row } from "styled/alignment/Row";
import sizes from "theme/sizes";
import Fieldset from "components/_UI/Fieldset";
import colors from "theme/colors";
import { toast } from "react-toastify";
import Loader from "components/_UI/Loader";
import { FullyCentered } from "styled/alignment/Center";
import { StyledForm } from "styled/UI/StyledForm";
import { useForm } from "react-hook-form";
import fieldValidations from "helpers/fieldValidations";
import ButtonAdd from "components/_UI/ButtonAdd";
import { useTags } from "context/Tags";
import SearchNotFound from "components/_UI/SearchNotFound";
import { useActionForTables } from "context/ActionForTables";

export default function TagCardResult() {
    const { tags, setTags } = useTags([])
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenAdd, setModalOpenAdd] = useState(false);
    const [item, setItem] = useState(null)
    const {actionForTables, setActionForTables} = useActionForTables()
    const [isLoading, setIsLoading] = useState(false);
    const [nameTag, setNameTag] = useState('');


    useEffect(() => {
        getTags()
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({});

    const getTags = async () => {
        setIsLoading(true)
        try {
            const { data } = await tagsApi.get()
            const tableData = data.map(el => {
                return {
                    id: el.id,
                    name: el.name,
                    count: el.events_count,
                    is_active: el.is_active ? 'Ativa' : 'Desativada',
                    action: ['active', 'disabled','edit', 'delete']
                }
            })
            setTags(tableData);
            setActionForTables(Object.values(tableData).map(el => el.action))
        } catch (error) {
            toast.error('Erro não foi possível carregar as cetegorias', {
                position: "top-center",
                autoClose: 5000,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const onConfirmAction = async () => {
        if (item.actionSelected === 'delete') {
            await deleteTag()
            return
        }
        if (item.actionSelected === 'edit') {
            await editTag()
            return
        }
        if (item.actionSelected === 'disabled') {
            await disabledTag()
            return
        }
        if (item.actionSelected === 'active') {
            await activatingTag()
            return
        }
    }
    const disabledTag = async() => {
        setIsLoading(true)
        const id = item.id
        const payload = {
            name: item.name,
            is_active: false
        }
        try {
            await tagsApi.update(`${id}`, payload)
            
            const updateTag = tags.map((tag) => {
                if(tag.id === id) {
                    return {...payload, id: id, name: payload.name, is_active: payload.is_active ? 'Ativa' : 'Desativada', action: ['active', 'disabled','edit', 'delete']}
                }
                return tag
            })
            setTags(updateTag)
            setActionForTables(Object.values(updateTag).map(el => el.action))
            toast.success('Categoria desativada com sucesso!', {
                position: 'top-center'
            });
        } catch (error) {
            toast.error('Um erro inesperado aconteceu!', {
                position: 'top-center'
            });
        } finally {
            setIsLoading(false)
            setModalOpen(false)
        }
    }

    const activatingTag = async() => {
        setIsLoading(true)
        const id = item.id
        const payload = {
            name: item.name,
            is_active: true
        }
        try {
            await tagsApi.update(`${id}`, payload)
            const updateTag = tags.map((tag) => {
                if(tag.id === id) {
                    return {...payload, id: id, name: payload.name, is_active: payload.is_active ? 'Ativa' : 'Desativada', action: ['active', 'disabled','edit', 'delete']}
                }
                return tag;
            })
            setTags(updateTag)
            setActionForTables(Object.values(updateTag).map(el => el.action))
            toast.success('Categoria ativada com sucesso!', {
                position: 'top-center'
            });
        } catch (error) {
            toast.error('Um erro inesperado aconteceu!', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
            setModalOpen(false)
        }
    }


    const editTag = async () => {
        setIsLoading(true);
        const id = item.id;
        const payload = {
          id,
          name: nameTag,
        };
      
        try {
          await tagsApi.update(`${id}`, payload);
      
          const updatedTags = tags.map((tag) => {
            if (tag.id === id) {
                return {...payload, id: id, name: payload.name, is_active: payload.is_active ? 'Ativa' : 'Desativada', action: ['active', 'disabled','edit', 'delete']}
            }
            return tag;
          });
      
          setTags(updatedTags);
          setActionForTables(Object.values(updatedTags).map(el => el.action))

          toast.success("Categoria editada com sucesso!", {
            position: "top-center",
            autoClose: 5000,
          });
        } catch (error) {
          toast.error("Erro ao editar categoria!", {
            position: "top-center",
            autoClose: 5000,
          });
        } finally {
          setIsLoading(false);
          setModalOpen(false);
        }
    }
    
    const deleteTag = async () => {
        setIsLoading(true)
        const id = item.id
        try {
            await tagsApi.delete(id)
            setTags(tags => tags.filter(tag => tag.id !== id));
            toast.success('Categoria deletada com sucesso!', {
                position: "top-center",
                autoClose: 5000,
            })
          
        } catch (error) {
            toast.error('Erro não foi possível deletar está categoria!', {
                position: 'top-center'
            })
        } finally {
            setIsLoading(false)
            setModalOpen(false)
        }
    }

    const modalAddOpen = () => {
        setModalOpenAdd(true)
    }

    const toggleActionClicked = (item) => {
        setModalOpen(true);
        setItem(item)
    }

    return (
        <Container>
            <ButtonAddContainer>
                <ButtonAdd onClick={() => modalAddOpen()}>
                    Adicionar categoria
                </ButtonAdd>
            </ButtonAddContainer>
            {
                !isLoading ? (
                    !tags || tags.length === 0 ? (
                        <SearchNotFound />
                    ) : (
                        tags && actionForTables && (
                            <Table
                                rows={tags}
                                columns={['Nome', 'Status', 'Ações']}
                                actionClicked={(item) => toggleActionClicked(item)}
                            />
                        )
                    )
                ) : (
                    <Loader />
                )
            }


            {
                modalOpenAdd && (
                    <Modal isOpen={modalOpenAdd} onClose={() => setModalOpenAdd(false)}>
                        <ModalTagContent closeModal={() => setModalOpenAdd(false)} />
                    </Modal>
                )
            }

            {
                modalOpen && (
                    <Modal
                        isOpen={modalOpen}
                        onClose={() => setModalOpen(false)}>
                        <Text
                            name="subtitle"> 
                            {
                                item.actionSelected === 'edit' ? 'Editar Categoria'
                                    : item.actionSelected === 'delete' ? 'Tem certeza que deseja excluir essa categoria?'
                                    : item.actionSelected === 'disabled' ? 'Tem certeza que deseja desativar essa categoria?' 
                                    : item.actionSelected === 'active' && 'Tem certeza que deseja ativar essa categoria?'
                            }
                        </Text>
                        <ModalWrapper>

                            {
                                item.actionSelected === 'edit' && (
                                    <Content>     
                                        <StyledForm onSubmit={handleSubmit(onConfirmAction)}>
                                            <Fieldset
                                                marginTop={sizes.tiny}
                                                onChangeValue={(e) => setNameTag(e)}
                                                name='nameTag'
                                                value={item.name}
                                               {...register}
                                                defaultValue={item.name}
                                                validations={fieldValidations.name()}
                                            />
                                            {
                                                errors.nameTag?.message && (
                                                    <FullyCentered textAlign='center'>
                                                        <Text 
                                                            color={colors.danger}
                                                        > 
                                                            Preencha corretamente o campo! 
                                                        </Text>
                                                    </FullyCentered>
                                                )
                                            }
                                            {
                                                !isLoading ? (
                                                    <Button 
                                                        type='submit'
                                                        marginTop={sizes.little}
                                                    >
                                                        Confirmar
                                                    </Button>
                                                ) : (
                                                    <Loader />
                                                )
                                            }
                                            </StyledForm>
                                    </Content>
                                )
                            }
                           
                            {
                                item.actionSelected !== 'edit' && (
                                    <Content>
                                        <ButtonContentConfirmed>
                                            {
                                                !isLoading ? (

                                                <>
                                                    <Button 
                                                        onClick={() => onConfirmAction()}
                                                    >
                                                        Confirmar
                                                    </Button>

                                                    <Button 
                                                        marginLeft={sizes.tiny} 
                                                        onClick={() => setModalOpen(false)}
                                                    >
                                                            Cancelar
                                                    </Button>
                                                </>
                                                ) : (
                                                    <Loader />
                                                )
                                            }
                                        </ButtonContentConfirmed>
                                    </Content>
                                )
                            }
                        </ModalWrapper>
                    </Modal>
                )
            }

        </Container>
    )
}

const Container = styled(Column)`
    width: 100%;
`;
const Content = styled(Column)`
    width: 100%;
    display: flex;
    align-items: center;
`;
const ButtonAddContainer = styled(Row)`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const ButtonContentConfirmed = styled(Row)`
    display: flex;
    height: ${sizes.gigantic};
    width: 300px;
`;

const ModalWrapper = styled(Column)`
    padding: ${sizes.nano} ${sizes.gigantic};
`;