import api from "api";

const user = {
    
    create: (payload) => {
        return api().post('/users', payload);
    },
    
    get:(numberPage) => {
        return api().get(`/users?page=${numberPage}`);    
    },

    delete: (payload) => {
        return api().delete(`/users/${payload}`)
    },

    getUserMe:() => {
        return api().get('/users/me')
    },

    update: (id, payload) => {
        return api().put(`/users/${id}`, payload)
    },

    filter: (payload) => {
        return api().get(`/users`, {
            params: payload
        })
    },

    logout: () => {
        return api().post('/logout')
    },

    partner: (payload) => {
        return api().post('/users/login-as-user', payload)
    }

};
 
export default user;  