import React from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import sizes from 'theme/sizes';
import CardScopes from './CardScopes';

export default function Scopes({scopes}) {
    return (
        <Container>
            <Content>
                {
                    scopes.map((el) => (
                        <ContainerCard key={el.id}>
                            <CardScopes data={el}/>
                        </ContainerCard>
                    ))
                }
            </Content>
        </Container>
      )
}

const Container = styled(Column)`
    width: 100%;
    margin-top: ${sizes.medium};
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;
const ContainerCard = styled.div`
    display: flex;
    flex-direction: row;
`;