import React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import statisticsApi from 'api/requests/statistics'
import StatisticsContent from './card/Content'
import Loader from 'components/_UI/Loader'


export default function Statistics() {
  const [statistics, setStatistics] = useState()
  const [isLoading, setIsloading] = useState(false)

  useEffect(() => {
    getStatistics()
  }, [])

  const getStatistics = async() => {
    setIsloading(true)
    try {
      const {data} = await statisticsApi.get()
    
     setStatistics(data)
      
    } catch (error) {

    } finally {
      setIsloading(false)
    }
  }
  return (
    <StatisticsContainer>
       <Content>
         {
          !isLoading ? (
            <StatisticsContent data={statistics} />
          ) : (
            <Loader />
          )
         }
       </Content>
      </StatisticsContainer>
  )
}

const StatisticsContainer = styled(Column)`
    width: 100%;
`;

const Content = styled(Column)`
  width: 100;
`;
