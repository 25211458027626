import tagsApi from "api/requests/tags"
import Button from "components/_UI/Button"
import Fieldset from "components/_UI/Fieldset"
import Text from "components/_UI/Text"
import React, { useState } from "react"
import styled from "styled-components"
import { Column } from "styled/alignment/Column"
import sizes from "theme/sizes"
import Loader from "components/_UI/Loader"
import { toast } from "react-toastify"
import { useForm } from 'react-hook-form';
import fieldValidations from 'helpers/fieldValidations';
import { StyledForm } from "styled/UI/StyledForm"
import { FullyCentered } from "styled/alignment/Center"
import colors from "theme/colors"
import { useTags } from "context/Tags"
import { useActionForTables } from "context/ActionForTables"

export default function ModalTagContent({ closeModal }) {
    const {setActionForTables} = useActionForTables()

    const { tags, setTags } = useTags()
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();


    const submitTag =  async ({ nameTag }) => {
        setIsLoading(true)
       const payload = {
            name: nameTag
       }
        try {
            const { data } = await tagsApi.create(payload)
            const newContent = {
                id: data.id, name: data.name, is_active: 'Ativa', action: ['active', 'disabled', 'delete']
            }
            const updatedTags = [...tags, newContent];
            const newData = updatedTags?.map(el => {
                return {...payload, name: el.name, is_active:  'Ativa', action: ['active', 'disabled','edit', 'delete']}
            })
            setTags(updatedTags);
            setActionForTables(Object.values(newData).map(el => el.action))
            toast.success('Categoria criada com sucesso!', {
                position: "top-center",
                autoClose: 5000,
            });
        } catch (error) {
            toast.error('Erro não foi possível criar a categoria!', {
                position: "top-center",
                autoClose: 5000,
            })
        } finally {
            setIsLoading(false)
            closeModal()
        }
    }

    return (
        <ContainerTag>
            <Text name='title'>Adicionar categoria</Text>
            <ModalWrapper>
                <StyledForm onSubmit={handleSubmit(submitTag)}>
                    <Fieldset
                        marginTop={sizes.tiny}
                        placeholder='Digite o nome da categoria'
                        register={register}
                        name='nameTag'
                        validations={fieldValidations.name()}
                    />
                    {
                        errors.nameTag?.message && (
                            <FullyCentered textAlign='center'>
                                <Text color={colors.danger}> Preencha corretamente o campo! </Text>
                            </FullyCentered>
                        )
                    }
                    {
                        !isLoading ? (
                            <Button 
                                type='submit' 
                                marginTop='12px'
                            >
                                Confirmar
                            </Button>
                        ) : (
                            <Loader />
                        )
                    }
                </StyledForm>
            </ModalWrapper>
        </ContainerTag>
    )
}
const ContainerTag = styled(Column)`
    display: flex;
    width: 100%;
    align-items: center;
`;


const ModalWrapper = styled(Column)`
    padding: 0 20px;
`;