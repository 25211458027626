const fieldMessages = {
    required: (value) => `O campo ${value} é obrigatório`,
    minValue: (value) => `No minimo ${value} caractéres`,
    maxValue: (value) => `No máximo ${value} caractéres`,
    fillCorrectly: (value) => `Preencha o campo de ${value} corretamente`,
    invalidFemaleField: (value) => `${value} invalida`,
    invalidMaleField: (value) => `${value} invalido`,
    onlyAlphabetic: 'São validos apenas caracteres alfabeticos',
    passwordMustBeEqual: 'As senhas devem ser iguais',
    password: 'No minimo 8 caractéres, contendo pelo menos uma letra maiuscula, um número e um caractére especial',    
    generic: 'Ocorreu um erro. Tente novamente',
    invalidTime: (value) => `O campo ${value} deve ter o formato HH:MM.`,
};

export default fieldMessages;
