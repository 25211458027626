import React, { createContext, useState, useContext } from 'react';

const TouristSpots = createContext();

export default function TouristSpotsProvider({ children }) {
    const [touristSpots, setTouristSpots] = useState('');
    return (
        <TouristSpots.Provider value={{ touristSpots, setTouristSpots}}>
            {children}
        </TouristSpots.Provider>
    );
}

export function useTouristSpots() {
    const context = useContext(TouristSpots);

    if (!context) throw new Error('useTouristSpots must be used within useTouristSpots');

    const { touristSpots, setTouristSpots } = context;
    return { touristSpots, setTouristSpots };
}