import Content from "components/main/Content";
import TagsContent from "modules/tags/TagsContent";
import React from "react";
import TagsProvider from 'context/Tags'
import ActionForTablesProvider from "context/ActionForTables";

export default function Tags() {
    return (
        <TagsProvider>
            <ActionForTablesProvider>
                <Content title="Categorias">
                    <TagsContent />
                </Content>
            </ActionForTablesProvider>
        </TagsProvider>
    )
}