import React from 'react';
import UserCardResultFilter from './card/UserCardResultFilter';
import UsersFilters from './filters/UsersFilters';
import { useUsersFilter } from 'context/UsersFilter';
import SearchNotFound from 'components/_UI/SearchNotFound';
import UserToCreate from './UserToCreate';
import Loader from 'components/_UI/Loader';

export default function UsersContent() {
    const { usersFilter } = useUsersFilter()
    
    return (
        <>   
            <UserToCreate />
            
            <UsersFilters />
            {usersFilter.loading ? <Loader />  : <UserCardResultFilter />}

            {usersFilter.isNotFound && <SearchNotFound /> }
        </>
    )
}
