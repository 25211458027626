import React, { createContext, useState, useContext } from 'react';

const EventsContext = createContext();

export default function EventsProvider({ children }) {
    const [events, setEvents] = useState(null);

    return (
        <EventsContext.Provider value={{events, setEvents}}>
            {children}
        </EventsContext.Provider>
    );
}

export function useEvents() {
    const context = useContext(EventsContext);
    
    if (!context) throw new Error('useEvents must be used within a events');
    
    const { events, setEvents } = context;
    return { events, setEvents };
}