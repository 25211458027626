import Content from 'components/main/Content'
import ActionForTablesProvider from 'context/ActionForTables'
import TagsTouristSpotsProvider from 'context/TagsTouristSpots'
import TagsSpotsContent from 'modules/tags-spots/TagsSpotsContent'
import React from 'react'

export default function TagsSpots() {
  return (
    <TagsTouristSpotsProvider>
      <ActionForTablesProvider>
        <Content title='Categorias'>
            <TagsSpotsContent />
        </Content>
      </ActionForTablesProvider>
    </TagsTouristSpotsProvider>
  )
}
