import Content from 'components/main/Content'
import ActionForTablesProvider from 'context/ActionForTables'
import TagsNaturalBeautyProvider from 'context/TagsNaturalBeauty'
import TagsNaturalBeautyContent from 'modules/tags-natural-beauty/TagsNaturalBeautyContent'
import React from 'react'

export default function TagsNaturalBeauty() {
  return (
    <TagsNaturalBeautyProvider>
      <ActionForTablesProvider>
        <Content title='Categorias Belezas Naturais'>
            <TagsNaturalBeautyContent />
        </Content>
      </ActionForTablesProvider>
    </TagsNaturalBeautyProvider>
  )
}
